import React, {useState, useMemo, useEffect} from 'react';
import Select from 'react-select';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {
  Option,
  MultiValueRemove,
  DropdownIndicator,
  customStyles,
} from '../../../components';
import {getCompanyApps} from '../../../helpers/queryFns/fetchCompanyDetails';

export default function AssignApps({setAppsToAssign, memberApps}) {
  const {id} = useParams();

  const appQuery = useQuery(['app-query', id], () => getCompanyApps(id));

  const allApps = appQuery?.data;
  const userApps = useMemo(
    () =>
      allApps
        ? allApps?.apps?.map(app => ({value: app._id, label: app.name}))
        : [],
    [allApps],
  );
  const memberAssignedApps = useMemo(
    () =>
      memberApps
        ? memberApps?.map(app => ({value: app._id, label: app.name}))
        : [],
    [memberApps],
  );

  const [selectedApps, setSelectedApps] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const DATA = useMemo(
    () => [{label: 'Select All', value: 'all', isActive}, ...(userApps || [])],
    [isActive, userApps],
  );

  useEffect(() => {
    setSelectedApps(memberAssignedApps);
  }, [memberAssignedApps]);

  function onChange(value, event) {
    const pushData = value.map(app => app.value);
    const pullData = userApps.map(app => app.value);

    if (
      (!selectedApps.length || selectedApps.length !== DATA.slice(1).length) &&
      event.action === 'select-option' &&
      event.option.value === 'all'
    ) {
      setSelectedApps(DATA.slice(1));
      setAppsToAssign({
        pushApps: DATA.slice(1).map(app => app.value),
        pullApps: [],
      });
      setIsActive(!isActive);
    } else if (event.option?.value === 'all') {
      setSelectedApps([]);
      setAppsToAssign({pushApps: [], pullApps: pullData});
      setIsActive(false);
    } else {
      setSelectedApps(value);
      setAppsToAssign({
        pushApps: value.map(o => o.value),
        pullApps: pullData.filter(item => pushData.indexOf(item) === -1),
      });
      setIsActive(value.length === DATA.slice(1).length);
    }
  }

  return (
    <>
      <label
        htmlFor="app"
        className="mt-6 mb-2 flex flex-col items-start max-w-full text-sm label text-grey60"
      >
        <p>
          App
          <span className="text-grey40"> (optional)</span>
        </p>
      </label>
      <Select
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onChange}
        options={DATA}
        value={selectedApps}
        isMulti
        multiValueLabel
        placeholder="Select app(s)"
        className="basic-multi-select apps-multi-select"
        classNamePrefix="react-select"
        components={{
          DropdownIndicator,
          MultiValueRemove,
          Option,
        }}
        styles={customStyles}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        allowSelectAll
        menuPlacement="top"
      />
    </>
  );
}
