import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Loader from '../components/Loader';
import * as Actions from '../store/actions';

function LoginComplete({completeLoginUser}) {
  const navigate = useNavigate();
  useEffect(() => {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get('code');

    if (!value) {
      localStorage.clear();
      window.location.href = '/auth';
    }
    if (value) completeLoginUser(value);
  }, [completeLoginUser, navigate]);
  return <Loader />;
}

export default connect(state => state, Actions)(LoginComplete);
