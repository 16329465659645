import React, {useState} from 'react';
import {downloadGrey, enlargeIc} from '../../../assets/images/images';

export default function ExpandImageToggle({resizeImage, imgUrl, id}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleImageClick = () => {
    if (isExpanded) {
      resizeImage(id, false);
    } else {
      resizeImage(id, true);
    }

    toggleExpand();
  };

  return (
    <div className="absolute flex items-center gap-2 right-2 bottom-2">
      <button
        onClick={handleImageClick}
        className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white-900 w-fit"
      >
        <span className="-mb-[2px]">
          {isExpanded ? 'Shrink' : 'Enlarge'} image
        </span>
        <img src={enlargeIc} alt="" className="-mt-[2px]" />
      </button>
      <a
        href={`${imgUrl}`}
        download="file"
        rel="noreferrer"
        className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white-900 w-fit"
      >
        <span>Download</span>
        <img src={downloadGrey} alt="" className="-mt-[2px]" />
      </a>
    </div>
  );
}
