/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
export const debounceFunction = (func, delay) => {
  let timer;

  return function () {
    const self = this;

    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(self, args);
    }, delay);
  };
};
