import React, {useState} from 'react';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';

export default function EditCompanyNameModal({
  open,
  setOpen,
  selectedCompany,
  editCompanyName,
  editingCompanyName,
}) {
  const [newCompanyName, setNewCompanyName] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    editCompanyName({
      company_name: newCompanyName,
    });
  };

  return (
    <Modal
      show={open}
      modalTitle="Edit company name"
      onClose={setOpen}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <form onSubmit={handleSubmit} className="mt-6 text-left">
        <label
          htmlFor="old_company_name"
          className="flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          Old Company name
          <input
            id="old_company_name"
            defaultValue={selectedCompany?.name}
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            disabled
          />
        </label>
        <label
          htmlFor="new_company_name"
          className="mt-4 flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          New Company name
          <input
            id="new_company_name"
            value={newCompanyName}
            onChange={e => setNewCompanyName(e.target.value)}
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            required
          />
        </label>

        <PrimaryButton
          fontSize="text-sm"
          buttonText="Update company name"
          className="inline-flex justify-center w-full mt-6 text-white rounded bg-brandBlue"
          loadingState={editingCompanyName}
          disabled={editingCompanyName}
        />
      </form>
    </Modal>
  );
}
