import React, {useEffect} from 'react';

import {ReactQueryDevtools} from 'react-query/devtools';

import {connect} from 'react-redux';
import Routes from './routes';

function Main({auth: {user}}) {
  const route = window.location.href;

  useEffect(() => {
    if (
      (route.includes('super-admin-settings') &&
        process.env.REACT_APP_APP_ENV_MODE !== 'production' &&
        user?.role?.includes('Admin')) ||
      (route.includes('super-admin-settings') &&
        process.env.REACT_APP_APP_ENV_MODE === 'production' &&
        user?.email !== 'tobi@dojah.io')
    ) {
      window.location.href = '/not-allowed';
    }
  }, [route, user?.email, user?.role]);

  return (
    <>
      <Routes />
      <ReactQueryDevtools />
    </>
  );
}
export default connect(state => state, null)(Main);
