import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Modal} from '../../../components';
import ActivityLog from './ActivityLog';
import * as Actions from '../../../store/actions';

function SessionsModal({verifications: {session_summary}, open, setOpen}) {
  const VERIFICATION = session_summary?.data?.Verification;

  const [session, setSession] = useState(VERIFICATION?.session_id || null);

  useEffect(() => {
    if (!session && session_summary) {
      setSession(VERIFICATION?.session_id);
    }
  }, [session_summary, session, setSession, VERIFICATION?.session_id]);

  return (
    <Modal
      modalBg="bg-white-900"
      modalTitle="Sessions"
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="md:max-w-[408px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="flex items-center gap-2 p-4 mt-4 text-xs bg-white rounded text-grey60">
        <span className="font-medium uppercase">session ID:</span>
        <p>{session}</p>
      </div>
      <ActivityLog session={session} />
    </Modal>
  );
}

export default connect(state => state, Actions)(SessionsModal);
