/* eslint-disable react/jsx-indent */
import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {logo, logOut} from '../assets/images/images';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import * as Actions from '../store/actions';
import {
  NAV_ITEMS_BASE_ANALYTICS,
  NAV_ITEMS_BLOG,
  NAV_ITEMS_OTHERS,
  NAV_ITEMS_SETTINGS,
  NAV_RECONCILO,
} from '../helpers/constants';
import {NAV_BAAS} from '../helpers/constants/nav';

function NavItems({href, icon, text}) {
  const location = useLocation();
  const {pathname} = location;
  const splitLocation = pathname.split('/');

  return (
    <NavLink
      to={href}
      className={`rounded mt-1 ml-6 text-white pl-4 py-3 w-48 flex relative ${splitLocation[1]} === ${href} ? "active" : ""`}
      aria-current="page"
    >
      <div className="my-auto mr-4 shrink-0">{icon}</div>
      <span className="text-sm font-medium text-navGrey">{text}</span>
    </NavLink>
  );
}

function Sidebar({logout, auth: {user}}) {
  const location = useLocation();
  const {pathname} = location;
  const [navBaseAnalytics, setNBA] = useState(NAV_ITEMS_BASE_ANALYTICS);
  const [navReconcilo, setNavReconcilo] = useState(NAV_RECONCILO);
  const [navBaas, setNavBaas] = useState(NAV_BAAS);
  const [navOthers, setNavOthers] = useState(NAV_ITEMS_OTHERS);
  const [navSettings, setNavSettings] = useState(NAV_ITEMS_SETTINGS);
  const [navBlog, setNavBlog] = useState(NAV_ITEMS_BLOG);

  useEffect(() => {
    const roleNavigationMap = {
      Admin: {
        nba: NAV_ITEMS_BASE_ANALYTICS,
        navOthers: NAV_ITEMS_OTHERS,
        navSettings: NAV_ITEMS_SETTINGS,
        navBlog: NAV_ITEMS_BLOG,
      },
      Default: {
        nba: [],
        navOthers: [],
        navSettings: NAV_ITEMS_SETTINGS.filter(
          item => item.text !== 'Admins' && item.text !== 'Audit Trail',
        ),
        navBlog: [],
      },
      Marketing: {
        nba: [],
        navOthers: [],
        navSettings: NAV_ITEMS_SETTINGS.filter(
          item => item.text !== 'Admins' && item.text !== 'Audit Trail',
        ),
        navBlog: NAV_ITEMS_BLOG,
      },
      Compliance: {
        nba: NAV_ITEMS_BASE_ANALYTICS.filter(
          item =>
            item.text !== 'Dashboard' &&
            item.text !== 'Users' &&
            item.text !== 'Api Logs' &&
            item.text !== 'Billings' &&
            item.text !== 'Verifications',
        ),
        navOthers: [],
        navSettings: [],
        navBlog: [],
      },
      Retention: {
        nba: NAV_ITEMS_BASE_ANALYTICS,
        navOthers: NAV_ITEMS_OTHERS,
        navSettings: NAV_ITEMS_SETTINGS.filter(
          item => item.text !== 'Admins' && item.text !== 'Audit Trail',
        ),
        navBlog: [],
      },
      Reviewer: {
        nba: NAV_ITEMS_BASE_ANALYTICS.filter(
          item =>
            item.text !== 'Dashboard' &&
            item.text !== 'Users' &&
            item.text !== 'Api Logs' &&
            item.text !== 'Billings' &&
            item.text !== 'Company',
        ),
        navOthers: [],
        navSettings: [],
        navBlog: [],
      },
    };

    const userRoles = user?.role || [];
    const combinedRoles = userRoles?.filter(role =>
      Object.prototype.hasOwnProperty.call(roleNavigationMap, role),
    );

    if (combinedRoles.length === 0) {
      setNBA([...NAV_ITEMS_BASE_ANALYTICS]);
      setNavReconcilo([...NAV_RECONCILO]);
      setNavBaas([...NAV_BAAS]);
      setNavOthers([...NAV_ITEMS_OTHERS]);
      setNavSettings([...NAV_ITEMS_SETTINGS]);
      setNavBlog([...NAV_ITEMS_BLOG]);
    } else {
      const combinedNavConfig = combinedRoles.reduce(
        (acc, role) => {
          const roleConfig = roleNavigationMap[role];
          return {
            nba: acc.nba.concat(
              roleConfig.nba.filter(item => !acc.nba.includes(item)),
            ),
            navOthers: acc.navOthers.concat(
              roleConfig.navOthers.filter(
                item => !acc.navOthers.includes(item),
              ),
            ),
            navSettings: acc.navSettings.concat(
              roleConfig.navSettings.filter(
                item => !acc.navSettings.includes(item),
              ),
            ),
            navBlog: acc.navBlog.concat(
              roleConfig.navBlog.filter(item => !acc.navBlog.includes(item)),
            ),
          };
        },
        {
          nba: [],
          navOthers: [],
          navSettings: [],
          navBlog: [],
        },
      );

      setNBA(combinedNavConfig.nba);
      setNavOthers(combinedNavConfig.navOthers);
      setNavSettings(combinedNavConfig.navSettings);
      setNavBlog(combinedNavConfig.navBlog);
    }
  }, [user?.role]);

  // Javascript split method to get the name of the path in array
  const splitLocation = pathname.split('/');
  const [open, setOpen] = useState(false);

  return (
    <aside className="fixed overflow-y-auto flex-auto h-full w-60 bg-sidebarBlue">
      <div className="flex flex-col overflow-y-auto">
        <ul className="relative h-full p-0 m-0 list-none">
          <li className="relative flex justify-start w-full pt-8 pl-6 text-2xl">
            <Link to="/" className="flex items-center gap-2">
              <img src={logo} alt="logo" width={63} height={21} />
              <span className="text-xs text-brandBlue">ADMIN</span>
            </Link>
          </li>
          {navBaseAnalytics?.length > 0 ? (
            <p className="pt-8 pl-6 font-medium uppercase text-small text-disabled">
              Base analytics
            </p>
          ) : null}
          {navBaseAnalytics.map((item, index) => (
            <NavItems key={index} {...item} />
          ))}

          {navBaas?.length > 0 ? (
            <p className="pt-8 pl-6 font-medium uppercase text-small text-disabled">
              BAAS
            </p>
          ) : null}
          {navBaas.map((item, index) => (
            <NavItems key={index} {...item} />
          ))}

          {navReconcilo?.length > 0 ? (
            <p className="pt-8 pl-6 font-medium uppercase text-small text-disabled">
              Reconcilo
            </p>
          ) : null}
          {navReconcilo.map((item, index) => (
            <NavItems key={index} {...item} />
          ))}
          {navOthers?.length > 0 ? (
            <p className="pt-4 pl-6 font-medium uppercase text-small text-disabled">
              Others
            </p>
          ) : null}
          {navOthers.map((item, index) => (
            <NavItems key={index} {...item} />
          ))}
          {navSettings?.length > 0 ? (
            <p className="pt-4 pl-6 font-medium uppercase text-small text-disabled">
              Settings
            </p>
          ) : null}
          {navSettings.map((item, index) => (
            <NavItems key={index} {...item} />
          ))}
          {navBlog?.length > 0 ? (
            <p className="pt-4 pl-6 font-medium uppercase text-small text-disabled">
              Blog
            </p>
          ) : null}
          {navBlog.map((item, index) => (
            <NavItems key={index} {...item} />
          ))}
          {(process.env.REACT_APP_APP_ENV_MODE !== 'production' &&
            user?.role?.includes('Admin')) ||
          (process.env.REACT_APP_APP_ENV_MODE === 'production' &&
            user?.email === 'tobi@dojah.io') ? (
            <p className="pt-4 pl-6 font-medium uppercase text-small text-disabled">
              Super Admin
            </p>
          ) : null}
          {(process.env.REACT_APP_APP_ENV_MODE !== 'production' &&
            user?.role?.includes('Admin')) ||
          (process.env.REACT_APP_APP_ENV_MODE === 'production' &&
            user?.email === 'tobi@dojah.io') ? (
            <NavLink
              to="/super-admin-settings"
              className={`rounded mt-1 ml-6 pl-4 py-3 w-48 flex relative ${splitLocation[1]} === "super-admin-settings" ? "active" : ""`}
              aria-current="page"
            >
              <div className="my-auto mr-4 shrink-0">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_7005_19626)">
                    <path
                      d="M13.3335 17.5V15.8333C13.3335 14.9493 12.9823 14.1014 12.3572 13.4763C11.7321 12.8512 10.8842 12.5 10.0002 12.5H4.16683C3.28277 12.5 2.43493 12.8512 1.80981 13.4763C1.18469 14.1014 0.833496 14.9493 0.833496 15.8333V17.5"
                      stroke="#8E8E91"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.08333 9.16667C8.92428 9.16667 10.4167 7.67428 10.4167 5.83333C10.4167 3.99238 8.92428 2.5 7.08333 2.5C5.24238 2.5 3.75 3.99238 3.75 5.83333C3.75 7.67428 5.24238 9.16667 7.08333 9.16667Z"
                      stroke="#8E8E91"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.1665 9.16667L15.8332 10.8333L19.1665 7.5"
                      stroke="#8E8E91"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7005_19626">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="text-sm font-medium text-navGrey">Settings</span>
            </NavLink>
          ) : null}
          <button
            onClick={() => setOpen(!open)}
            className="relative flex justify-start w-48 py-3 pl-4 mt-6 mb-auto ml-6 rounded bg-lighterBlue"
          >
            <div className="my-auto mr-4 shrink-0">
              <img src={logOut} alt="" />
            </div>
            <span className="text-sm font-medium text-danger">Logout</span>
            <Modal show={open} onClose={setOpen}>
              <div className="max-w-sm mx-auto sm:my-8">
                <h2 className="mb-6">Are you sure?</h2>
                <PrimaryButton
                  className="inline-flex justify-center w-full text-white rounded text-tiny bg-danger"
                  onClick={() => logout()}
                  buttonText="Logout"
                />
                <button
                  type="button"
                  className="inline-flex justify-center w-full mt-6 text-navGrey"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </Modal>
          </button>
        </ul>
      </div>
    </aside>
  );
}

export default connect(state => state, Actions)(Sidebar);
