import React, {useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import {Menu} from '@headlessui/react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import moment from 'moment';
import {connect} from 'react-redux';
import {check_export, eye} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import Dropdown from '../../components/Dropdown';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import {fetchBaasSettlements} from '../../helpers/queryFns/company';
import GoToTop from '../../components/GoToTop';
import Loader from '../../components/Loader';
import {SearchInput} from '../../components/SearchInput';
import {EmptyState} from '../../components/EmptyState';
import Modal from '../../components/Modal';
import {getStatusStyles} from '../../helpers/getStatusStyles';

function BaasSettlements({auth: {user}}) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const page = Number(query.get('page'));
  const filter = query.get('filter');
  const [searchText, setSearchText] = useState(filter);
  const [open, setOpen] = useState(false);

  const handleCurrentPage = e => {
    navigate(`?page=${e}`);
  };

  const params = useMemo(
    () => ({
      search: searchText,
      page: page === 0 ? 1 : page,
      length: 10,
      environment: 'live',
    }),
    [page, searchText],
  );

  const fetchParams = {
    ...params,
    export: true,
    email: user?.email,
    file_type: 'excel',
  };

  const {isFetching: fetchingCompanyExport, refetch: refetchingCompanyExport} =
    useQuery(
      ['export-settlement', {params: {...fetchParams}}],
      () => fetchBaasSettlements({params: {...fetchParams}}),
      {
        enabled: false,
        onSuccess: () => {
          setOpen(false);
          toast.success('File exported to your email');
        },
      },
    );

  const {
    data: settlementData,
    isLoading,
    isFetching,
  } = useQuery(['settlement-data', {params}], () =>
    fetchBaasSettlements({params}),
  );

  const settlementResult = useMemo(
    () => !isLoading && settlementData && settlementData?.entity,
    [isLoading, settlementData],
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <DashboardLayout headerText="Company">
      <LayoutContainer>
        <div>
          <h4 className="mt-2 mb-4 font-medium text-grey60">Settlements</h4>
          <div className="flex flex-wrap ">
            <SearchInput
              placeholder="Search Settlements"
              onChange={text => {
                setSearchText(text);
                navigate(`?filter=${text}`);
              }}
              defaultValue={searchText}
            />

            <div className="flex flex-wrap items-center gap-4 ml-auto">
              <>
                <PrimaryButton
                  buttonText="Export table"
                  xPadding="px-6"
                  className="font-medium"
                  onClick={() => setOpen(!open)}
                />
                <Modal show={open} onClose={setOpen}>
                  <div className="flex flex-col items-center px-12 pt-20 pb-10">
                    <img className="w-[134px]" src={check_export} alt="" />
                    <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                      Your export file is being prepared. When it is ready, it
                      will be sent to the email attached to this account.
                    </h2>
                    <PrimaryButton
                      buttonText="Continue"
                      xPadding="px-6"
                      className="w-full font-medium"
                      disabled={fetchingCompanyExport}
                      loadingState={fetchingCompanyExport}
                      onClick={refetchingCompanyExport}
                    />
                    <button
                      type="button"
                      className="inline-flex justify-center w-full mt-6 text-navGrey"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Modal>
              </>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!settlementResult?.total ? (
                <EmptyState
                  title="No Selttlement found"
                  body="No Selttlement is listed yet"
                />
              ) : (
                <TableLayout>
                  <thead className="text-xs font-semibold uppercase">
                    <tr className="border-b border-tableGrey">
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Company name
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Account name
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        account number
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        bank name
                      </th>
                      <th className="p-0  font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Baas Migrated Status
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Date created
                      </th>
                      <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap">
                        action
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-sm divide-y divide-gray-100"
                    style={{opacity: isFetching && 0.3}}
                  >
                    {settlementResult?.settlements?.map(settlement => (
                      <tr
                        className="border-b cursor-pointer border-tableGrey"
                        key={settlement?.id}
                        onClick={() =>
                          navigate(
                            `/baas/settlement/${settlement?.id}/settings`,
                            {
                              state: {
                                environment: settlement?.environment,
                              },
                            },
                          )
                        }
                      >
                        <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                          {settlement?.company_name || '-'}
                        </td>
                        <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                          {settlement?.account_name || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {/* {formatNumberToCurrency( */}
                          {/*  settlement?.wallet_balance / 100 ?? 0, */}
                          {/*  settlement?.currency, */}
                          {/*  settings?.settings?.dollar_exchange_rate, */}
                          {/* )} */}

                          {settlement?.account_number || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {settlement?.bank_name || '-'}
                        </td>
                        <td
                          className={`uppercase pt-[5px] pb-[3px] mt-4 px-2 text-xs font-medium  rounded-full w-fit flex items-center  ${
                            getStatusStyles(
                              settlement?.migrated_to_baas
                                ? 'migrated'
                                : 'not-migrated',
                            ).styles
                          } whitespace-nowrap`}
                        >
                          {getStatusStyles(
                            settlement?.migrated_to_baas
                              ? 'migrated'
                              : 'not-migrated',
                          ).text || '-'}
                        </td>

                        <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                          {moment(settlement?.date_created).format(
                            'Do MMM, YYYY hh:mm',
                          ) || '-'}
                        </td>
                        <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                          <Dropdown>
                            <Menu.Item>
                              {() => (
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/baas/settlement/${settlement?.id}/settings`,
                                      {
                                        state: {
                                          environment: settlement?.environment,
                                        },
                                      },
                                    )
                                  }
                                  className={classNames(
                                    'text-grey60 flex items-center gap-4  text-tiny font-medium',
                                  )}
                                >
                                  <img src={eye} alt="" />
                                  View company profile
                                </button>
                              )}
                            </Menu.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableLayout>
              )}
            </>
          )}

          <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
            <Pagination
              total={(settlementResult && settlementResult?.total_pages) || 0}
              current={page}
              onPageChange={handleCurrentPage}
            />
          </div>
        </div>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}

export default connect(state => state, null)(BaasSettlements);
