/* eslint-disable no-shadow */
import React, {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {dropdown, filterCheck, filterNoCheck} from '../assets/images/images';

export default function SingleDropdown({
  dropdownData,
  selected,
  setSelected,
  spanText,
  width,
  styles,
  whiteBg,
}) {
  return (
    <div className="max-w-72">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button
            className={`relative w-full flex gap-2 items-center justify-between cursor-pointer rounded border border-grey20 py-[14px] px-4 text-left h-12 focus:outline-none focus:border focus:border-brandBlue ${
              whiteBg ? 'bg-white' : 'bg-grey20'
            }`}
          >
            <span className="text-tiny text-grey60">{spanText}</span>
            <img src={dropdown} alt="" />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute right-0 z-20 mt-1 flex flex-col gap-3 rounded bg-white p-4 shadow ${
                width ?? 'w-[214px]'
              }`}
              style={{...styles}}
            >
              {dropdownData &&
                dropdownData?.map((data = {}, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({active, selected}) =>
                      `relative flex items-center justify-between cursor-pointer select-none py-3 pl-[12px] pr-[9px] rounded ${
                        active ? 'bg-lightBlue' : ''
                      } ${selected ? 'bg-lightBlue' : ''}`
                    }
                    value={data}
                  >
                    {({selected}) => (
                      <>
                        <span className="truncate text-tiny text-grey60">
                          {data?.env ||
                            data?.label ||
                            data?.status ||
                            data?.type ||
                            data?.sms_route ||
                            data?.currency ||
                            data?.dep ||
                            data?.job_title ||
                            data?.category ||
                            data?.label}
                        </span>
                        <img
                          src={selected ? filterCheck : filterNoCheck}
                          alt=""
                        />
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
