import {toast} from 'react-toastify';
import api from './api';

function fetchAuditTrails(data) {
  return api.get('/admin/audit-trail', {params: data}).then(res => res.data);
}

async function exportAuditTrail() {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const response = await api
    .get('/admin/audit-trail/export')
    .then(res => res.data);
  toast.success(response?.message);
  return response;
}

export {fetchAuditTrails, exportAuditTrail};
