import React from 'react';
import {notFoundImg, blueLine} from '../assets/images/images';
import DashboardLayout from '../components/DashboardLayout';
import LayoutContainer from '../components/LayoutContainer';

export default function NotFound() {
  return (
    <DashboardLayout>
      <LayoutContainer>
        <div className="flex items-center justify-center gap-10 flex-wrap sm:h-[calc(100vh-223px)]">
          <img src={notFoundImg} alt="" />
          <div className="flex flex-col gap-2 max-w-[300px]">
            <p className="text-base text-danger">ERROR 404</p>
            <img src={blueLine} alt="" className="w-fit" />
            <h1 className="text-xxl font-extrabold text-grey80">
              We looked really hard
            </h1>
            <p className="text-base text-brandBlue">
              But, it seems the page you are looking for isn’t here anymore
            </p>
          </div>
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}
