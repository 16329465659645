import React, {useCallback, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {connect} from 'react-redux';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import Loader from '../../components/Loader';
import {
  getCompanyApps,
  getCompanyLogs,
} from '../../helpers/queryFns/fetchCompanyDetails';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';
import {EmptyState} from '../../components/EmptyState';
import SingleDropdown from '../../components/SingleDropdown';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';

const dropdownData = [{env: 'Production'}, {env: 'Sandbox'}];

function CompanyApiLogs({company: {company_info, settings}}) {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');
  const [selected, setSelected] = useState(dropdownData[0]);

  const pageClick = e => {
    setCurrentPage(e);
  };

  const logsQuery = useQuery(
    ['logs-query', {...selected, id, page: currentPage}],
    () => getCompanyLogs(id, {...selected, page: currentPage}),
  );

  const appQuery = useQuery(['app-query', id], () =>
    getCompanyApps(id, {page: currentPage, limit: 1000}),
  );

  const getAppNameById = useCallback(
    appId =>
      (!appQuery?.isLoading ||
        !appQuery?.isFetching ||
        !logsQuery.isFetching) &&
      appQuery?.data?.apps?.find(app => app?._id === appId),
    [appQuery, logsQuery],
  );

  return (
    <DashboardLayout className="mt-4" headerText="Company/API Logs">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5 mb-3">
            {logsQuery.isLoading ? (
              <Loader />
            ) : (
              <>
                {!logsQuery?.data?.totalRecords ? (
                  <EmptyState
                    height={45}
                    title="No log listed"
                    body="No log is listed yet"
                  />
                ) : (
                  <>
                    <div className="flex flex-wrap items-center justify-end gap-4">
                      <h4 className="font-medium text-tiny text-grey60">
                        Filter by:
                      </h4>
                      <SingleDropdown
                        dropdownData={dropdownData}
                        selected={selected}
                        setSelected={setSelected}
                        spanText={selected.env}
                      />
                    </div>
                    <TableLayout>
                      <thead className="text-xs font-semibold uppercase">
                        <tr className="border-b border-tableGrey">
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            product
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            app name
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            services
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            cost
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            type
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            environment
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            created
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            status
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-sm divide-y divide-gray-100"
                        style={{opacity: logsQuery.isFetching && 0.3}}
                      >
                        {logsQuery.data?.api_logs.map((log, i) => (
                          <tr className="border-b border-tableGrey" key={i}>
                            <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                              {log.product ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {getAppNameById(log.app)?.name ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {log.service ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {formatNumberToCurrency(
                                log.cost / 100 ?? 0,
                                company_info?.company?.currency,
                                settings?.settings?.dollar_exchange_rate,
                              )}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {log.type ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {log.env ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {moment(log.createdAt).format(
                                'Do MMM, YYYY hh:mm',
                              ) ?? '-'}
                            </td>
                            <td
                              className={`p-3 text-left text-tiny whitespace-nowrap ${
                                log?.status === 'Success'
                                  ? 'text-success'
                                  : 'text-danger'
                              }`}
                            >
                              {log.status ?? '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableLayout>
                  </>
                )}
              </>
            )}
          </div>

          <div className="mt-4 mb-8">
            <Pagination
              total={logsQuery?.data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}

export default connect(state => state, null)(CompanyApiLogs);
