export const VerificationTypes = [
  {
    label: 'International Passport ID',
    value: 'passport',
    name: 'PASSPORT_ID',
  },
  {label: "Driver's License ID", value: 'dl', name: 'DL_ID'},
  {label: 'National ID', value: 'national', name: 'NATIONAL_ID'},
  {label: "Voter's Card", value: 'permit', name: 'VOTER_CARD'},
  {label: 'Nin slip', value: 'id', name: 'NIN_SLIP'},
];
export const VerificationGender = [
  {label: 'Male', value: 'male', name: 'MALE'},
  {label: 'Female', value: 'female', name: 'FEMALE'},
];
