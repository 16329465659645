import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import React from 'react';
import Modal from './Modal';
import {PrimaryButton} from './index';
import {updateCompany} from '../helpers/queryFns/company';

export default function RevokeClientPartnerModal({
  open,
  setOpen,
  setOpenSuccessDialog,
  companyId,
}) {
  const queryClient = useQueryClient();

  const {mutate: updateCompanyInfo, isLoading: updateLoading} = useMutation(
    updateCompany,
    {
      onSuccess: () => {
        setOpen(false);
        setTimeout(() => setOpenSuccessDialog(true), 1000);
        setTimeout(() => {
          setOpenSuccessDialog(false);
        }, 3500);
        queryClient.invalidateQueries('companyInfo-query');
        queryClient.invalidateQueries('company-data');
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );

  const handleRevokeClient = e => {
    e.preventDefault();
    updateCompanyInfo({
      company_id: companyId,
      data: {
        client_partner: null,
      },
    });
  };

  return (
    <Modal
      show={open}
      modalTitle="Revoke user?"
      onClose={() => setOpen(false)}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <form onSubmit={handleRevokeClient} className="mt-6 text-left">
        <p className="text-sm text-grey60 text-center">
          Are you sure you want to revoke this user?
        </p>

        <div className="mt-6 flex flex-col gap-6">
          <PrimaryButton
            fontSize="text-sm"
            buttonText="Yes, revoke access"
            className="inline-flex justify-center w-full mt-6 text-white rounded bg-brandBlue"
            loadingState={updateLoading}
            disabled={updateLoading}
            bgColor="bg-danger"
            bgHover="bg-danger100"
          />

          <button
            type="button"
            className="text-grey60 text-tiny"
            onClick={() => {
              setOpen(false);
            }}
          >
            No, cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
