import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function ZimbabweKycServices({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const {id} = useParams();

  const [zimbabweId, setZimbabweId] = useState('');

  const zimbabweServicesQuery = useQuery(
    ['zimbabwe-kyc-services-query', id],
    () => getCompanyServices(id),
  );
  const url = zimbabweServicesQuery?.data?.pricing;

  useEffect(() => {
    const getZimbabweServicesQuery = () => {
      setZimbabweId((url?.zw_kyc_id / 100).toFixed(2));
    };

    getZimbabweServicesQuery();
  }, [url?.zw_kyc_id]);
  return (
    <>
      {zimbabweServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">Zimbabwe Kyc services</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <label
                  htmlFor="zimbabwe_kyc_nin"
                  className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                >
                  Zimbabwe ID
                  <input
                    id="zimbabwe_kyc_nin"
                    type="number"
                    className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                    defaultValue={zimbabweId}
                    onChange={e =>
                      onUpdate({
                        zw_kyc_id: e.target.value * 100,
                      })
                    }
                  />
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
