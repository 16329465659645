import api from './api';

function fetchDasboardSummary(data) {
  return api
    .get('/admin/dashboard/data/v2', {
      headers: {
        Environment: data?.env,
      },
    })
    .then(res => res.data);
}

export {fetchDasboardSummary};
