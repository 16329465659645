/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from 'react';
import {useQuery} from 'react-query';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  CustomDateRadio,
  FilterWrapper,
  Radios,
  Checkboxes,
  FilterAccordion,
} from '../../../components';
import {dates} from '../../../helpers/constants';
import {customDateFilters} from '../../../helpers/customDateFilters';
import {FilterContext} from '../../../helpers/context/FilterContext';
import {fetchAdminUsers} from '../../../helpers/queryFns/admin';

const STATUS_DATA = [
  {label: 'Pending', value: '1', name: 'Pending'},
  {label: 'Successful', value: '2', name: 'Completed'},
  {label: 'Failed', value: '3', name: 'Failed'},
];

function VerificationsFilter({apps, ...otherProps}) {
  const {
    openFilter,
    setOpenFilter,
    resetFilters,
    handleFilter,
    onCheck,
    appFilters,
  } = otherProps;

  const [selectedAssignee, setSelectedAssignee] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  // Query for admin users
  const {data: adminUsers} = useQuery(
    ['admin-users', {page: 1, limit: 10000}],
    () =>
      fetchAdminUsers({
        page: 1,
        limit: 10000,
      }),
  );

  const ASSIGNEE = useMemo(
    () =>
      (adminUsers &&
        adminUsers?.users.map(admin => ({
          label: `${admin?.first_name} ${admin?.last_name}`,
          value: admin.email,
        }))) ||
      [],
    [adminUsers],
  );

  const [activeIndex, setActiveIndex] = useState(null);
  const [date, setDate] = useState('');
  const {filter, dispatch} = useContext(FilterContext);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [count, setCount] = useState({});

  const [dateOpen, setDateClick] = useState(false);
  const maxDate = new Date();

  const handleClearFilters = useCallback(() => {
    resetFilters();
    setDate('');
    setCount({});
    setEndDate(new Date());
    setStartDate(new Date());
    setDateClick(false);
    dispatch({type: 'RESET'});
    setActiveIndex(null);
    setCustomDate(false);
    setSelectedAssignee(null);
    setSelectedStatus(null);
  }, [resetFilters, dispatch]);

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({value, appFilters, onCheck});
      setDate(value);
      dispatch({type: 'UPDATE_DATE', date: value});
      setCount({
        ...count,
        date: 1,
      });
      setCustomDate(false);
    },
    [appFilters, count, onCheck, dispatch],
  );

  useEffect(
    () => () => {
      if (Object.values(count).length > 0) {
        handleClearFilters();
        window.location.reload();
        localStorage.removeItem('vFilters');
        localStorage.setItem('page', '1');
      }
    },
    [Object.values(count).length > 0],
  );

  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  const logsSearchInputs = useMemo(
    () => [
      {
        data: ASSIGNEE,
        filterName: 'assignee',
        onCheck: data => {
          onCheck(data);
          setSelectedAssignee(Object.values(data));
        },
        selectedCount: selectedAssignee ? selectedAssignee[0]?.split(',') : 0,
      },
      {
        data: STATUS_DATA,
        filterName: 'review_status',
        onCheck: data => {
          onCheck(data);
          setSelectedStatus(Object.values(data));
        },
        selectedCount: selectedStatus ? selectedStatus[0]?.split(',') : 0,
      },
    ],
    [onCheck, selectedStatus, selectedAssignee],
  );
  return (
    <FilterWrapper
      resetFilters={handleClearFilters}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilter={handleFilter}
      clearFilters
      appFilters={appFilters}
    >
      <FilterAccordion
        selectedCount={date === '' ? 0 : 1}
        filterName="date"
        onClick={() => setDateClick(!dateOpen)}
        activeIndex={dateOpen}
      >
        <Radios data={dates} onCheck={onDateCheck} radioValue={filter.date}>
          <label
            htmlFor="custom_date"
            className="relative flex flex-row items-center gap-2 pl-4 font-normal transition duration-500 ease-in-out"
          >
            <input
              type="radio"
              value="custom_date"
              id="custom_date"
              name="date"
              className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
              onClick={onCustomDateCheck}
            />
            <span className="text-sm text-grey">Specific date range</span>
          </label>
          {customDate && (
            <CustomDateRadio
              selectedStart={startDate}
              selectedEnd={endDate}
              onChangeStart={date => {
                const start = moment(date).format('YYYY-MM-DD HH:mm:ss');
                onCheck({...appFilters, start});
                setStartDate(date);
              }}
              onChangeEnd={date => {
                const end = moment(date).format('YYYY-MM-DD HH:mm:ss');
                onCheck({...appFilters, end});
                setEndDate(date);
              }}
              startDate={startDate}
              endDate={endDate}
              maxDate={maxDate}
              minDate={startDate}
            />
          )}
        </Radios>
      </FilterAccordion>

      {logsSearchInputs.map(
        ({data, filterName, displayName, onCheck, selectedCount}, index) => {
          const isActive = activeIndex === index;
          const filterCount =
            selectedCount && selectedCount[0] === ''
              ? selectedCount?.filter(Boolean)
              : selectedCount;
          return (
            <FilterAccordion
              activeIndex={isActive}
              key={filterName}
              filterName={displayName || filterName}
              // selectedCount={count[filterName]}
              selectedCount={filterCount}
              onClick={() => setActiveIndex(index)}
            >
              <Checkboxes
                data={data}
                values={data?.map(d => d.value) || []}
                onCheck={newData => {
                  const filterValues = Object.values(newData)[0]?.split(',');
                  onCheck(newData);
                  setCount({
                    ...count,
                    [filterName]: filterValues,
                  });
                }}
                filterName={filterName}
              />
            </FilterAccordion>
          );
        },
      )}
    </FilterWrapper>
  );
}

export default connect(state => state, null)(VerificationsFilter);
