import {toast} from 'react-toastify';
import * as Helpers from '../../helpers';
import {
  LOADING,
  DASHBOARD_DATA,
  ALL_USERS,
  USER,
  API_LOGS,
  SENDER_IDS,
} from '../types';

export const getDashboardSummary = () =>
  Helpers.api(
    '/admin/dashboard/data',
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: DASHBOARD_DATA},
  );

export const getUsers = filters =>
  Helpers.api(
    `/admin/users${filters || ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: ALL_USERS},
  );
export const getUser = companyName =>
  Helpers.api(
    `/admin/users?filter=${companyName || ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: USER},
  );

// TODO: Complete Api Logs filter params
export const getApiLogs = data =>
  Helpers.api(
    `/admin/logs/v2?filter=${data?.acc || ''}&company=${
      data?.company
    }&company_id=${data?.companyId || ''}&limit=${data?.limit || '10'}&page=${
      data?.page || 1
    }`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: API_LOGS},
  );

export const getSenderIds = data =>
  Helpers.api(
    `/admin/sender_ids?page=${data?.page || 1}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: SENDER_IDS},
  );

export const changeSenderIdStatus = sender_id =>
  Helpers.api(
    '/admin/activate/sender_id',
    'POST',
    {sender_id},
    {
      success: () => {
        toast.success('Sender ID status updated successfully');
      },
      updated: true,
    },
    {error: 'ERROR', loading: LOADING, responder: SENDER_IDS},
  );
export const deleteSenderId = sender_id =>
  Helpers.api(
    `/admin/sender_ids/${sender_id}`,
    'DELETE',
    {},
    {
      success: () => {
        toast.success('Sender Id deleted successfully');
      },
      updated: true,
    },
    {error: 'ERROR', loading: LOADING, responder: SENDER_IDS},
  );
