import * as Helpers from '../../helpers';
import {LOADING, ADMINS, ADMIN} from '../types';

export const getAdmins = data =>
  Helpers.api(
    `/admin/adminusers?filter=${data?.name || ''}&page=${data?.page || 1}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: ADMINS},
  );
export const getAdmin = adminId =>
  Helpers.api(
    `/admin/adminusers/${adminId || ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: ADMIN},
  );
export const updateAdmin = data =>
  Helpers.api(
    `/admin/adminusers/${data?.id || ''}`,
    'PUT',
    {...data?.adminData},
    {updated: true},
    {error: 'ERROR', loading: LOADING, responder: ADMINS},
  );
export const addAdmin = adminData =>
  Helpers.api(
    '/admin/adminusers',
    'POST',
    {...adminData},
    {updated: true},
    {error: 'ERROR', loading: LOADING, responder: ADMINS},
  );
export const removeAdmin = ({_id, ...data}) =>
  Helpers.api(
    `/admin/adminusers/${_id}`,
    'DELETE',
    {data},
    {updated: true},
    {error: 'ERROR', loading: LOADING, responder: ADMINS},
  );
