import React, {useCallback, useMemo} from 'react';
import moment from 'moment';
import {Modal, PrimaryButton, RowData} from '../../../components';
import DeactivateMemberModal from './DeactivateMemberModal';

export default function ViewMemberModal({
  open,
  setOpen,
  takeAction,
  removeTeamMember,
  selectedMember,
  deactivateModal,
  setDeactivateModal,
  removingTeamMember,
}) {
  const diffInHours = useCallback(
    (date1, date2) => Math.abs(date1 - date2) / 36e5,
    [],
  );

  const APPS = useMemo(() => selectedMember?.apps, [selectedMember]);

  return (
    <Modal
      modalWidth="581"
      show={open}
      modalTitle="Team member"
      onClose={setOpen}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      noBottomPadding
    >
      <DeactivateMemberModal
        selectedMember={selectedMember}
        removeTeamMember={removeTeamMember}
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
        removingTeamMember={removingTeamMember}
      />
      <section className="flex flex-col gap-4 mb-8">
        <div className="flex items-center justify-center mt-8 bg-accent-300 w-20 h-20 mx-auto rounded-full">
          <p className="text-brandBlue text-lg font-medium">
            {selectedMember?.user?.first_name
              ? selectedMember?.user?.first_name[0]
              : '-'}
            .
            {selectedMember?.user?.last_name
              ? selectedMember?.user?.last_name[0]
              : '-'}
          </p>
        </div>
        <p className="text-grey text-sm font-medium">
          {selectedMember?.user?.first_name} {selectedMember?.user?.last_name}
        </p>
      </section>

      <div className="overflow-y-auto flex flex-col h-80 3xl:h-full max-h-full">
        <section className="mb-8 bg-white-900 rounded p-6">
          <RowData
            leftText="EMAIL ADDRESS"
            rightText={selectedMember?.email}
            bottomBorderOnly
            xPadding
            bolderText
          />
          <RowData
            leftText="ROLE"
            rightText={selectedMember?.permissions?.role}
            bottomBorderOnly
            xPadding
            smallerText
          />
          <RowData
            leftText="DATE ADDED"
            rightText={moment(selectedMember?.createdAt).format(
              'Do, MMM YYYY, hh:mm',
            )}
            bottomBorderOnly
            xPadding
          />
          <RowData
            leftText="APPS ADDED"
            customUI={
              <div className="flex flex-wrap justify-end gap-2">
                {APPS &&
                  APPS?.map((app, index) => (
                    <div key={index} className="flex items-center gap-2 w-fit">
                      <div className="rounded-full bg-brandBlue w-[6px] h-[6px]" />
                      <p className="text-sm text-body -mb-1">{app.name}</p>
                    </div>
                  ))}
              </div>
            }
            bottomBorderOnly
            xPadding
            alignStart
          />
          <RowData
            leftText="STATUS"
            customUI={
              <div
                className={`px-2 pt-[6px] pb-[2px] text-xs font-medium uppercase rounded-full w-fit ${
                  selectedMember?.status !== 'active'
                    ? diffInHours(
                        new Date(selectedMember?.createdAt),
                        new Date(),
                      ) > 24
                      ? 'text-accent-400 bg-danger200'
                      : 'text-accent-300 bg-darkBlue'
                    : 'bg-success400 text-accent-200'
                }`}
              >
                {selectedMember?.status !== 'active'
                  ? diffInHours(
                      new Date(selectedMember?.createdAt),
                      new Date(),
                    ) > 24
                    ? 'expired'
                    : 'pending'
                  : selectedMember?.status}
              </div>
            }
            bottomBorderOnly
            xPadding
          />
        </section>
      </div>

      <div
        className="-mx-6 flex items-center gap-4 p-6 overflow-x-hidden bg-white80"
        style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
      >
        <PrimaryButton
          fontSize="text-sm"
          buttonText="Edit member"
          className="w-full"
          onClick={() => {
            takeAction(selectedMember);
            setOpen(false);
          }}
        />

        <button
          type="button"
          className="bg-accent-400 rounded h-12 text-sm text-danger500 font-medium p-4 whitespace-nowrap hover:opacity-90"
          onClick={() => setDeactivateModal(true)}
        >
          Deactivate user
        </button>
      </div>
    </Modal>
  );
}
