import React, {useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {Menu} from '@headlessui/react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {check_export, suspend, trash, verify} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import LayoutContainer from '../../components/LayoutContainer';
import * as Actions from '../../store/actions';
import Dropdown from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import {
  exportSenderIds,
  fetchSenderIds,
  mutateSenderId,
} from '../../helpers/queryFns/senderId';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import {SearchInput} from '../../components/SearchInput';
import {EmptyState} from '../../components/EmptyState';

function ManageSendersIds({deleteSenderId}) {
  const [page, setCurrentPage] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const {data, isLoading, isFetching} = useQuery(
    ['sender_ids', {company: searchText, page}],
    () => fetchSenderIds({company: searchText, page}),
  );

  const {
    isLoading: loadingSenderIdsExport,
    isFetching: fetchingSenderIdsExport,
    refetch: refetchingSenderIdsExport,
  } = useQuery('export-users', () => exportSenderIds(), {
    enabled: false,
    onSuccess: () => {
      setOpen(false);
    },
  });

  const {mutate} = useMutation(mutateSenderId, {
    onSuccess: () => {
      toast.success('Sender ID status updated successfully');
      queryClient.invalidateQueries('sender_ids');
    },
  });

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <DashboardLayout headerText="Manage senders IDs">
      <LayoutContainer>
        <h2 className="text-base font-medium text-grey60">
          Manage senders IDs
        </h2>
        <div className="flex flex-wrap justify-between gap-4 mt-4">
          <SearchInput
            placeholder="Search sender IDs"
            onChange={text => setSearchText(text)}
          />
          <div className="flex flex-wrap items-center gap-4 ml-auto">
            <PrimaryButton
              buttonText="Export table"
              xPadding="px-6"
              className="font-medium"
              onClick={() => setOpen(!open)}
            />
            <Modal show={open} onClose={setOpen}>
              <div className="flex flex-col items-center px-12 pt-20 pb-10">
                <img className="w-[134px]" src={check_export} alt="" />
                <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                  Your export file is being prepared. When it is ready, it will
                  be sent to the email attached to this account.
                </h2>
                <PrimaryButton
                  buttonText="Continue"
                  xPadding="px-6"
                  className="w-full font-medium"
                  disabled={loadingSenderIdsExport || fetchingSenderIdsExport}
                  loadingState={
                    loadingSenderIdsExport || fetchingSenderIdsExport
                  }
                  onClick={refetchingSenderIdsExport}
                />
                <button
                  type="button"
                  className="inline-flex justify-center w-full mt-6 text-navGrey"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="mt-3">
          {isLoading ? (
            <Loader height={45} />
          ) : (
            <>
              {!data?.totalRecords ? (
                <EmptyState
                  title="No sender id listed"
                  body="No sender ID record is listed yet"
                />
              ) : (
                <TableLayout>
                  <thead className="text-xs font-semibold uppercase">
                    <tr className="border-b border-tableGrey">
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        Sender id
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        company
                      </th>
                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        created
                      </th>

                      <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                        status
                      </th>
                      <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap">
                        action
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="text-sm divide-y divide-gray-100"
                    style={{opacity: isFetching && 0.3}}
                  >
                    {data?.sender_ids?.map(sender => (
                      <tr
                        className="border-b border-tableGrey"
                        key={sender?.id}
                      >
                        <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                          {sender?.sender_id || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {sender?.company?.name || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {moment(sender?.createdAt).format('Do MMM, YYYY') ||
                            '-'}
                        </td>
                        <td
                          className={`p-3 text-left text-tiny text-${
                            sender?.activated ? 'success' : 'danger'
                          } whitespace-nowrap`}
                        >
                          {sender?.activated ? 'Active' : 'Inactive' || '-'}
                        </td>
                        <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                          <Dropdown>
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  className={classNames(
                                    active ? 'text-grey60' : 'text-grey60',
                                    `flex items-center gap-4 z-40  text-tiny font-medium ${
                                      !sender?.activated && 'text-success'
                                    }`,
                                  )}
                                  style={{color: sender?.activated && 'red'}}
                                  onClick={() =>
                                    mutate({
                                      sender_id: sender?.id,
                                      type: sender?.activated
                                        ? 'deactivate'
                                        : 'activate',
                                    })
                                  }
                                >
                                  <img
                                    src={sender?.activated ? suspend : verify}
                                    alt=""
                                  />
                                  {!sender?.activated
                                    ? 'Activate'
                                    : 'Deactivate'}
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {() => (
                                <button
                                  className="z-40 flex items-center gap-4 font-medium text-tiny text-danger"
                                  onClick={() => deleteSenderId(sender?.id)}
                                >
                                  <img src={trash} alt="" />
                                  Delete
                                </button>
                              )}
                            </Menu.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </TableLayout>
              )}
            </>
          )}

          <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
            <Pagination
              total={(data && data?.totalPages) || 0}
              current={+page}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
          </div>
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(ManageSendersIds);
