import {
  LOADING,
  BLOGS,
  BLOG_CATEGORIES,
  BLOG_SUMMARY,
  BLOG,
  IMAGE_URL,
} from '../types';

const initialState = {
  blog: null,
  blogs: null,
  blog_categories: null,
  blog_summary: null,
  img_url: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};
    case BLOG:
      return {...state, blog: action.payload};
    case IMAGE_URL:
      return {...state, img_url: action.payload};
    case BLOGS:
      return {...state, blogs: action.payload};
    case BLOG_CATEGORIES:
      return {...state, blog_categories: action.payload};
    case BLOG_SUMMARY:
      return {...state, blog_summary: action.payload};

    default:
      return state;
  }
}
