import React from 'react';
import {connect} from 'react-redux';
import {DashboardLayout} from '../../../components';

function VerificationWrapper({children, action}) {
  return (
    <DashboardLayout headerText="Verifications">
      {action}

      {children}
    </DashboardLayout>
  );
}

export default connect(state => state, null)(VerificationWrapper);
