import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function MessagingServices({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const {id} = useParams();

  const [messagingServices, setMessagingServices] = useState({});

  const {data: messagingServiceData, isLoading} = useQuery(
    ['messaging-services-query', id],
    () => getCompanyServices(id),
  );
  const pricingData =
    !isLoading && messagingServiceData && messagingServiceData?.pricing;

  useEffect(() => {
    const smsPrice = (pricingData?.sms ?? 0) / 100;
    const emailOtpPrice = (pricingData?.email_otp ?? 0) / 100;
    const whatsappOtpPrice = (pricingData?.whatsapp_otp ?? 0) / 100;
    const voiceOtpPrice = (pricingData?.voice_otp ?? 0) / 100;

    setMessagingServices({
      sms: smsPrice.toFixed(2),
      email_otp: emailOtpPrice.toFixed(2),
      whatsapp_otp: whatsappOtpPrice.toFixed(2),
      voice_otp: voiceOtpPrice.toFixed(2),
    });
  }, [pricingData]);

  return (
    <>
      {isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">Messaging services</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="sms"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Send SMS
                    <input
                      id="sms"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={messagingServices?.sms}
                      onChange={e => onUpdate({sms: e.target.value * 100})}
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="email_otp"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Email OTP
                    <input
                      id="email_otp"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={messagingServices?.email_otp}
                      onChange={e =>
                        onUpdate({email_otp: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                {/* <div> */}
                {/*  <label */}
                {/*    htmlFor="send_validate_sms_otp" */}
                {/*    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80" */}
                {/*  > */}
                {/*    Send/validate SMS OTP */}
                {/*    <input */}
                {/*      id="send_validate_sms_otp" */}
                {/*      type="number" */}
                {/*      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12" */}
                {/*      disabled */}
                {/*    /> */}
                {/*  </label> */}
                {/* </div> */}

                <div>
                  <label
                    htmlFor="whatsapp_otp"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Send whatsapp
                    <input
                      id="whatsapp_otp"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={messagingServices?.whatsapp_otp}
                      onChange={e =>
                        onUpdate({whatsapp_otp: e.target.value * 100})
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="space-y-4">
                {/* <div> */}
                {/*  <label */}
                {/*    htmlFor="send_whatsapp_msg" */}
                {/*    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80" */}
                {/*  > */}
                {/*    Send whatsapp messages */}
                {/*    <input */}
                {/*      id="send_whatsapp_msg" */}
                {/*      type="number" */}
                {/*      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12" */}
                {/*      disabled */}
                {/*    /> */}
                {/*  </label> */}
                {/* </div> */}

                <div>
                  <label
                    htmlFor="voice_otp"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Send voice OTP
                    <input
                      id="voice_otp"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={messagingServices?.voice_otp}
                      onChange={e =>
                        onUpdate({voice_otp: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                {/* <div> */}
                {/*  <label */}
                {/*    htmlFor="send_voice_sms" */}
                {/*    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80" */}
                {/*  > */}
                {/*    Send voice SMS */}
                {/*    <input */}
                {/*      id="send_voice_sms" */}
                {/*      type="number" */}
                {/*      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12" */}
                {/*      disabled */}
                {/*    /> */}
                {/*  </label> */}
                {/* </div> */}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
