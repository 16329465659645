import React from 'react';
import {
  getCompanyApps,
  getCompanyInfo,
  getCompanyWidgets,
  getCompanyBillings,
  getCompanyLogs,
  getCompanyUsers,
  getCompanyAuditTrail,
} from '../queryFns/fetchCompanyDetails';
import {
  ApiLogsIcon,
  CompanyIcon,
  DashboardIcon,
  UsersIcon,
  BillingsIcon,
  ManageSenderIdsIcon,
  AdminsIcon,
  AuditTrailIcon,
  ProfileIcon,
  BlogIcon,
  WalletIcon,
} from '../../components/Svgs';
import {VerificationsIcon} from '../../components/Svgs/VerificationsIcon';

const NAV_BAAS = [
  {
    href: '/baas/settlement',
    icon: <WalletIcon />,
    text: 'Wallet Settlement',
  },
];
const NAV_RECONCILO = [
  {
    href: '/reconcilo/company',
    icon: <CompanyIcon />,
    text: 'Company',
  },
  {
    href: '/reconcilo/users',
    icon: <UsersIcon />,
    text: 'Users',
  },
];

const NAV_ITEMS_BASE_ANALYTICS = [
  {
    href: '/dashboard',
    icon: <DashboardIcon />,
    text: 'Dashboard',
  },
  {
    href: '/company',
    icon: <CompanyIcon />,
    text: 'Company',
  },
  {
    href: '/users',
    icon: <UsersIcon />,
    text: 'Users',
  },
  {
    href: '/api-logs',
    icon: <ApiLogsIcon />,
    text: 'Api Logs',
  },
  {
    href: '/billings',
    icon: <BillingsIcon />,
    text: 'Billings',
  },
  {
    href: '/verifications',
    icon: <VerificationsIcon />,
    text: 'Verifications',
  },
];

const NAV_ITEMS_OTHERS = [
  {
    href: '/manage-senders-ids',
    icon: <ManageSenderIdsIcon />,
    text: 'Manage Sender IDs',
  },
];

const NAV_ITEMS_SETTINGS = [
  {
    href: '/admins',
    icon: <AdminsIcon />,
    text: 'Admins',
  },
  {
    href: '/audit-trail',
    icon: <AuditTrailIcon />,
    text: 'Audit Trail',
  },
  {
    href: '/profile',
    icon: <ProfileIcon />,
    text: 'Profile',
  },
];

const NAV_ITEMS_BLOG = [
  {
    href: '/blog',
    icon: <BlogIcon />,
    text: 'Blog',
  },
];

const BAAS_TABS = [
  {
    href: 'settings',
    text: 'BAAS Settlement Settings',
    queryKey: 'companyInfo-query',
  },
];

const COMPANY_TABS = [
  {
    href: '',
    text: 'Company information',
  },
  {
    href: 'services',
    text: 'Services',
  },
  {
    href: 'users',
    text: 'Users',
    queryKey: 'users-query',
    getInfo: getCompanyUsers,
  },
  {
    href: 'apps',
    text: 'Apps',
    queryKey: 'app-query',
    getInfo: getCompanyApps,
  },
  {
    href: 'api-logs',
    text: 'API logs',
    queryKey: 'logs-query',
    getInfo: getCompanyLogs,
  },
  {
    href: 'billings',
    text: 'Billings',
    queryKey: 'billings-query',
    getInfo: getCompanyBillings,
  },
  {
    href: 'widget',
    text: 'Widgets',
    queryKey: 'widget-query',
    getInfo: getCompanyWidgets,
  },
  {
    href: 'compliance',
    text: 'Compliance',
  },
  {
    href: 'settings',
    text: 'Settings',
    queryKey: 'companyInfo-query',
    getInfo: getCompanyInfo,
  },
  {
    href: 'audit-trail',
    text: 'Audit Trail',
    queryKey: 'audit-trail-query',
    getInfo: getCompanyAuditTrail,
  },
  {
    href: 'teams',
    text: 'Teams',
  },
];

const EXCLUDED_TABS = {
  baas: [
    'Services',
    'Compliance',
    'Company information',
    'Apps',
    'API logs',
    'Billings',
    'Widgets',
    'Users',
    'Audit Trail',
    'Teams',
  ],
  compliance: [
    'Settings',
    'Services',
    'Apps',
    'API logs',
    'Billings',
    'Widgets',
    'Users',
    'Audit Trail',
    'Teams',
  ],
};

function filterTabs(role) {
  const excludedTabs =
    role === 'Compliance' ? EXCLUDED_TABS.compliance : EXCLUDED_TABS.baas;
  return COMPANY_TABS.filter(tab => !excludedTabs.includes(tab.text));
}

export {
  NAV_ITEMS_BASE_ANALYTICS,
  NAV_RECONCILO,
  NAV_ITEMS_OTHERS,
  NAV_ITEMS_SETTINGS,
  NAV_ITEMS_BLOG,
  COMPANY_TABS,
  NAV_BAAS,
  filterTabs,
  BAAS_TABS,
};
