import React, {useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {useMutation, useQuery} from 'react-query';
import moment from 'moment';
import {
  FilterButton,
  LayoutContainer,
  Loader,
  Pagination,
  TableLayout,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {SearchInput} from '../../../components/SearchInput';
import {eye} from '../../../assets/images/images';
import VerificationWrapper from './VerificationWrapper';
import VerificationsFilter from './VerificationsFilter';
import PAGES_URL from '../../pageRoutes';
import {
  assignReviewer,
  fetchVerifications,
} from '../../../helpers/queryFns/verifications';
import {EmptyState} from '../../../components/EmptyState';
import {
  getStatusStyles,
  getStatusWithNumbers,
} from '../../../helpers/getStatusStyles';
import VerificationLayout from './VerificationsLayout';

function Verifications({auth: {user}}) {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const page = Number(query.get('page') || 1);
  const appIds = localStorage.getItem('appIds');
  const search = query.get('search');

  const [searchText, setSearchText] = useState(search || '');
  const [openFilter, setOpenFilter] = useState(false);
  const initialFilters = useMemo(
    () => ({
      appIds,
      app_ids: appIds,
      limit: 10,
      mode: '',
      page,
      term: searchText,
      review_status: 0,
      type: '',
      start: '',
      end: '',
    }),
    [appIds, page, searchText],
  );

  const removeFalsyValues = (obj = {}) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (!obj[key]) {
        // eslint-disable-next-line no-param-reassign
        delete obj[key];
      }
    }
  };

  const [vFilters, setFilters] = useState(initialFilters);
  const handleChangeCurrentPage = e => {
    navigate(`?page=${e}`);
  };

  const handleFilter = () => {
    setFilters(vFilters);
  };

  const params = useMemo(() => ({...vFilters}), [vFilters]);
  removeFalsyValues(params);

  const {
    data: verificationData,
    isLoading,
    isFetching,
  } = useQuery(
    ['verifications-data', {...params, page}],
    () => fetchVerifications({...params, page}),
    {
      retry: false,
    },
  );

  const handleResetFilter = () => {
    setFilters(initialFilters);
  };

  const {mutate: assignVerificationToReviewer} = useMutation(assignReviewer);

  return (
    <VerificationWrapper
      action={
        <VerificationsFilter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onCheck={data => {
            setFilters({...vFilters, ...data});
          }}
          appFilters={vFilters}
          handleFilter={handleFilter}
          resetFilters={handleResetFilter}
          loading={isLoading || isFetching}
        />
      }
    >
      <VerificationLayout>
        <LayoutContainer>
          <div>
            <div className="flex flex-wrap gap-4">
              <SearchInput
                onChange={value => {
                  setSearchText(value);
                  setFilters({...vFilters, term: value});
                }}
                placeholder="Search verirification"
                defaultValue={searchText}
              />
              <FilterButton
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
              {/* <PrimaryButton */}
              {/*  buttonText="Export table" */}
              {/*  xPadding="px-6" */}
              {/*  className="font-medium" */}
              {/* /> */}
            </div>
          </div>

          {isLoading ? (
            <Loader />
          ) : verificationData?.data.length === 0 ? (
            <EmptyState title="No record found" />
          ) : (
            <div className="mt-3" style={{opacity: isFetching ? 0.6 : 1}}>
              <TableLayout>
                <thead className="text-xs font-semibold uppercase">
                  <tr className="border-b border-tableGrey">
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      name
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Email address
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      reference id
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      status
                    </th>{' '}
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      {"Reviewer's status"}
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      assignee
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      date/time created
                    </th>
                    <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap" />
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {verificationData &&
                    verificationData?.data.map((item, i) => (
                      <tr
                        className="border-b border-tableGrey"
                        key={i + item?.reference_id}
                        onClick={async () => {
                          navigate(
                            PAGES_URL.VERIFICATIONSBIODATA.replace(
                              ':slug',
                              item?.verification_id,
                            ),
                            {
                              state: {
                                sessionId: item?.verification_id,
                              },
                            },
                          );
                          // eslint-disable-next-line no-unused-expressions
                          !item?.Asignee &&
                            (await assignVerificationToReviewer({
                              refId: item.verification_id,
                              data: {
                                reviewer: user?.email,
                              },
                            }));
                        }}
                      >
                        <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                          {item?.full_name || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {item?.email || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {item?.reference_id || '-'}
                        </td>
                        <td className="p-3 text-tiny text-pending whitespace-nowrap">
                          <div
                            className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium  rounded-full w-fit items-start gap-[2px]
                        ${getStatusStyles(item.status?.toLowerCase()).styles}`}
                          >
                            {getStatusStyles(item?.status?.toLowerCase())
                              .text || '-'}
                          </div>
                        </td>
                        <td className="p-3 text-tiny text-pending whitespace-nowrap">
                          {![null, undefined, 0].includes(
                            item?.review_status,
                          ) ? (
                            <div
                              className={`flex uppercase pt-[5px] pb-[3px] px-2 text-xs font-medium  rounded-full w-fit items-start gap-[2px]
                        ${
                          getStatusStyles(
                            getStatusWithNumbers(item?.review_status),
                          ).styles
                        }`}
                            >
                              {
                                getStatusStyles(
                                  getStatusWithNumbers(item?.review_status),
                                ).text
                              }
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {item?.Asignee || '-'}
                        </td>
                        <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                          {moment(item.datetime).format('Do MMM YYYY h:m a')}
                        </td>
                        <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                          <button>
                            <img src={eye} alt="" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableLayout>

              <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
                <Pagination
                  total={
                    verificationData &&
                    Math.ceil(
                      verificationData?.meta?.total_count /
                        verificationData?.meta?.item_per_page,
                    )
                  }
                  current={+page}
                  onPageChange={handleChangeCurrentPage}
                />
              </div>
            </div>
          )}
        </LayoutContainer>
      </VerificationLayout>
    </VerificationWrapper>
  );
}

export default connect(state => state, Actions)(Verifications);
