import * as Helpers from '../../helpers';
import {LOADING, ALL_COMPANIES, COMPANY_INFO} from '../types';

export const getCompanies = data =>
  Helpers.api(
    `/admin/companies?page=${data?.page || 1}&limit=${
      data?.limit ?? '10'
    }&start=${data?.start ?? ''}&end=${data?.end ?? ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: ALL_COMPANIES},
  );
export const getCompanyInfo = data =>
  Helpers.api(
    `/admin/company/${data?.companyId || ''}?page=${data?.page || 1}&limit=${
      data?.limit || '10'
    }&start=${data?.start || ''}&end=${data?.end || ''}`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: COMPANY_INFO},
  );
