import React, {useState} from 'react';
import Toggle from '../../../components/Toggle';
import {servicesList} from '../dashboardServices';
import GreyButton from '../../../components/GreyButton';
import {sortObjectsAlphabetically} from '../../../helpers/sortAlphabetically';
import {Accordion} from '../../../components/Accordion';

const moduleLimit = 6;

export function DashboardModularization({settings, setSettings}) {
  const [next, setNext] = useState(moduleLimit);

  const EASY_ONBOARD_SERVICES = 'easy_onboard_services';
  const FRAUD_DECISION_SERVICES = 'fraud_decision_services';

  const handleToggle = (value, service) => {
    const newSettings = {
      ...settings,
      [service.key]: value,
      ...(service.key === EASY_ONBOARD_SERVICES && {
        id_verification_services: value,
      }),
      ...(service.key === FRAUD_DECISION_SERVICES && {
        reconciliation: value,
        custom_link: value,
      }),
    };

    setSettings(newSettings);
  };

  const alphabeticalServicesList = sortObjectsAlphabetically(
    servicesList?.sort((a, b) =>
      settings[a.key] === settings[b.key] ? 0 : settings[a.key] ? -1 : 1,
    ),
    'name',
  );

  return (
    <div className="mt-4">
      <Accordion title="Dashboard Modularization" background="bg-white-900">
        <section className="grid grid-cols-1  md:grid-cols-2 gap-x-4 lg:gap-x-8 w-wull">
          {alphabeticalServicesList.slice(0, next).map(service => (
            <div className="mt-4 w-full" key={service.key}>
              <label className="flex items-start text-left text-sm label text-grey60 mb-1">
                {service.name}
              </label>
              <div className="relative w-full flex gap-2 items-center justify-between cursor-pointer rounded border border-grey20 bg-grey20 py-[14px] px-4 text-left h-12 focus:outline-none focus:border focus:border-brandBlue">
                <Toggle
                  enabled={settings[service.key]}
                  setEnabled={e => handleToggle(e, service)}
                />
              </div>
            </div>
          ))}
        </section>
        {next < alphabeticalServicesList?.length && (
          <GreyButton
            className="text-center w-full items-center  mt-6"
            fontSize="text-sm"
            xPadding="px-4"
            yPadding="pt-[16px] pb-[16px]"
            height="h-7"
            buttonText="Load more"
            onClick={() => setNext(next + moduleLimit)}
          />
        )}
      </Accordion>
    </div>
  );
}
