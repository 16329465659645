const getStatusStyles = status =>
  ({
    successful: {styles: 'text-success20 bg-success', text: 'Successful'},
    completed: {styles: 'text-success20 bg-success', text: 'Successful'},
    pending: {styles: 'bg-pending-200 text-pending', text: 'Pending'},
    failed: {styles: 'text-white bg-danger', text: 'Failed'},
    started: {styles: 'bg-disabled', text: 'Created'},
    migrated: {styles: 'text-success20 bg-success', text: 'Migrated'},
    'not-migrated': {styles: 'bg-disabled text-grey', text: 'Not Migrated'},
  }[status] || {styles: 'bg-disabled text-grey', text: 'Created'});

const getStatusWithNumbers = number =>
  ({
    1: 'pending',
    2: 'successful',
    3: 'failed',
  }[number] || 'pending');

export {getStatusWithNumbers, getStatusStyles};
