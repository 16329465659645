/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import {dropdownFillGrey} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import PrimaryButton from '../../components/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton';
import {
  DropdownIndicator,
  MultiValueRemove,
  customStyles,
} from '../../components/ReactSelect';
import {handleFileInput} from '../../helpers';
import * as Actions from '../../store/actions';
import {fetchAdminUsers} from '../../helpers/queryFns/admin';
import Loader from '../../components/Loader';
import GuestCollaborators from './GuestCollaborators';
import Editor3 from './Editor3';

const AppEnv = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
});

const url =
  process.env.REACT_APP_APP_ENV_MODE === AppEnv.PRODUCTION
    ? 'https://dojah.io'
    : process.env.REACT_APP_APP_ENV_MODE === AppEnv.DEVELOPMENT
    ? 'https://dev-web.dojah.services'
    : 'https://staging-web.dojah.services';

function Accordion({children, groupName}) {
  const [accordionOpen, setAccordionOpen] = useState(true);
  return (
    <>
      <div className={`bg-white p-4 ${accordionOpen && 'pb-10'} rounded`}>
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setAccordionOpen(!accordionOpen)}
        >
          <p className="text-grey60 text-sm font-medium">{groupName}</p>
          <img
            src={dropdownFillGrey}
            alt=""
            className={`transition-all duration-300 ${
              accordionOpen ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </div>

        {accordionOpen && children}
      </div>
    </>
  );
}

function EditBlog({
  getBlog,
  updateBlog,
  imageUpload,
  blog: {blog, blog_categories, loading},
}) {
  const {data: adminUsers, isLoading} = useQuery(
    ['admin-users', {page: 1, limit: 10000}],
    () =>
      fetchAdminUsers({
        page: 1,
        limit: 10000,
      }),
  );

  const ADMINS =
    useMemo(
      () =>
        !isLoading &&
        adminUsers &&
        adminUsers?.users?.map(admin => ({
          label: `${admin?.first_name} ${admin?.last_name}` || '',
          value: admin?._id,
          name: `${admin?.first_name} ${admin?.last_name}` || '',
        })),
      [adminUsers, isLoading],
    ) || [];

  const CATEGORIES_DATA = useMemo(
    () =>
      blog_categories &&
      blog_categories?.categories?.map(category => ({
        label: category?.name,
        value: category?._id,
        name: category?.name,
      })),
    [blog_categories],
  );
  const DEFAULT_SELECTED_CATEGORIES = useMemo(
    () =>
      blog &&
      blog?.post?.categories?.map(category => ({
        label: category.name,
        value: category._id,
        name: category.name,
      })),
    [blog],
  );
  const SELECTED_AMIN = useMemo(() =>
    blog && blog?.post?.collaborators
      ? blog?.post?.collaborators?.map(admin => ({
          label: `${admin?.first_name} ${admin?.last_name}` || '',
          value: `${admin?._id}`,
          name: `${admin?.first_name} ${admin?.last_name}` || '',
          key: admin?._id,
        }))
      : [blog?.post?.author]?.map(admin => ({
          label: `${admin?.first_name} ${admin?.last_name}`,
          value: admin?._id,
          name: `${admin?.first_name} ${admin?.last_name}`,
          key: admin?._id,
        }))[blog?.post?.collaborators],
  );
  const [selectedCategories, setSelectedCategories] = useState();
  const [selectedAdmins, setSelectedAdmins] = useState([...SELECTED_AMIN]);

  const [blogData, setBlogData] = useState();
  const [coverImage, setCoverImage] = useState({
    file: '',
    filename: '',
  });
  const [errors, setErrors] = useState({});
  const [guestCollaborators, setGuestCollaborators] = useState(
    blog?.post?.guest_collaborators || [],
  );

  const blogId = window.location.pathname?.split('/')[2];

  useEffect(() => {
    getBlog(blogId, blog?.post?.published);

    if (blog) {
      setBlogData({
        title: blog?.post?.title,
        summary: blog?.post?.summary,
        body: blog?.post?.body,
        categories: [...blog?.post?.categories]?.map(item => item?._id),
        cover_img: blog?.post?.cover_img,
        collaborators: blog?.post?.collaborators
          ? blog?.post?.collaborators.map(c => c?._id)
          : [blog?.post?.author?._id],
        tags: blog?.post?.tags,
        slug: blog?.post?.slug,
        guest_collaborators: blog?.post?.guest_collaborators,
      });
    }
    // eslint-disable-next-line no-unused-expressions
    !isLoading && setSelectedAdmins(SELECTED_AMIN);
    setSelectedCategories(DEFAULT_SELECTED_CATEGORIES);
  }, [isLoading]);

  const checkErrors = useCallback(() => {
    const {
      title,
      summary,
      body,
      categories,
      collaborators,
      guest_collaborators,
      cover_img,
    } = blogData;
    if (!title) errors.title = 'Blog title is required';
    if (!summary) errors.summary = 'Blog description is required';
    if (!body) errors.body = 'Blog body is required';
    if (!categories || categories.length === 0) {
      errors.categories = 'Blog category is required';
    }
    if (
      (!collaborators || collaborators.length === 0) &&
      (!guest_collaborators || guest_collaborators.length === 0)
    ) {
      errors.collaborators = 'Blog author is required';
    }
    if (!cover_img) errors.cover_img = 'Cover image is required';
    setErrors(errors);
  }, [errors, blogData]);

  const handlePost = status => {
    checkErrors();
    if (Object.keys(errors).length === 0) {
      const updatedBlogData = {
        ...blogData,
        published: status,
      };
      if (!updatedBlogData.slug) {
        delete updatedBlogData.slug;
      }

      if (guestCollaborators.length > 0) {
        // sanitize before sending to server because the server is expecting name and image
        const sanitizedGuestCollaborators = guestCollaborators.map(
          collaborator => {
            const {imageFile, isGeneratingImgUrl, ...sanitizedCollaborator} =
              collaborator;
            return sanitizedCollaborator;
          },
        );
        updatedBlogData.guest_collaborators = sanitizedGuestCollaborators;
      } else {
        delete updatedBlogData.guest_collaborators;
      }

      if (
        Array.isArray(updatedBlogData.tags) &&
        updatedBlogData.tags.length > 0
      ) {
        updatedBlogData.tags = updatedBlogData.tags.filter(
          tag => tag.trim() !== '',
        );
      } else {
        delete updatedBlogData.tags;
      }

      if (updatedBlogData.cover_img.includes('base64')) {
        imageUpload({
          updateData: {id: blogId, ...updatedBlogData},
        });
      }
      updateBlog({id: blogId, ...updatedBlogData});
    } else {
      Object.keys(errors).find(key => toast.error(errors[key]));
    }
  };

  function formatSlug(input) {
    return input
      .replace(/\s+/g, '-')
      .replace(/[^a-zA-Z0-9-]/g, '')
      .toLowerCase();
  }

  return (
    <DashboardLayout
      className="bg-white-900 pt-8 min-h-screen"
      headerText="Edit Blog"
      noMarginTop
    >
      {loading || isLoading ? (
        <Loader />
      ) : (
        <LayoutContainer>
          <div className="flex gap-8 items-start basis-full">
            <div className="basis-[67.49%] bg-white shadow-card px-10 py-6 rounded">
              <div className="mt-6 flex items-center gap-2">
                {!blog?.post?.hasBeenPublished && (
                  <PrimaryButton
                    buttonText="Save as draft"
                    xPadding="px-6"
                    className="ml-auto"
                    onClick={() => handlePost(false)}
                    loadingState={loading}
                    style={{height: 48, width: 140}}
                    disabled={loading}
                  />
                )}
                <SecondaryButton
                  onClick={() => {
                    getBlog(blogId, blog?.post?.published);
                    setTimeout(() => {
                      window.open(
                        `${url}/api/preview?secret=MY_SECRET_TOKEN&slug=/blog/${blog?.post?.slug}`,
                        '_blank',
                      );
                    }, 1000);
                  }}
                  buttonText="Preview article"
                  className={blog?.post?.hasBeenPublished && 'ml-auto'}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="title"
                  className="w-full flex flex-col items-start mb-4 text-sm label text-grey60 font-medium max-w-full"
                >
                  Enter Title
                  <input
                    id="title"
                    required
                    value={blogData?.title}
                    className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
                    onChange={e => {
                      setBlogData({...blogData, title: e.target.value});
                      setErrors({});
                    }}
                  />
                </label>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="summary"
                  className="w-full flex flex-col items-start mb-4 text-sm label text-grey60 font-medium max-w-full"
                >
                  Enter summary
                  <input
                    id="summary"
                    required
                    value={blogData?.summary}
                    className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
                    onChange={e => {
                      setBlogData({...blogData, summary: e.target.value});
                      setErrors({});
                    }}
                  />
                </label>
              </div>
              <Editor3
                bodyValue={blogData?.body || ''}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setBlogData({...blogData, body: data});
                  setErrors({});
                }}
              />
            </div>
            <div className="basis-[32.51%] flex flex-col gap-4">
              <PrimaryButton
                buttonText={
                  blog?.post?.hasBeenPublished || blog?.post?.published
                    ? 'Update article'
                    : 'Publish article'
                }
                xPadding="px-6"
                // className="ml-auto"
                onClick={() => handlePost(true)}
                loadingState={loading}
                style={{height: 48, width: 150}}
                disabled={loading}
              />
              <Accordion groupName="Categories">
                <div className="mt-7">
                  <label
                    htmlFor="categories"
                    className="mt-6 relative text-xs font-medium text-grey60"
                  >
                    Select categories
                  </label>
                  <Select
                    defaultValue={selectedCategories}
                    onChange={data => {
                      setSelectedCategories(data);
                      const categories = data && data?.map(item => item?.value);
                      setBlogData({
                        ...blogData,
                        categories,
                      });
                      setErrors({});
                    }}
                    options={CATEGORIES_DATA}
                    isMulti
                    multiValueLabel
                    placeholder="Select categories"
                    className="basic-multi-select"
                    classNamePrefix="react-select"
                    components={{DropdownIndicator, MultiValueRemove}}
                    styles={customStyles}
                    menuPlacement="auto"
                  />
                </div>
              </Accordion>

              <Accordion groupName="Add cover image">
                <label className="mt-6">
                  <input
                    accept="image/*"
                    type="file"
                    onChange={e => {
                      handleFileInput(e.target.files[0], data => {
                        setBlogData({
                          ...blogData,
                          cover_img: data?.file,
                        });
                        setCoverImage({
                          ...coverImage,
                          file: data?.file,
                          filename: data?.filename,
                        });
                        setErrors({});
                      });
                    }}
                  />
                  <div className="mt-10 p-4 cursor-pointer bg-white-900 text-center border border-brandBlue border-dashed rounded w-full">
                    <p
                      className={`${
                        coverImage.filename ? 'text-black' : 'text-grey60'
                      } text-sm font-medium`}
                    >
                      {coverImage.filename ||
                        'Click here to upload cover image'}
                    </p>
                  </div>
                </label>

                <img
                  src={coverImage.file || blogData?.cover_img}
                  alt=""
                  className="mt-4"
                />
              </Accordion>

              <Accordion groupName="Add custom slug">
                <label
                  htmlFor="author"
                  className="mt-7 w-full flex flex-col items-start text-xs label text-grey60 font-medium max-w-full relative"
                >
                  Edit slug
                  <input
                    className="input w-full max-w-full text-sm mt-1 outline outline-1 outline-grey-100"
                    type="text"
                    value={blogData?.slug}
                    onChange={e => {
                      const inputSlug = e.target.value;
                      const formattedSlug = formatSlug(inputSlug);

                      setBlogData({...blogData, slug: formattedSlug});
                      setErrors({});
                    }}
                    onPaste={e => {
                      e.preventDefault();
                      const pastedText = e.clipboardData.getData('text/plain');
                      const sanitizedSlug = formatSlug(pastedText);

                      setBlogData({...blogData, slug: sanitizedSlug});
                      setErrors({});
                    }}
                  />
                </label>
              </Accordion>

              <Accordion groupName="Author">
                <label
                  htmlFor="author"
                  className="mt-7 w-full flex flex-col items-start text-xs label text-grey60 font-medium max-w-full relative"
                >
                  Select author(s) (max 5)
                </label>
                <Select
                  defaultValue={selectedAdmins}
                  onChange={data => {
                    setSelectedAdmins(data);
                    const collaborators =
                      data && data?.map(item => item?.value);
                    setBlogData({
                      ...blogData,
                      collaborators,
                    });
                    setErrors({});
                  }}
                  options={ADMINS}
                  isMulti
                  multiValueLabel
                  placeholder="Select author(s)"
                  className="basic-multi-select"
                  classNamePrefix="react-select"
                  components={{DropdownIndicator, MultiValueRemove}}
                  styles={customStyles}
                  menuPlacement="auto"
                  isOptionDisabled={() => selectedAdmins?.length >= 5}
                />
              </Accordion>

              <Accordion groupName="Guest author (if any)">
                <GuestCollaborators
                  guestCollaborators={guestCollaborators}
                  setGuestCollaborators={setGuestCollaborators}
                  setBlogData={setBlogData}
                />
              </Accordion>

              <Accordion groupName="Tags">
                <p className="mt-2 text-grey60 font-medium text-[0.6785rem]">
                  Add SEO tags and tags here, you can separate tags using comma
                </p>

                <label
                  htmlFor="tags"
                  className="mt-5 w-full flex flex-col items-start text-xs label text-grey60 font-medium max-w-full"
                >
                  Add tags
                  <input
                    id="tags"
                    required
                    value={blogData?.tags?.toString()}
                    className="w-full py-4 pl-4 mt-1 input text-sm bg-grey20 max-w-full"
                    onChange={e => {
                      setBlogData({
                        ...blogData,
                        tags: e.target?.value?.split(','),
                      });
                      setErrors({});
                    }}
                  />
                </label>

                <div className="flex flex-col gap-4">
                  <span className="mt-2 text-grey-200 font-medium text-xs">
                    Seperate tags using comma
                  </span>

                  {/* <button className="inline-flex items-center justify-center gap-2 border border-black-300 rounded py-4 bg-white-900 max-w-full w-full h-12">
                  <span className="text-sm text-grey-100 font-medium">
                    Add tag
                  </span>
                </button> */}
                </div>
              </Accordion>
            </div>
          </div>
        </LayoutContainer>
      )}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(EditBlog);
