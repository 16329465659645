import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {connect} from 'react-redux';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import DateRangeFilter from '../../components/DateFilter';
import {getCompanyBillings} from '../../helpers/queryFns/fetchCompanyDetails';
import Loader from '../../components/Loader';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';
import {EmptyState} from '../../components/EmptyState';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';

function CompanyBillings({company: {company_info, settings}}) {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');
  const [{start, end}, setDateFilters] = useState({});

  const pageClick = selected => setCurrentPage(selected);

  const billingsQuery = useQuery(['billings-query', {end, id, start}], () =>
    getCompanyBillings(id, {end, page: currentPage, start}),
  );

  return (
    <DashboardLayout className="mt-4" headerText="Company/Billings">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5 mb-3">
            {billingsQuery?.isLoading ? (
              <Loader />
            ) : (
              <>
                {!billingsQuery?.data?.totalRecords ? (
                  <EmptyState
                    height={45}
                    title="No bill listed"
                    body="No bill is listed yet"
                  />
                ) : (
                  <>
                    <div className="flex flex-wrap items-center justify-end gap-4">
                      <h4 className="font-medium text-tiny text-grey60">
                        Filter by:
                      </h4>
                      <DateRangeFilter
                        onChange={date => setDateFilters(date)}
                      />
                    </div>
                    <TableLayout>
                      <thead className="text-xs font-semibold uppercase">
                        <tr className="border-b border-tableGrey">
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            reference number
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            amount
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            prev. balance
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            balance
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            type
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            created
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            status
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-sm divide-y divide-gray-100"
                        style={{opacity: billingsQuery.isFetching && 0.4}}
                      >
                        {billingsQuery?.data?.billing?.map((bill, i) => (
                          <tr className="border-b border-tableGrey" key={i}>
                            <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                              {bill?.reference_number ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {formatNumberToCurrency(
                                bill?.amount / 100,
                                company_info?.company?.currency,
                                settings?.settings?.dollar_exchange_rate,
                              ) ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {formatNumberToCurrency(
                                bill?.prev_balance / 100,
                                company_info?.company?.currency,
                                settings?.settings?.dollar_exchange_rate,
                              ) ?? 0}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {formatNumberToCurrency(
                                bill?.balance / 100,
                                company_info?.company?.currency,
                                settings?.settings?.dollar_exchange_rate,
                              ) ?? 0}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {bill?.type ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {moment(bill?.createdAt).format('Do MMM, YYYY') ??
                                '-'}
                            </td>
                            <td
                              className={`p-3 text-tiny text-left whitespace-nowrap ${
                                bill?.status === 'Successful'
                                  ? 'text-success'
                                  : bill?.status === 'Initiated'
                                  ? 'text-danger'
                                  : null
                              }`}
                            >
                              {bill?.status ?? '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableLayout>
                  </>
                )}
              </>
            )}
          </div>
          <div className="mt-4 mb-8">
            <Pagination
              total={billingsQuery?.data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}

export default connect(state => state, null)(CompanyBillings);
