import {LOADING, ADMINS, ADMIN} from '../types';

const initialState = {
  admin: null,
  admins: null,
  loading: null,
};

export default function admins(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};
    case ADMINS:
      return {...state, admins: action.payload};
    case ADMIN:
      return {...state, admin: action.payload};

    default:
      return state;
  }
}
