import React from 'react';
import {Menu} from '@headlessui/react';
import {dropdownWhite, edit, search, trash} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import Pagination from '../../components/Pagination';
import Dropdown from '../../components/Dropdown';
import LayoutContainer from '../../components/LayoutContainer';

export default function BlogCategories() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <DashboardLayout headerText="Blog Categories">
      <LayoutContainer>
        <div>
          <h5 className="mb-2 text-tiny text-grey80">Category name</h5>
          <div className="flex flex-wrap gap-2">
            <label htmlFor="category" className="label">
              <input
                name="category"
                placeholder="Input Category"
                className="input"
              />
            </label>
            <PrimaryButton buttonText="Add" className="px-12 font-medium" />
          </div>
        </div>

        <div>
          <h4 className="mt-12 mb-4 font-medium text-grey60">
            Categories list
          </h4>
          <div className="flex flex-wrap justify-between gap-4">
            <label htmlFor="search" className="relative label">
              <input
                name="search"
                placeholder="Search list"
                className="input"
              />
              <img src={search} alt="" className="absolute top-4 right-4" />
            </label>
          </div>
        </div>

        <div className="mt-3">
          <TableLayout>
            <thead className="text-xs font-semibold uppercase">
              <tr className="border-b border-tableGrey">
                <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                  category name
                </th>
                <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap">
                  actions
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {Array.from({length: 10}, (_, i) => (
                <tr className="border-b border-tableGrey" key={i}>
                  <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                    Engineering
                  </td>
                  <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                    <Dropdown>
                      <Menu.Item>
                        {({active}) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'text-grey60' : 'text-grey60',
                              'flex items-center gap-2 text-tiny',
                            )}
                          >
                            <img src={edit} alt="" />
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({active}) => (
                          <button
                            href="#"
                            className={classNames(
                              active ? 'text-danger' : 'text-danger',
                              'flex items-center gap-2 text-tiny',
                            )}
                          >
                            <img src={trash} alt="" />
                            Delete post
                          </button>
                        )}
                      </Menu.Item>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableLayout>

          <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
            <Pagination />
            <p className="flex items-center gap-2 text-tiny text-grey60">
              Showing{' '}
              <span className="flex p-2 text-white rounded bg-brandBlue">
                100
                <img src={dropdownWhite} alt="" />
              </span>
              out of 100
            </p>
          </div>
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}
