import React, {createContext, useReducer} from 'react';

export const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_DATE':
      return {
        ...state,
        date: action.date,
      };
    case 'RESET':
      return {
        ...state,
        date: '',
      };
    default:
      return state;
  }
};

export const FilterContext = createContext();

function FilterContextProvider({children}) {
  const [filter, dispatch] = useReducer(reducer, {date: ''});

  return (
    <FilterContext.Provider value={{filter, dispatch}}>
      {children}
    </FilterContext.Provider>
  );
}

export default FilterContextProvider;
