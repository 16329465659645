import React from 'react';
import {components} from 'react-select';
import {
  dropdown,
  removeService,
  checkedBox,
  uncheckedBox,
} from '../assets/images/images';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <img src={dropdown} alt="" width={16} height={16} />
    </components.DropdownIndicator>
  );
}

function MultiValueRemove(props) {
  return (
    <components.MultiValueRemove {...props}>
      <img src={removeService} alt="" width={10} height={10} />
    </components.MultiValueRemove>
  );
}

function Option(props) {
  return (
    <components.Option {...props}>
      <div
        // className=""
        className={`flex items-center gap-2 ${
          props?.data?.isActive
            ? 'bg-brandBlue w-[470px] text-white self-center -left-3 px-3 py-1 top-0 relative'
            : ''
        }`}
      >
        {props?.isSelected || props?.data?.isActive ? (
          <img src={checkedBox} alt="" width={14} height={14} />
        ) : (
          <img src={uncheckedBox} alt="" width={14} height={14} />
        )}
        <span className="mt-1">{props?.children}</span>
      </div>
    </components.Option>
  );
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#8e90a9',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.26666666666667em 0.5em 0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const customStyles = {
  control: base => ({
    ...base,
    backgroundColor: '#F9F9F9',
    border: '1px solid #CECED2',
    boxShadow: 'none',
    textAlign: 'left',
  }),
};

export {
  DropdownIndicator,
  MultiValueRemove,
  customStyles,
  formatGroupLabel,
  Option,
};
