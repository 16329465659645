import {COMPANY_INFO_SETTINGS} from '../../store/types';
import api from './api';

function fetchAdminUsers(params) {
  return api.get('/admin/adminusers', {params}).then(res => res.data);
}
function fetchAdminUser(id, params) {
  return api.get(`/admin/adminusers/${id}`, {params}).then(res => res.data);
}
function createAdmin(data) {
  return api.post('/admin/adminusers', {...data}).then(res => res.data);
}
function updateAdmin({id, ...data}) {
  return api
    .put(`/admin/adminusers/${id}`, {...data?.adminData})
    .then(res => res.data);
}
function updateAdminUser(id, params) {
  return api.put(`/admin/adminusers/${id}`, params);
}
function updateCurrencyRate(data, dispatch) {
  return api.put('/admin/settings', {...data}).then(res => {
    if (dispatch) {
      dispatch({
        type: COMPANY_INFO_SETTINGS,
        payload: res.data,
      });
    }
    return res.data;
  });
}
function removeAdmin(id, data) {
  return api.delete(`/admin/adminusers/${id}`, data).then(res => res.data);
}
export {
  fetchAdminUsers,
  fetchAdminUser,
  removeAdmin,
  createAdmin,
  updateAdmin,
  updateCurrencyRate,
  updateAdminUser,
};
