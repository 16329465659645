import React, {useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {DropdownIndicator, customStyles, Modal, PrimaryButton} from '.';
import {updateCompany} from '../helpers/queryFns/company';
import {fetchAdminUsers} from '../helpers/queryFns/admin';
import {SuccessDialog} from './Company/ClientPartnerAssignSuccessDialog';

export default function AssignClientPartnerModal({
  open,
  setOpen,
  selectedCompany,
}) {
  const [clientPartner, setClientPartner] = useState(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const queryClient = useQueryClient();
  const {
    data: adminUsers,
    isLoading,
    isFetching,
  } = useQuery(['admin-users', {page: 1, limit: 1000}], () =>
    fetchAdminUsers({page: 1, limit: 1000}),
  );

  const {mutate: updateCompanyInfo, isLoading: updateLoading} = useMutation(
    updateCompany,
    {
      onSuccess: () => {
        setOpenSuccessDialog(true);
        setTimeout(() => {
          setOpenSuccessDialog(false);
          setOpen(false);
        }, 2500);
        queryClient.invalidateQueries('companyInfo-query');
        queryClient.invalidateQueries('company-data');
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );

  const CLIENT_PARTNERS =
    useMemo(
      () =>
        (!isLoading || !isFetching) &&
        adminUsers &&
        adminUsers?.users?.map(user => ({
          label: `${user?.first_name} ${user?.last_name}` || '-',
          value: user?._id,
        })),
      [adminUsers, isLoading, isFetching],
    ) || [];

  const handleSubmit = e => {
    e.preventDefault();

    if (clientPartner?.value && selectedCompany) {
      const {id, _id} = selectedCompany;
      updateCompanyInfo({
        company_id: id || _id,
        data: {
          client_partner: clientPartner?.value,
        },
      });
    }
  };

  return (
    <Modal
      show={open}
      modalTitle="Assign a client partner"
      onClose={() => setOpen(false)}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <SuccessDialog
        openSuccessDialog={openSuccessDialog}
        setOpenSuccessDialog={setOpenSuccessDialog}
        clientPartner={clientPartner?.label}
        company={selectedCompany?.name}
        action="assigned"
      />
      <form onSubmit={handleSubmit} className="mt-6 text-left">
        <label
          htmlFor="client_partner"
          className="mt-6 mb-2 flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          Select a Client Partner
        </label>
        <Select
          defaultValue={CLIENT_PARTNERS[0]}
          value={clientPartner}
          onChange={setClientPartner}
          options={CLIENT_PARTNERS}
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="bottom"
          className="text-left"
          placeholder="{Name of client partner}"
        />

        <PrimaryButton
          fontSize="text-sm"
          buttonText="Assign"
          className="inline-flex justify-center w-full mt-6 text-white rounded bg-brandBlue"
          loadingState={updateLoading}
          disabled={updateLoading}
        />
      </form>
    </Modal>
  );
}
