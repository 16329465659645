import React from 'react';
import Chart from 'react-apexcharts';

function DashboardChart({data, env}) {
  const chartData = {
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      colors: ['#3977de', '#ECE9F1'],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      grid: {
        borderColor: '#F6F9FF',
      },
      legend: {
        color: '#5022c3',
        position: 'top',
      },
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        marker: {
          show: false,
        },
        theme: false,
        x: {
          show: false,
        },
      },
      xaxis: {
        categories: data?.map(log => (log?._id ? log?._id : 'Unknown')) || [
          'Financial',
          'General',
          'KYC',
          'wallet',
          'messaging',
          'ML',
          'Webhook',
          'Purchase',
        ],
        labels: {
          style: {
            colors: '#A2A3A5',
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#A2A3A5',
          },
        },
      },
    },
    series: [
      {
        data: data?.map(log => (log?.count ? log?.count : 0)) || [
          23, 34, 12, 54, 32, 43, 22, 65,
        ],
        name: env,
      },
    ],
  };

  const {options, series} = chartData;

  return <Chart options={options} series={series} type="line" height={319} />;
}

export default DashboardChart;
