/* eslint-disable no-param-reassign */
import * as Helpers from '../../helpers';
import {
  LOADING,
  BLOGS,
  BLOG_CATEGORIES,
  BLOG_SUMMARY,
  BLOG,
  IMAGE_URL,
} from '../types';

const AppEnv = Object.freeze({
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
});

const url =
  process.env.REACT_APP_APP_ENV_MODE === AppEnv.PRODUCTION
    ? 'https://dojah.io'
    : process.env.REACT_APP_APP_ENV_MODE === AppEnv.DEVELOPMENT
    ? 'https://dev-web.dojah.services'
    : 'https://staging-web.dojah.services';

export const getBlogs = ({page, status, start, end, category}) =>
  Helpers.api(
    `/blog?page=${page || 1}${(status && `&status=${status}`) || ''}${
      (start && `&start=${start}`) || ''
    }${(end && `&end=${end}`) || ''}${
      (category && `&category=${category}`) || ''
    }`,
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: BLOGS},
  );
export const getBlogCategories = () =>
  Helpers.api(
    '/blog/categories',
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: BLOG_CATEGORIES},
  );
export const getBlog = (id, published, navigate) =>
  Helpers.api(
    `/blog/${id}${!published ? `?preview=${id}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: BLOG});
        if (navigate) {
          navigate(`/blog/${id}${!published ? `?preview=${id}` : ''}`);
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: BLOG},
  );
export const deletBlog = id =>
  Helpers.api(
    `/blog/${id}`,
    'DELETE',
    null,
    {
      report: true,
      success: () => {
        window.location.href = '/blog';
      },
    },
    {error: 'ERROR', loading: LOADING, responder: BLOGS},
  );
export const getBlogSummary = () =>
  Helpers.api(
    '/blog/summary',
    'GET',
    null,
    {},
    {error: 'ERROR', loading: LOADING, responder: BLOG_SUMMARY},
  );

export const postBlog = (postData, preview) =>
  Helpers.api(
    '/blog',
    'POST',
    {...postData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: BLOG});
        if (preview) {
          window.open(
            `${url}/api/preview?secret=MY_SECRET_TOKEN&slug=/blog/${data?.post?.slug}`,
            '_blank',
          );
          setTimeout(() => {
            window.location.href = `/blog/${data?.post?._id}${
              !data?.post?.published ? `?preview=${data?.post?._id}` : ''
            }`;
          }, 1000);
        } else {
          window.location.href = `/blog/${data?.post?._id}${
            !data?.post?.published ? `?preview=${data?.post?._id}` : ''
          }`;
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: BLOG_SUMMARY},
  );

export const updateBlog = ({requestType, id, ...postData}) =>
  Helpers.api(
    `/blog/${id}`,
    'PUT',
    {...postData},
    {
      report: true,
      success: (data, dispatch) => {
        if (!requestType) {
          window.location.href = `/blog/${data?.post?._id}`;
          dispatch({payload: data, type: BLOG});
        } else {
          window.location.href = '/blog';
          dispatch(getBlogs());
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: BLOG},
  );
export const imageUpload = ({imageUrl, blogData, preview, type, updateData}) =>
  Helpers.api(
    '/blog/upload',
    'POST',
    {image: imageUrl || updateData?.cover_img},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: IMAGE_URL});
        delete blogData?.cover_img;
        delete updateData?.cover_img;

        const postData = {...blogData, cover_img: data.image};
        if (type === 'post-blog') {
          dispatch(postBlog(postData, preview));
        } else {
          dispatch(updateBlog({...updateData, cover_img: data.image}));
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: IMAGE_URL},
  );
