import React from 'react';

export function ProfileIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_260_6259)">
        <path
          d="M8.99991 12.75C11.7464 12.75 14.1487 13.9312 15.4552 15.6937L14.0737 16.347C13.0102 15.087 11.1352 14.25 8.99991 14.25C6.86466 14.25 4.98966 15.087 3.92616 16.347L2.54541 15.693C3.85191 13.9305 6.25341 12.75 8.99991 12.75ZM8.99991 1.5C9.99447 1.5 10.9483 1.89509 11.6516 2.59835C12.3548 3.30161 12.7499 4.25544 12.7499 5.25V7.5C12.7499 8.46635 12.3768 9.39537 11.7084 10.0933C11.0401 10.7913 10.1281 11.2043 9.16266 11.2463L8.99991 11.25C8.00535 11.25 7.05152 10.8549 6.34826 10.1517C5.645 9.44839 5.24991 8.49456 5.24991 7.5V5.25C5.24997 4.28365 5.62307 3.35463 6.2914 2.65667C6.95974 1.95871 7.87172 1.54569 8.83716 1.50375L8.99991 1.5ZM8.99991 3C8.426 2.99997 7.87377 3.21924 7.45621 3.61296C7.03865 4.00668 6.78733 4.54508 6.75366 5.118L6.74991 5.25V7.5C6.74935 8.08568 6.97718 8.6485 7.38499 9.06886C7.7928 9.48923 8.34846 9.73401 8.93388 9.7512C9.51931 9.76839 10.0884 9.55662 10.5201 9.1609C10.9519 8.76519 11.2124 8.2167 11.2462 7.632L11.2499 7.5V5.25C11.2499 4.65326 11.0129 4.08097 10.5909 3.65901C10.1689 3.23705 9.59665 3 8.99991 3Z"
          fill="#8E8E91"
        />
      </g>
      <defs>
        <clipPath id="clip0_260_6259">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
