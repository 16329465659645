import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {Menu} from '@headlessui/react';
import {connect} from 'react-redux';
import moment from 'moment';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import {
  activityBlue,
  activityGreen,
  activityRed,
  edit,
  pin,
  pinnedIc,
  publish,
  unpin,
  unpublish,
  userCheck,
} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import TableLayout from '../../components/TableLayout';
import Pagination from '../../components/Pagination';
import Dropdown from '../../components/Dropdown';
import LayoutContainer from '../../components/LayoutContainer';
import * as Actions from '../../store/actions';
import {SearchInput} from '../../components/SearchInput';
import Card from '../../components/Card';
import FilterButton from '../../components/FilterButton';
import BlogFilter from './BlogFilter';
import Loader from '../../components/Loader';
import api from '../../helpers/queryFns/api';
import {fetchPosts} from '../../helpers/queryFns/blog';
import {handleFileInput} from '../../helpers';

function getPinnedPost() {
  return api.get('/blog/pinned').then(res => res.data);
}

function truncate(string, n) {
  return string?.length > n ? `${string.substring(0, n - 1)}...` : string;
}

function Blog({
  getBlog,
  updateBlog,
  getBlogCategories,
  getBlogSummary,
  blog: {loading, blog_summary},
}) {
  const [filters, setFilters] = useState({
    start: '',
    end: '',
    category: '',
    status: '',
  });
  const [openFilter, setOpenFilter] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const pageParam = query.get('page');
  const page = Number(pageParam) >= 1 ? Number(pageParam) : 1;
  const filter = query.get('q');
  const [searchText, setSearchText] = useState(filter);
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [isGeneratingImgUrl, setIsGeneratingImgUrl] = useState(false);
  const [isCopiedUrl, setIsCopiedUrl] = useState(false);

  const copyUrl = () => {
    navigator.clipboard.writeText(imageUrl);
    setIsCopiedUrl(true);

    setTimeout(() => {
      setIsCopiedUrl(false);
    }, 3000);
  };

  const uploadImage = async base64String => {
    setIsGeneratingImgUrl(true);
    try {
      const response = await api.post('/blog/upload', {image: base64String});

      const res = response.data.image;
      setImageUrl(res);
      setIsGeneratingImgUrl(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsGeneratingImgUrl(false);
      return null;
    }
  };

  const handleChangeCurrentPage = newPage => {
    const queryParams = {
      page: newPage,
      filter: searchText || '',
      ...(filters.start && {start: filters.start}),
      ...(filters.end && {end: filters.end}),
      ...(filters.category && {category: filters.category}),
      ...(filters.status && {status: filters.status}),
    };

    const newUrl = `?${Object.entries(queryParams)
      .filter(([, value]) => value !== '')
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')}`;

    navigate(newUrl);
  };

  useEffect(() => {
    getBlogCategories();
    getBlogSummary();
  }, [getBlogCategories, getBlogSummary]);

  const {data: blogs, refetch: refetchPosts} = useQuery(
    ['posts', {page: page === 0 ? 1 : page, title: searchText}],
    () =>
      fetchPosts({
        page: page === 0 ? 1 : page,
        title: searchText,
        ...(filters.start && {start: filters.start}),
        ...(filters.end && {end: filters.end}),
        ...(filters.category && {category: filters.category}),
        ...(filters.status && {status: filters.status}),
      }),
  );

  const handleFilter = () => {
    navigate(
      `?page=1${filters.start ? `&start=${filters.start}` : ''}${
        filters.end ? `&end=${filters.end}` : ''
      }${filters.category ? `&category=${filters.category}` : ''}${
        filters.status ? `&status=${filters.status}` : ''
      }`,
    );

    refetchPosts();
    setOpenFilter(false);
  };

  const isFilterApplied = useCallback(
    () =>
      filters.start ||
      filters.end ||
      filters.category ||
      filters.status ||
      searchText,
    [filters.category, filters.end, filters.start, filters.status, searchText],
  );

  const handleResetFilter = () => {
    if (isFilterApplied()) {
      setFilters({
        start: '',
        end: '',
        category: '',
        status: '',
      });
      setSearchText('');
      navigate('?page=1');
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const {data: pinnedPost, isLoading: isLoadingPinnedPost} = useQuery(
    'pinnedPosts',
    getPinnedPost,
  );

  const allPosts = blogs?.posts;

  const posts = !isLoadingPinnedPost
    ? allPosts?.filter(post => post._id !== pinnedPost?.post?._id)
    : allPosts || [];

  const sortedPosts =
    (!isLoadingPinnedPost &&
      pinnedPost?.post &&
      posts && [pinnedPost?.post, ...posts]) ||
    posts;

  return (
    <DashboardLayout headerText="Blog">
      <LayoutContainer>
        <div className="flex items-center gap-2 overflow-x-auto">
          <Card
            label="Total articles"
            value={blog_summary?.total?.toLocaleString() || '0'}
            src={userCheck}
          />
          <Card
            label="Published articles"
            value={blog_summary?.published?.toLocaleString() || '0'}
            src={activityGreen}
          />
          <Card
            label="Unpublished articles"
            value={blog_summary?.unpublished?.toLocaleString() || '0'}
            src={activityRed}
          />
          <Card
            label="Draft articles"
            value={blog_summary?.draft?.toLocaleString() || '0'}
            src={activityBlue}
          />
        </div>

        <div className="mt-10">
          <p className="font-medium">Image Upload</p>
          <div className="flex items-center gap-4">
            <label className="mt-2">
              <input
                accept="image/png, image/jpeg"
                type="file"
                onChange={e => {
                  handleFileInput(e.target.files[0], data => {
                    uploadImage(data.file);
                    setImageFile(data);
                  });
                }}
              />
              <div className="p-4 cursor-pointer bg-white-900 text-center border border-brandBlue border-dashed rounded w-full">
                <p className="text-grey60 text-sm font-medium">
                  {imageFile?.filename ?? 'Upload image'}
                </p>
              </div>
            </label>
            {isGeneratingImgUrl ? (
              <svg
                className="animate-spin h-5 w-5 text-brandBlue"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : null}
            <div className="flex items-center">
              <p className="text-sm text-grey60">{imageUrl}</p>
              {imageUrl ? (
                <button
                  className="ml-4 px-2 py-2 rounded bg-white-900 text-grey text-xs font-medium outline outline-1 outline-grey"
                  onClick={copyUrl}
                >
                  {isCopiedUrl ? 'Copied' : 'Copy url'}
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="mt-10">
          <p className="text-grey60 font-medium mb-4">Blog</p>
          <div className="flex items-center">
            <SearchInput
              placeholder="Search list"
              onChange={text => {
                setSearchText(text);
                navigate(`?q=${text}`);
              }}
              defaultValue={searchText}
            />
            <div className="ml-4">
              <FilterButton
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
              />
            </div>

            <BlogFilter
              handleFilter={handleFilter}
              onCheck={data => setFilters({...filters, ...data})}
              resetFilters={handleResetFilter}
              appFilters={filters}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
            />

            <Link
              to="/blog/add"
              className="font-medium ml-auto px-11 bg-brandBlue text-white py-[14px] rounded hover:text-white"
            >
              New article
            </Link>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="mt-5">
            <TableLayout>
              <thead className="text-xs font-semibold uppercase">
                <tr className="border-b bg-white-900 border-tableGrey">
                  <th className="p-3 font-medium text-left text-small text-grey whitespace-nowrap">
                    post title
                  </th>
                  <th className="p-3 font-medium text-left text-small text-grey whitespace-nowrap">
                    date created
                  </th>
                  <th className="p-3 font-medium text-left text-small text-grey whitespace-nowrap">
                    category
                  </th>
                  <th className="p-3 font-medium text-left text-small text-grey whitespace-nowrap">
                    status
                  </th>
                  <th className="p-3 font-medium text-small text-grey whitespace-nowrap">
                    action
                  </th>
                </tr>
              </thead>
              <tbody
                className="text-sm text-grey80"
                style={{opacity: loading && 0.3}}
              >
                {!loading &&
                !isLoadingPinnedPost &&
                blogs &&
                sortedPosts?.length > 0
                  ? sortedPosts?.map((blog, i) => (
                      // eslint-disable-next-line react/jsx-indent
                      <tr className="border-b border-grey60" key={i}>
                        <td className="p-3 text-left text-sm whitespace-nowrap">
                          <div className="flex gap-1">
                            {truncate(blog?.title, 30) || '-'}
                          </div>
                        </td>
                        <td className="p-3 text-sm text-left whitespace-nowrap">
                          {moment(blog?.createdAt).format('Do MMM, YYYY HH:mm')}
                        </td>
                        <td className="p-3 text-sm text-left whitespace-nowrap">
                          {(blog &&
                            blog?.categories
                              .map(category => category.name)
                              .join(', ')) ||
                            '-'}
                        </td>
                        <td className="p-3 text-sm text-left whitespace-nowrap">
                          <div
                            className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full text-white ${
                              blog?.published && blog?.hasBeenPublished
                                ? 'bg-accent-500'
                                : !blog?.published && blog?.hasBeenPublished
                                ? 'bg-darkRed'
                                : 'bg-graphGrey'
                            } w-fit`}
                          >
                            {blog?.published && blog?.hasBeenPublished
                              ? 'published'
                              : !blog?.published && blog?.hasBeenPublished
                              ? 'Unpulished'
                              : 'Draft'}
                          </div>
                        </td>
                        <td className="p-3 text-sm text-center whitespace-nowrap relative">
                          <Dropdown>
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  href="#"
                                  onClick={() => {
                                    getBlog(
                                      blog?._id,
                                      blog?.published,
                                      navigate,
                                    );
                                  }}
                                  className={classNames(
                                    active ? 'text-grey60' : 'text-grey60',
                                    'flex items-center gap-2 text-tiny font-medium',
                                  )}
                                >
                                  <img src={edit} alt="" />
                                  Edit post
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {() => (
                                <button
                                  href="#"
                                  onClick={() => {
                                    const blogData = {
                                      title: blog?.title,
                                      body: blog?.body,
                                      categories: [...blog?.categories]?.map(
                                        item => item?._id,
                                      ),
                                      feature_img: blog?.feature_img,
                                      tags: blog?.tags,
                                      published: !blog?.published,
                                    };
                                    updateBlog({
                                      requestType: 'publishing',
                                      id: blog?._id,
                                      ...blogData,
                                    });
                                  }}
                                  className={classNames(
                                    blog?.published
                                      ? 'text-danger'
                                      : 'text-success',
                                    'flex items-center gap-2 text-tiny font-medium',
                                  )}
                                >
                                  <img
                                    src={blog?.published ? unpublish : publish}
                                    alt=""
                                    style={{height: 16}}
                                  />
                                  {blog?.published
                                    ? 'Unpublish post'
                                    : 'Publish post'}
                                </button>
                              )}
                            </Menu.Item>
                            {blog?.published && (
                              <Menu.Item>
                                {() => (
                                  <button
                                    href="#"
                                    onClick={() => {
                                      const blogData = {
                                        title: blog?.title,
                                        body: blog?.body,
                                        categories: [...blog?.categories]?.map(
                                          item => item?._id,
                                        ),
                                        feature_img: blog?.feature_img,
                                        tags: blog?.tags,
                                        published: blog?.published,
                                        pinned: !blog?.pinned,
                                      };
                                      updateBlog({
                                        requestType: 'publishing',
                                        id: blog?._id,
                                        ...blogData,
                                      });
                                    }}
                                    className={classNames(
                                      blog?.pinned
                                        ? 'text-danger'
                                        : 'text-success',
                                      'flex items-center gap-2 text-tiny font-medium',
                                    )}
                                  >
                                    <img
                                      src={blog?.pinned ? unpin : pin}
                                      alt=""
                                      style={{height: 16}}
                                    />
                                    {blog?.pinned ? 'Unpin post' : 'Pin post'}
                                  </button>
                                )}
                              </Menu.Item>
                            )}

                            {/* {!blog?.published && (
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  href="#"
                                  className={classNames(
                                    active ? 'text-danger' : 'text-danger',
                                    'flex items-center gap-2 text-tiny font-medium',
                                  )}
                                  onClick={() => deletBlog(blog?._id)}
                                >
                                  <img src={trash} alt="" />
                                  Delete post
                                </button>
                              )}
                            </Menu.Item>
                          )} */}
                          </Dropdown>
                          {blog?.pinned ? (
                            <img
                              className="absolute top-3 right-0"
                              src={pinnedIc}
                              width={27}
                              height={27}
                              alt=""
                              title="Pinned"
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </TableLayout>

            <div className="flex flex-wrap items-baseline justify-between gap-4 mb-14">
              <Pagination
                total={(blogs && blogs?.totalPages) || 0}
                current={page}
                onPageChange={handleChangeCurrentPage}
              />
              {/* <p className="flex items-center gap-2 text-tiny text-grey60">
                Showing{' '}
                <span className="flex p-2 text-white rounded bg-brandBlue">
                  100
                  <img src={dropdownWhite} alt="" />
                </span>
                out of 100
              </p> */}
            </div>
          </div>
        )}
      </LayoutContainer>
    </DashboardLayout>
  );
}
export default connect(state => state, Actions)(Blog);
