import {
  LOADING,
  DASHBOARD_DATA,
  ALL_USERS,
  USER,
  API_LOGS,
  SENDER_IDS,
} from '../types';

const initialState = {
  api_logs: null,
  dashboard: null,
  loading: null,
  sender_ids: null,
  user: null,
  users: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};
    case DASHBOARD_DATA:
      return {...state, dashboard: action.payload};
    case ALL_USERS:
      return {...state, users: action.payload};
    case USER:
      return {...state, user: action.payload};
    case API_LOGS:
      return {...state, api_logs: action.payload};
    case SENDER_IDS:
      return {...state, sender_ids: action.payload};
    default:
      return state;
  }
}
