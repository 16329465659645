import React from 'react';
import {Switch} from '@headlessui/react';

export default function Toggle({enabled = false, setEnabled}) {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={`${
        enabled ? 'bg-success' : 'bg-danger'
      } relative inline-flex items-center h-6 rounded-full w-10`}
    >
      <span className="sr-only">Toggle services on/off</span>
      <span
        className={`${
          enabled ? 'translate-x-6' : 'translate-x-1'
        } inline-block w-[0.65rem] h-[0.65rem] bg-lightBlue rounded-full transform ring-0 transition ease-in-out duration-200`}
      />
    </Switch>
  );
}
