import React, {useState} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

function DashboardLayout({
  children,
  noMarginTop = false,
  headerText = '',
  className = '',
  xlLeftMargin = '',
  leftMargin = '',
  bg = '',
}) {
  const [openNav, setOpenNav] = useState(false);

  const x = window.matchMedia('(min-width: 768px)');

  return (
    <div className="relative flex min-h-screen dashboard-layout">
      <nav
        className={`${
          openNav ? 'absolute ease-in-out transition-all duration-300' : ''
        } w-60 flex-shrink-0 hidden lg:block `}
      >
        {x && <Sidebar />}
      </nav>

      <div className="flex flex-col w-full">
        <Header
          setOpenNav={() => setOpenNav(!openNav)}
          headerText={headerText}
        />
        <div
          className={`${className} ${bg} ${leftMargin} ${xlLeftMargin} ${
            noMarginTop ? 'mt-0' : 'mt-12'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
