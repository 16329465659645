import api from './api';
import {COMPANY_INFO, COMPANY_INFO_SETTINGS} from '../../store/types';

// GET
function getCompanyApps(id, params) {
  return api.get(`/admin/company/apps/${id}`, {params}).then(res => res.data);
}

function getCompanyWidgets(id, params) {
  return api
    .get(`/admin/company/widgets/${id}`, {params})
    .then(res => res.data);
}

function getCompanyInfo(id, dispatch) {
  return api.get(`/admin/company/${id}`).then(res => {
    if (dispatch) {
      dispatch({
        payload: res.data,
        type: COMPANY_INFO,
      });
    }
    return res.data;
  });
}
function getCompanySettings(dispatch) {
  return api.get('/admin/settings/').then(res => {
    if (dispatch) {
      dispatch({
        payload: res.data,
        type: COMPANY_INFO_SETTINGS,
      });
    }
    return res.data;
  });
}

function getIndividualCompanyLookup(id, params) {
  return api
    .get(`/admin/company/lookup/individual/${id}`, {params})
    .then(res => res.data);
}

function getBatchCompanyLookup(id, params) {
  return api
    .get(`/admin/company/lookup/batch/${id}`, {params})
    .then(res => res.data);
}

function getCompanyLogs(id, params) {
  return api
    .get(`/admin/logs/v2?company_id=${id}`, {params})
    .then(res => res.data);
}

function getCompanyBillings(id, params) {
  return api
    .get(`/admin/billings?company=${id}`, {params})
    .then(res => res.data);
}

function getCompanyUsers(id, params) {
  return api.get(`/admin/users?company=${id}`, {params}).then(res => res.data);
}

function getCompanyAuditTrail(id, params) {
  return api
    .get(`/admin/audit-trail?company_id=${id}`, {params})
    .then(res => res.data);
}

function getCompanyServices(id) {
  return api.get(`/admin/company/pricing/${id}`).then(res => res.data);
}

function getCompanyTeamMembers(id, params) {
  return api
    .get(`/admin/company/team?company=${id}`, {params})
    .then(res => res.data);
}

function getCompanyTeamRoles(id, params) {
  return api
    .get(`/admin/company/permissions?company=${id}`, {params})
    .then(res => res.data);
}

// PUT
function updateKycServices(id, params) {
  return api.put(`/admin/company/services/${id}`, params);
}

function updateNegativeBilling(id, params) {
  return api.put(`/admin/negative_billing/${id}`, params);
}

function updateCompanyPricing(id, params) {
  return api.put(`/admin/company/pricing/${id}`, params);
}

function updateCompanyInfo(id, params) {
  return api.put(`/admin/company/${id}`, params);
}
function updateCompanyConfig(id, params) {
  return api.put(`/admin/company/configuration/${id}`, params);
}

function updateTeamMemberRole(params) {
  return api.put('/admin/company/team', params);
}

function assignAppsToUsers(params) {
  return api.put('/admin/company/assign-apps', params);
}

// POST
function topUpCompanyWallet(id, params) {
  return api.post(`/admin/payment/topup/${id}`, params);
}

function inviteMemberToCompany(params) {
  return api.post('/admin/company/invite-member', params);
}

// DELETE
function deactivateTeamMember(id, params) {
  return api.delete(`/admin/company/team/${id}`, params);
}

export {
  getCompanyApps,
  getCompanyWidgets,
  getCompanyInfo,
  getIndividualCompanyLookup,
  getBatchCompanyLookup,
  getCompanyLogs,
  getCompanyBillings,
  getCompanyUsers,
  getCompanyAuditTrail,
  getCompanyServices,
  updateKycServices,
  updateNegativeBilling,
  topUpCompanyWallet,
  updateCompanyPricing,
  updateCompanyInfo,
  getCompanySettings,
  getCompanyTeamMembers,
  inviteMemberToCompany,
  getCompanyTeamRoles,
  updateTeamMemberRole,
  assignAppsToUsers,
  deactivateTeamMember,
  updateCompanyConfig,
};
