import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import CompanyLayout from './CompanyLayout';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import {getCompanyInfo} from '../../helpers/queryFns/fetchCompanyDetails';
import Loader from '../../components/Loader';
import GoToTop from '../../components/GoToTop';
import AssignClientPartnerModal from '../../components/AssignClientPartnerModal';
import RevokeClientPartnerModal from '../../components/RevokeClientPartnerModal';
import {SecondaryButton} from '../../components';
import {SuccessDialog} from '../../components/Company/ClientPartnerAssignSuccessDialog';

export default function CompanyInformation() {
  const {id} = useParams();
  const [clientName, setClientName] = useState('');
  const [assignClientPartner, setAssignClientPartner] = useState(false);
  const [revokeClientPartner, setRevokeClientPartner] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const companyInfoQuery = useQuery(['companyInfo-query', id], () =>
    getCompanyInfo(id),
  );

  const CLIENT_PARTNER =
    companyInfoQuery && companyInfoQuery?.data?.company?.client_partner;
  const action = !CLIENT_PARTNER ? 'unassigned' : 'assigned';

  return (
    <DashboardLayout className="mt-4" headerText="Company/Company Information">
      <AssignClientPartnerModal
        open={assignClientPartner}
        setOpen={setAssignClientPartner}
        selectedCompany={companyInfoQuery?.data?.company}
      />
      <RevokeClientPartnerModal
        open={revokeClientPartner}
        setOpenSuccessDialog={setOpenSuccessDialog}
        setOpen={() => {
          setRevokeClientPartner(!revokeClientPartner);
          setClientName(
            CLIENT_PARTNER &&
              `${CLIENT_PARTNER?.first_name} ${CLIENT_PARTNER?.last_name}`,
          );
        }}
        companyId={id}
      />

      <SuccessDialog
        openSuccessDialog={openSuccessDialog}
        setOpenSuccessDialog={setOpenSuccessDialog}
        clientPartner={clientName}
        company={companyInfoQuery?.data?.company?.name}
        action={action}
      />
      <LayoutContainer>
        <CompanyLayout>
          <div className="sm:my-8 flex items-start gap-22 justify-center">
            <div className="max-w-sm w-full">
              {companyInfoQuery?.isLoading ? (
                <Loader height="40" />
              ) : (
                <>
                  <h5 className="mb-6 text-grey80 text-lg text-start font-medium">
                    Company Information
                  </h5>
                  <form className="max-w-sm">
                    <label
                      htmlFor="company_name"
                      className="flex flex-col items-start max-w-full mb-6 text-sm label text-grey60"
                    >
                      Company name
                      <input
                        id="company_name"
                        className="max-w-full mt-1 input"
                        readOnly
                        value={companyInfoQuery?.data?.company?.name ?? '-'}
                      />
                    </label>
                    <label
                      htmlFor="address"
                      className="flex flex-col items-start max-w-full mb-6 text-sm label text-grey60"
                    >
                      Address
                      <input
                        id="address"
                        className="max-w-full mt-1 input"
                        readOnly
                        value={companyInfoQuery?.data?.company?.address ?? '-'}
                      />
                    </label>
                    <label
                      htmlFor="rc_number"
                      className="flex flex-col items-start max-w-full mb-6 text-sm label text-grey60"
                    >
                      Registration number
                      <input
                        id="rc_number"
                        type="number"
                        className="max-w-full mt-1 input"
                        readOnly
                        value={companyInfoQuery?.data?.company?.rc_number ?? ''}
                      />
                    </label>
                  </form>
                </>
              )}
            </div>
            {!companyInfoQuery?.isLoading && (
              <div className="max-w-[365px] w-full p-8 flex flex-col items-center text-center gap-7 bg-lighterBlue rounded-2xl">
                <p className="text-sm font-medium text-grey">
                  Assigned client partner
                </p>
                <div className="space-y-4">
                  {!CLIENT_PARTNER ? (
                    <>
                      <p className="text-xl font-semibold text-grey">
                        No Client Partners
                      </p>
                      <p className="text-grey60 max-w-[215px] mx-auto">
                        You haven’t added any client partner.
                      </p>
                      <SecondaryButton
                        buttonText="Add a client partner"
                        onClick={() => setAssignClientPartner(true)}
                      />
                    </>
                  ) : (
                    <>
                      <p className="text-xl font-semibold text-grey">
                        {(CLIENT_PARTNER &&
                          `${CLIENT_PARTNER?.first_name} ${CLIENT_PARTNER?.last_name}`) ||
                          'Name of client partner'}
                      </p>
                      <button
                        onClick={() => setRevokeClientPartner(true)}
                        className="text-danger text-tiny rounded px-6 py-[14px] font-medium"
                      >
                        Revoke
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
