import React from 'react';
import Radio from './Radio';

export default function Radios({data, onCheck, children, className = ''}) {
  return (
    <div
      className={`flex flex-col px-6 bg-white-900 -mx-6 filter ${className}`}
    >
      <div className="bg-white relative flex flex-col gap-4 py-4 animate-dropdown">
        {data?.map(({value, label, name}) => (
          <Radio
            key={value}
            handleChecked={onCheck}
            label={label}
            value={value}
            name={name}
          />
        ))}
        {children}
      </div>
    </div>
  );
}
