import api from './api';

function fetchCategories() {
  return api.get('/blog/categories').then(res => res.data);
}

function fetchPosts(params) {
  return api.get('/blog', {params}).then(res => res.data);
}

export {fetchCategories, fetchPosts};
