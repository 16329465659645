import React, {useCallback, useMemo, useState} from 'react';
import {FilterWrapper, Checkboxes, FilterAccordion} from '../../../components';

const status = [
  {name: 'active', label: 'Active', value: 'active'},
  {name: 'expired', label: 'Expired', value: 'expired'},
  {name: 'pending', label: 'Deactivated', value: 'pending'},
];

export default function TeamFilter({
  openFilter,
  setOpenFilter,
  resetFilters = () => {},
  handleFilter,
  onCheck,
  appFilters,
}) {
  const STATUS_DATA = useMemo(
    () =>
      status?.map(stat => ({
        label: stat.label,
        value: stat.value,
        name: stat.name,
      })),
    [],
  );

  const [activeIndex, setActiveIndex] = useState(null);
  const [count, setCount] = useState({});

  const logsSearchInputs = useMemo(
    () => [
      {
        data: STATUS_DATA,
        filterName: 'status',
        onCheck: data => {
          onCheck(data);
        },
      },
    ],
    [STATUS_DATA, onCheck],
  );

  const handleClearFilters = useCallback(() => {
    setActiveIndex(null);
    resetFilters();
    setCount({});
  }, [resetFilters]);

  return (
    <FilterWrapper
      resetFilters={handleClearFilters}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilter={handleFilter}
      clearFilters
      appFilters={appFilters}
    >
      {logsSearchInputs.map(({data, filterName}, index) => {
        const isActive = activeIndex === index;

        return (
          <FilterAccordion
            activeIndex={isActive}
            key={filterName}
            filterName={filterName}
            selectedCount={count[filterName]}
            onClick={() => setActiveIndex(index)}
          >
            <Checkboxes
              data={data}
              values={data?.map(d => d.value) || []}
              onCheck={newData => {
                const filterValues = Object.values(newData)[0]?.split(',');
                onCheck(newData);
                setCount({
                  ...count,
                  [filterName]: filterValues,
                });
              }}
              filterName={filterName}
            />
          </FilterAccordion>
        );
      })}
    </FilterWrapper>
  );
}
