import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';

export default function PrimaryButton({
  buttonText,
  xPadding = '',
  yPadding = 'py-[14px]',
  fontSize = 'text-tiny',
  className = '',
  onClick,
  loadingState,
  bgColor = 'bg-brandBlue',
  bgHover = 'bg-btnHover',
  ...props
}) {
  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;
  return (
    <button
      onClick={onClick}
      className={`${className} ${
        loadingState
          ? 'flex items-center justify-center relative py-6 max-w-full'
          : ''
      } ${bgColor} text-white ${fontSize} hover:${bgHover} duration-200 transition disabled:opacity-25 rounded ${xPadding} ${yPadding}`}
      {...props}
    >
      {loadingState ? (
        <PuffLoader css={override} size={35} color="#ffffff" />
      ) : null}
      {buttonText}
    </button>
  );
}
