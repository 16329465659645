import {toast} from 'react-toastify';
import api from './api';

function fetchApiLogsSummary(data) {
  return api
    .get('/admin/logs/summary', {
      headers: {
        Environment: data?.env,
      },
    })
    .then(res => res.data);
}

function fetchApiLogs(params) {
  return api.get('/admin/logs/v2', {params}).then(res => res.data);
}

async function exportLogs() {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const response = await api.get('/admin/logs/export').then(res => res.data);
  toast.success(response?.message);
  return response;
}

export {fetchApiLogsSummary, fetchApiLogs, exportLogs};
