import React from 'react';
import {Modal, SecondaryButton, TertiaryButton} from '../../../components';

export default function DeactivateMemberModal({
  removeTeamMember,
  selectedMember,
  removingTeamMember,
  deactivateModal,
  setDeactivateModal,
}) {
  const handleDelete = e => {
    e.preventDefault();
    removeTeamMember(selectedMember?.id);
  };

  return (
    <Modal
      show={deactivateModal}
      modalTitle="Are you sure?"
      onClose={setDeactivateModal}
      extraModalClasses="max-w-[512px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <form onSubmit={handleDelete}>
        <div className="mt-4">
          <p className="text-start text-sm text-body font-medium">
            By deactivating this user, you are removing the user from accessing
            the Dojah application
          </p>
        </div>

        <div className="mt-4 flex items-center gap-4">
          <SecondaryButton
            onClick={() => setDeactivateModal(false)}
            buttonText="Cancel"
          />
          <TertiaryButton
            onClick={handleDelete}
            buttonText="Deactivate user"
            className="grow"
            loading={removingTeamMember}
          />
        </div>
      </form>
    </Modal>
  );
}
