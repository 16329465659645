import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function AddressServices({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const {id} = useParams();

  const [physicalAddress, setPhysicalAddress] = useState('');
  const [digitalAddress, setDigitalAddress] = useState('');

  const addressServicesQuery = useQuery(['ghana-kyc-services-query', id], () =>
    getCompanyServices(id),
  );
  const url = addressServicesQuery?.data?.pricing;

  useEffect(() => {
    const getAddressServicesQuery = () => {
      setPhysicalAddress((url?.physical_address / 100).toFixed(2));
      setDigitalAddress((url?.digital_address / 100).toFixed(2));
    };

    getAddressServicesQuery();
  }, [url?.digital_address, url?.physical_address]);

  return (
    <>
      {addressServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">Address services</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="physical_address"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Physical Address
                    <input
                      id="physical_address"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={physicalAddress}
                      onChange={e =>
                        onUpdate({
                          physical_address: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="digital_address"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Digital Address
                    <input
                      id="digital_address"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={digitalAddress}
                      onChange={e =>
                        onUpdate({
                          digital_address: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
