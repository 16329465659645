import {toast} from 'react-toastify';
import api from './api';

function fetchBillings(data) {
  return api.get('/admin/billings', {params: data}).then(res => res.data);
}
function fetchBillingsSummary() {
  return api.get('/admin/billings/summary').then(res => res.data);
}
async function exportBillings() {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const response = await api
    .get('/admin/billings/export')
    .then(res => res.data);
  toast.success(response?.message);
  return response;
}

export {fetchBillings, fetchBillingsSummary, exportBillings};
