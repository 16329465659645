import React, {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';

import {customStyles, DropdownIndicator} from '../../../components';

const walletOptions = [
  {label: 'Wallet', value: 'wallet'},
  {label: 'Collection', value: 'collection'},
];

export function SettlementConfig({settings = {}, setSettings}) {
  const [selectedWalletOption, setSelectedWalletOption] = useState();
  const currentWalletOption = useMemo(
    () =>
      walletOptions?.find(wallet => wallet?.value === settings?.wallet_option),
    [settings?.wallet_option],
  );

  useEffect(() => {
    setSelectedWalletOption(currentWalletOption || walletOptions[0]);
  }, [currentWalletOption]);

  return (
    <div title="Settlement config" className="bg-white-900 p-4 rounded">
      <p className="mb-8">Settlement config</p>

      <div>
        <section className="mt-3 grid grid-cols-1  md:grid-cols-1 gap-x-4 lg:gap-x-6 w-wull">
          <div>
            <label className="flex items-start text-left text-sm label text-grey60 mb-1">
              Wallet Mode
            </label>
            <Select
              options={walletOptions}
              value={selectedWalletOption || currentWalletOption}
              // defaultValue={selectedWalletOption || currentWalletOption}
              onChange={type => {
                setSettings({...settings, wallet_option: type?.value});
                setSelectedWalletOption(type);
              }}
              components={{DropdownIndicator}}
              classNamePrefix="react-select"
              styles={customStyles}
              menuPlacement="top"
              className="text-left bg-white"
              placeholder="Select wallet option"
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="company_tagline"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Daily limit
              <input
                value={settings?.daily_limit ?? ''}
                onChange={e =>
                  setSettings({...settings, daily_limit: e.target.value})
                }
                id="company_tagline"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
              />
            </label>
          </div>
          <div className="mt-4">
            <label
              htmlFor="company_tagline"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Monthly limit
              <input
                value={settings?.monthly_limit ?? ''}
                onChange={e =>
                  setSettings({...settings, monthly_limit: e.target.value})
                }
                id="company_tagline"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
              />
            </label>
          </div>
        </section>
      </div>
    </div>
  );
}
