import React, {useState} from 'react';
import AppPagination from 'react-responsive-pagination';
import '../app.scss';

export default function Pagination({onPageChange, total, current}) {
  const [page, setPage] = useState(1);
  return (
    <AppPagination
      total={total || 0}
      current={current || page}
      onPageChange={onPageChange || (newPage => setPage(newPage))}
      maxWidth={50}
    />
  );
}
