import React from 'react';

export default function SecondaryButton({buttonText, className, onClick}) {
  return (
    <button
      className={`bg-lightBlue text-brandBlue text-tiny rounded px-6 py-[14px] ${className}`}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}
