import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import Loader from '../../components/Loader';
import {getCompanyApps} from '../../helpers/queryFns/fetchCompanyDetails';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';
import {EmptyState} from '../../components/EmptyState';

export default function CompanyApps() {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const appQuery = useQuery(['app-query', id], () =>
    getCompanyApps(id, {page: currentPage}),
  );

  return (
    <DashboardLayout className="mt-4" headerText="Company/Apps">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5">
            {appQuery.isLoading ? (
              <Loader />
            ) : (
              <>
                {!appQuery?.data?.totalRecords ? (
                  <EmptyState
                    height={45}
                    title="No app listed"
                    body="No app is listed yet"
                  />
                ) : (
                  <TableLayout>
                    <thead className="text-xs font-semibold uppercase">
                      <tr className="border-b border-tableGrey">
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          app name
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          app id
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          date created
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {appQuery.data?.apps.map(app => (
                        <tr
                          className="border-b border-tableGrey"
                          key={app?._id}
                        >
                          <td className="p-3  text-sm text-grey80 whitespace-nowrap">
                            {app.name ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {app._id ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {moment(app.createdAt).format('Do MMM, YYYY') ??
                              '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableLayout>
                )}
              </>
            )}
          </div>

          <div className="mt-4 mb-8">
            <Pagination
              total={appQuery?.data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
            {appQuery.isFetching ? (
              <p className="border-none mt-4">Updating...</p>
            ) : null}
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
