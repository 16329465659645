import {
  NAV_ITEMS_BASE_ANALYTICS,
  NAV_RECONCILO,
  NAV_ITEMS_OTHERS,
  NAV_ITEMS_SETTINGS,
  NAV_ITEMS_BLOG,
  COMPANY_TABS,
} from './nav';

const dates = [
  {value: 'today', label: 'Today', name: 'date'},
  {value: 'yesterday', label: 'Yesterday', name: 'date'},
  {value: 'seven_days', label: 'Last 7 days', name: 'date'},
  {value: 'fifteen_days', label: 'Last 15 days', name: 'date'},
  {value: 'thirty_days', label: 'Last 30 days', name: 'date'},
];

const JOB_TITLE = [
  {job_title: 'Product Marketing Manager', value: 'product-marketing-manager'},
  {job_title: 'Technical Writer', value: 'technical-writer'},
  {job_title: 'Product Manager', value: 'product-manager'},
  {job_title: 'Product Analyst', value: 'product-analyst'},
  {job_title: 'Brand Designer', value: 'brand-designer'},
  {job_title: 'Compliance and HR Manager', value: 'compliance-and-hr-manager'},
  {job_title: 'Technical Project Manager', value: 'technical-project-manager'},
  {job_title: 'Sales Analyst', value: 'sales-analyst'},
  {job_title: 'VP, Partnership and Sales', value: 'vp-partnership-and-sales'},
  {job_title: 'Chief of Staff', value: 'chief-of-staff'},
  {job_title: 'Retention Analyst', value: 'retention-analyst'},
  {job_title: 'Frontend Engineer', value: 'frontend-engineer'},
  {job_title: 'Backend Engineer', value: 'backend-engineer'},
  {job_title: 'Full stack Engineer', value: 'full-stack-engineer'},
  {job_title: 'Devops', value: 'devops'},
  {job_title: 'Data Scientist', value: 'data-scientist'},
  {job_title: 'Finance Analyst', value: 'finance-analyst'},
  {job_title: 'Product Designer', value: 'product-designer'},
  {job_title: 'Co-Founder, CEO', value: 'co-founder-ceo'},
  {
    job_title: 'Co-founder, Head of Product and Growth',
    value: 'co-founder-head-of-product-and-growth',
  },
];

const DEPARTMENTS = [
  {dep: 'Marketing', value: 'marketing'},
  {dep: 'Sales', value: 'sales'},
  {dep: 'Retention', value: 'retention'},
  {dep: 'Product', value: 'product'},
  {dep: 'Engineering', value: 'engineering'},
  {dep: 'HR', value: 'hr'},
  {dep: 'Compliance', value: 'compliance'},
  {dep: 'Finance', value: 'finance'},
];

const ROLES = [
  {label: 'Default', value: 'Default'},
  {label: 'Marketing', value: 'Marketing'},
  {label: 'Admin', value: 'Admin'},
  {label: 'Compliance', value: 'Compliance'},
  {label: 'Retention', value: 'Retention'},
  {label: 'Reviewer', value: 'Reviewer'},
];

export {
  dates,
  JOB_TITLE,
  DEPARTMENTS,
  ROLES,
  NAV_ITEMS_BASE_ANALYTICS,
  NAV_RECONCILO,
  NAV_ITEMS_OTHERS,
  NAV_ITEMS_SETTINGS,
  NAV_ITEMS_BLOG,
  COMPANY_TABS,
};
