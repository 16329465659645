import React from 'react';
import moment from 'moment';
import {DateRangePicker} from 'rsuite';
import {calendar} from '../assets/images/images';
import useWindowSize from '../helpers/hooks/useWindowSize';

function DateRangeFilter({onChange}) {
  const size = useWindowSize();

  const handleCallback = data => {
    const start = moment(data[0]).format('YYYY-MM-DD HH:mm:ss');
    const end = moment(data[1]).format('YYYY-MM-DD HH:mm:ss');

    onChange({
      end,
      start,
    });
  };

  return (
    <div className="flex items-center">
      <button className="flex items-center justify-between h-12 gap-2 px-4 rounded bg-grey20 text-grey60">
        {size.width > 800 && (
          <label htmlFor="date">
            <img src={calendar} alt="" />
          </label>
        )}
        <DateRangePicker
          onChange={handleCallback}
          placeholder={
            size.width > 800 ? '01/01/2021 - 31/12/2021' : 'Date Range'
          }
          size={size.width > 800 ? 'lg' : 'sm'}
          placement="leftStart"
        />
        {size.width < 800 && (
          <label htmlFor="date">
            <img src={calendar} className="" alt="" />
          </label>
        )}
      </button>
    </div>
  );
}

export default DateRangeFilter;
