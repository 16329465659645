import React from 'react';
import {empty} from '../assets/images/images';

export function EmptyState({title, body, height}) {
  return (
    <div
      className="flex flex-col justify-center items-center absolute"
      style={{height: `${height ?? 80}vh`, width: '70vw'}}
    >
      <img src={empty} alt="empty" />
      <h2 className="text-lg font-bold text-grey100 mt-6 mb-2">{title}</h2>
      <p>{body}</p>
    </div>
  );
}
