import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import * as Actions from '../../../store/actions';
import GreyButton from '../../../components/GreyButton';
import {fetchVerificationTimeline} from '../../../helpers/queryFns/verifications';

const timelineLimit = 4;

function ActivityLog() {
  const [next, setNext] = useState(timelineLimit);
  const {slug} = useParams();

  const handleLoadMore = () => {
    setNext(next + timelineLimit);
  };

  const {data: timeline} = useQuery(
    ['timeline'],
    () => fetchVerificationTimeline(slug),
    {
      retry: false,
    },
  );

  return (
    <div className="p-4 mt-4 rounded bg-white-900 lg:bg-white grow lg:grow-0 text-start">
      <p className="text-xs font-medium uppercase text-grey">Activity log</p>
      <div className="mt-4">
        <div className="grid">
          {timeline?.data?.slice(0, next)?.map((time, index) => (
            <div
              key={index}
              className="activity-parent grid gap-[6px] grid-rows-[2rem] h-full py-[4px]"
            >
              <span className="relative grid items-center text-xs font-medium text-grey60 after:content-[''] after:absolute after:border-r after:border-dashed after:border-grey-100 after:h-[40px] after:right-[-3px]">
                {time.datetime.split(' ').pop()?.replace('+00:00', '')}
              </span>
              <span className="text-sm lowercase text-grey60 activity-description leading-[12px] relative grid items-center pl-2 before:content-[''] before:absolute before:bg-brandBlue before:rounded-full before:w-[5px] before:h-[5px] before:top-1/2 before:left-[-3px] before:-mt-[1px] ">
                {time?.event_value ?? '-'}
              </span>
            </div>
          ))}
        </div>

        {next < timeline?.meta?.total_count && (
          <GreyButton
            className="flex items-start gap-2 mt-4"
            fontSize="text-xs"
            xPadding="px-2"
            yPadding="pt-[9px] pb-[7px]"
            height="h-7"
            buttonText="Load more"
            onClick={handleLoadMore}
          />
        )}
      </div>
    </div>
  );
}

export default connect(state => state, Actions)(ActivityLog);
