import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {connect, useDispatch} from 'react-redux';
import moment from 'moment';
import {toast} from 'react-toastify';
import Layout from './Layout';
import {Loader, RowData} from '../../../components';
import {placeholder} from '../../../assets/images/images';
import * as Actions from '../../../store/actions';
import {isObjectEmpty} from '../../../helpers';
import {
  fetchVerificationSummary,
  updateDocument,
} from '../../../helpers/queryFns/verifications';
import {SESSION_SUMMARY} from '../../../store/types';
import ExpandImageToggle from './ExpandImageToggle';
import {EditableGovData} from './EditableGovtData';

function RoundedImage({imageUrl}) {
  return (
    <div
      style={{width: '19vh', height: '19vh', borderRadius: '50rem'}}
      className="flex items-center justify-center overflow-clip shadow-lg shadow-brandBlue4"
    >
      <img
        src={imageUrl}
        alt="Liveness"
        className="h-full w-auto object-cover object-center"
      />
    </div>
  );
}

function BioData({KYC_Business}) {
  const {state} = useLocation();
  const {slug} = useParams();
  const [isEditing, setIsEditing] = useState({});
  const [canReview, setCanReview] = useState(false);
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const {
    data: session_summary,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ['verifications-summary', {slug}],
    () => fetchVerificationSummary(slug || state?.sessionId),
    {
      onSuccess: data => {
        dispatch({type: SESSION_SUMMARY, payload: {...data}});
      },
    },
  );

  const {mutate: updateVerificationDocs, isLoading: isSaving} = useMutation(
    updateDocument,
    {
      onSuccess: data => {
        toast.success(data.msg);
        queryClient.invalidateQueries('verifications-summary');
      },
    },
  );

  const LIVENESS = session_summary?.data?.Liveness;
  const VERIFICATION = session_summary?.data?.Verification;
  // const GOV_DATAS = session_summary?.data?.government_datas;
  const GOV_ID_DATA = session_summary?.data?.government_id_data;
  const USER_DATA = session_summary?.data?.user_data;
  const BUSINESS_DATA = session_summary?.data?.business_data;
  const BUSINESS_ID = session_summary?.data?.business_id;
  const UPLOAD_FILES = session_summary?.data?.files;

  const BUSINESS_DATA2 = KYC_Business?.entity;
  const EMAIL = session_summary?.data?.email;
  const PHONE = session_summary?.data?.phone;
  const vStatus = VERIFICATION?.status?.toLowerCase();

  const vType = VERIFICATION?.verification_type?.toLowerCase();
  // const vMode = VERIFICATION?.verification_mode?.toLowerCase();

  // const vType = VerificationTypes.find(
  //   v => v.value?.toLowerCase() === verificationType,
  // );

  const reversedDateStr = useCallback(
    dateStr => dateStr.split('-').reverse().join('-'),
    [],
  );

  const fullName = useMemo(
    () =>
      USER_DATA?.first_name
        ? `${USER_DATA?.first_name || '-'} ${USER_DATA?.last_name} ${
            USER_DATA?.middle_name
          }`
        : GOV_ID_DATA?.first_name
        ? `${GOV_ID_DATA?.first_name} ${GOV_ID_DATA?.last_name} ${GOV_ID_DATA?.middle_name}`
        : '-',
    [USER_DATA, GOV_ID_DATA],
  );

  const USER_DATA_1 = useMemo(
    () => [
      {
        leftText: 'Full name',
        rightText: fullName,
      },
      {
        leftText: 'date of birth',
        rightText:
          USER_DATA?.dob &&
          moment(USER_DATA?.dob).format('Do MMMM YYYY') === 'Invalid date'
            ? moment(reversedDateStr(USER_DATA?.dob)).format('Do MMMM YYYY')
            : moment(USER_DATA?.dob).format('Do MMMM YYYY') || '-',
      },
      {
        leftText: 'Nationality',
        rightText: USER_DATA?.nationality || '-',
      },
      {
        leftText: 'Residence country',
        rightText: USER_DATA?.residence_country || '-',
      },
      {
        leftText: 'Mobile',
        rightText: USER_DATA?.mobile || '-',
      },
    ],
    [USER_DATA, fullName, reversedDateStr],
  );

  const CUSTOMER_BUSINESS_DATA = useMemo(
    () => [
      {
        leftText: 'Company name',
        rightText: `${BUSINESS_DATA2?.approved_name || '-'} `,
      },
      {
        leftText: BUSINESS_DATA?.gd_type,
        rightText: `${BUSINESS_DATA2?.rc_number || '-'} `,
      },
      {
        leftText: 'Country',
        rightText: BUSINESS_DATA?.country || '-',
      },
      {
        leftText: 'Address',
        rightText: BUSINESS_DATA2?.address || '-',
      },
      {
        leftText: 'registration date',
        rightText:
          moment(BUSINESS_DATA2?.registration_date).format('Do MMMM YYYY') ||
          '-',
      },
    ],
    [BUSINESS_DATA, BUSINESS_DATA2],
  );

  const CUSTOMER_BUSINESS_ID = useMemo(
    () => [
      {
        leftText: 'Company name',
        rightText: `${BUSINESS_ID?.extracted_names || '-'} `,
      },
      {
        leftText: BUSINESS_ID?.extracted_type || 'RC',
        rightText: `${BUSINESS_ID?.extracted_rc_no || '-'}`,
      },
      {
        leftText: 'Country',
        rightText: BUSINESS_ID?.country || '-',
      },
      {
        leftText: 'date',
        rightText: moment(BUSINESS_ID?.datetime).format('Do MMMM YYYY') || '-',
      },
    ],
    [BUSINESS_ID],
  );

  const resizeImage = (id, enlarge) => {
    const img = document.getElementById(id);

    if (enlarge) {
      img.classList.remove('max-h-[192px]');
    } else {
      img.classList.add('max-h-[192px]');
    }
  };

  const reArrangeData = data =>
    data?.map(item =>
      Object.entries(item || {}).reduce((acc, [key, value]) => {
        if (value && typeof value !== 'boolean') {
          acc[key] = value;
        }
        if (value === '' || value === null) {
          acc[key] = '-';
        }
        return acc;
      }, {}),
    );

  const ID_DATA =
    GOV_ID_DATA !== null && Array.isArray(GOV_ID_DATA)
      ? [{...GOV_ID_DATA[0], address: ''}]
      : [
          {
            first_name: '-',
            last_name: '-',
            middle_name: '-',
            date_of_birth: '-',
            nationality: '-',
            expiry_date: '-',
            date_issued: '-',
            document_number: '-',
            document_type: '-',
            address: '',
            ...GOV_ID_DATA,
          },
        ];

  const filteredIdData = reArrangeData(ID_DATA);

  useEffect(() => {
    if (VERIFICATION?.reviewer === null) {
      refetch(slug);
    }
  }, [slug, refetch, VERIFICATION?.reviewer]);

  const getLivenessStatus = (status = '') =>
    ({
      completed: {
        text: 'Successful',
        theme: 'bg-success400 text-success20',
      },
      failed: {
        text: 'Failed',
        theme: 'bg-statusBg-500 text-danger',
      },
    }[status]);

  return (
    <Layout
      verificationId={slug}
      loading={isLoading || isFetching}
      isLoading={isLoading}
      setCanReview={setCanReview}
      canReview={canReview}
      placeholder={placeholder}
      resizeImage={resizeImage}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {!isObjectEmpty(USER_DATA) && (
            <>
              <p className="font-medium text-grey ">User Data</p>

              <div className="flex flex-col mt-6">
                {USER_DATA_1.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
            </>
          )}
          {!isObjectEmpty(BUSINESS_DATA) && (
            <>
              <p className="font-medium text-grey mt-14">Business Data</p>

              <div className="flex flex-col mt-6">
                {CUSTOMER_BUSINESS_DATA.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
            </>
          )}
          {!isObjectEmpty(BUSINESS_ID) && (
            <>
              <p className="font-medium text-grey mt-14 ">Business ID</p>
              <div className="flex flex-col mt-6">
                {CUSTOMER_BUSINESS_ID.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
              <div className="flex items-center gap-2 mt-6 justify-end ">
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px] self-end">
                  {Math.floor(Number(BUSINESS_ID?.confidence)) || 0}% confidence
                  level
                </div>
              </div>
              <div className="relative mt-10">
                {typeof BUSINESS_ID?.image_url === 'string' &&
                BUSINESS_ID?.image_url.endsWith('pdf') ? (
                  <object
                    width="100%"
                    height="600px"
                    data={BUSINESS_ID?.image_url}
                    aria-label="pdf"
                  />
                ) : (
                  <img
                    src={BUSINESS_ID?.image_url || placeholder}
                    alt=""
                    className="w-full mt-6 object-contain max-h-[192px]"
                    id="id-url"
                  />
                )}
                <ExpandImageToggle
                  resizeImage={resizeImage}
                  imgUrl={BUSINESS_ID?.image_url || placeholder}
                  id="id-url"
                />
              </div>
            </>
          )}
          {/* {!isObjectEmpty(GOV_DATAS) && ( */}
          {/*  <div> */}
          {/*    <EditableGovData */}
          {/*      isSaving={isSaving} */}
          {/*      govData={filteredGovData} */}
          {/*      vStatus={vStatus} */}
          {/*      isEditing={isEditing} */}
          {/*      slug={slug} */}
          {/*      VERIFICATION={VERIFICATION} */}
          {/*      resizeImage={resizeImage} */}
          {/*      title="Government Data" */}
          {/*      vMode={vMode} */}
          {/*      vType={vType} */}
          {/*      hasId */}
          {/*      isGovtData */}
          {/*    /> */}
          {/*    /!* {USER_DATA_1.map(({leftText, rightText}, i) => ( *!/ */}
          {/*    /!*  <RowData key={i} leftText={leftText} rightText={rightText} /> *!/ */}
          {/*    /!* ))} *!/ */}
          {/*  </div> */}
          {/* )} */}
          {!isObjectEmpty(EMAIL) && (
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p className="font-medium text-grey">Email</p>
              </div>

              <div className="flex flex-col mt-4">
                <div className="flex items-center justify-between px-4 py-4 border-t border-grey-100 last:border-y md:px-0">
                  <span className="text-xs font-medium uppercase text-grey">
                    Email
                  </span>
                  <span className="text-sm text-grey60">{EMAIL.email}</span>
                </div>
              </div>
            </div>
          )}
          {!isObjectEmpty(PHONE) && (
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p className="font-medium text-grey">Phone Number</p>
              </div>

              <div className="flex flex-col mt-4">
                <div className="flex items-center justify-between px-4 py-4 border-t border-grey-100 last:border-y md:px-0">
                  <span className="text-xs font-medium uppercase text-grey">
                    Phone number
                  </span>
                  <span className="text-sm text-grey60">{PHONE.phone}</span>
                </div>
              </div>
            </div>
          )}
          {VERIFICATION?.id_url && (
            <EditableGovData
              isSaving={isSaving}
              govData={filteredIdData}
              vStatus={vStatus}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              updateVerificationDocs={updateVerificationDocs}
              slug={slug}
              VERIFICATION={VERIFICATION}
              resizeImage={resizeImage}
              title={'Government ID'}
              vType={vType}
              canReview={canReview}
            />
          )}
          {!isObjectEmpty(LIVENESS) &&
            (LIVENESS.close_mouth_url ||
              LIVENESS.face_url ||
              LIVENESS.failed_image) &&
            vType !== 'rc-number' && (
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <p className="font-medium text-grey">
                    {vType && vType?.includes('video')
                      ? 'Video'
                      : vType && vType?.includes('selfie')
                      ? 'Selfie'
                      : 'Liveness'}
                  </p>
                  <div className="flex items-center gap-2">
                    {LIVENESS.status && (
                      <div
                        className={`uppercase pt-[5px] pb-[3px] px-2 text-xs  ${
                          getLivenessStatus(LIVENESS.status?.toLowerCase())
                            ?.theme
                        }  font-medium rounded-full w-fit flex items-start gap-[2px]`}
                      >
                        {
                          getLivenessStatus(LIVENESS.status?.toLowerCase())
                            ?.text
                        }
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-center flex-wrap md:flex-nowrap items-center gap-2 mt-6 w-full md:max-w-full">
                  {LIVENESS?.face_url && (
                    <div className="relative w-fit">
                      {!LIVENESS?.face_url.endsWith('.webm') ? (
                        <RoundedImage imageUrl={LIVENESS?.face_url} />
                      ) : (
                        <video width="auto" height="auto" controls>
                          <track kind="captions" />
                          <source src={LIVENESS?.face_url} type="video/webm" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  )}
                  {LIVENESS?.open_mouth_url && (
                    <div className="relative w-fit">
                      <RoundedImage imageUrl={LIVENESS?.open_mouth_url} />
                    </div>
                  )}
                  {LIVENESS?.close_mouth_url && (
                    <div className="relative w-fit">
                      <RoundedImage imageUrl={LIVENESS?.close_mouth_url} />
                    </div>
                  )}{' '}
                  {LIVENESS?.failed_image && (
                    <div className="relative w-fit">
                      <RoundedImage imageUrl={LIVENESS?.failed_image} />
                    </div>
                  )}
                </div>
              </div>
            )}
          {Array.isArray(UPLOAD_FILES) && UPLOAD_FILES.length > 0 && (
            <div className="mt-10">
              <p className="font-medium text-grey">Other IDs/Documents</p>
              <div className="relative mt-2">
                {UPLOAD_FILES.map((file, i) => (
                  <div
                    className="mb-7 relative bg-white-900 border-dashed border-2 rounded-md border-brandBlue4 p-3 w-full"
                    key={i}
                  >
                    <div className=" flex justify-end  w-full">
                      <span className="uppercase pt-[2px] pb-[3px] px-2 text-xs text-brandBlue50 font-medium bg-brandBlue4 rounded-full">
                        {file?.title || '-'}
                      </span>
                    </div>

                    {['.jpg', '.png', '.jpeg', '.j'].includes(
                      file?.file_url.match(/\.[^.]*$/)[0],
                    ) ? (
                      <div className="relative">
                        <img
                          src={
                            file?.file_url?.replace('.jpg', '.jpeg') ||
                            placeholder
                          }
                          alt=""
                          className="w-full mt-3 rounded-md object-cover max-h-[192px]"
                          id={`id-url-${i}`}
                        />
                        <ExpandImageToggle
                          resizeImage={resizeImage}
                          imgUrl={
                            file?.file_url?.replace('.jpg', '.jpeg') ||
                            placeholder
                          }
                          id={`id-url-${i}`}
                        />
                      </div>
                    ) : (
                      <object
                        width="100%"
                        height="600px"
                        data={file?.file_url}
                        aria-label="file"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}

export default connect(state => state, Actions)(BioData);
