import React from 'react';

export function WalletIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      width={20}
      height={20}
    >
      <path
        d="M18.375 12.75C18.375 13.0467 18.287 13.3367 18.1222 13.5834C17.9574 13.83 17.7231 14.0223 17.449 14.1358C17.175 14.2494 16.8734 14.2791 16.5824 14.2212C16.2914 14.1633 16.0241 14.0204 15.8144 13.8107C15.6046 13.6009 15.4617 13.3336 15.4038 13.0426C15.346 12.7517 15.3757 12.4501 15.4892 12.176C15.6027 11.9019 15.795 11.6676 16.0417 11.5028C16.2883 11.338 16.5783 11.25 16.875 11.25C17.2728 11.25 17.6544 11.408 17.9357 11.6893C18.217 11.9706 18.375 12.3522 18.375 12.75ZM22.125 9.375V16.875C22.125 17.6706 21.8089 18.4337 21.2463 18.9963C20.6837 19.5589 19.9207 19.875 19.125 19.875H5.62502C4.82937 19.875 4.06631 19.5589 3.5037 18.9963C2.94109 18.4337 2.62502 17.6706 2.62502 16.875V5.71125C2.61348 5.31009 2.68255 4.91069 2.82814 4.5367C2.97373 4.1627 3.19288 3.82173 3.47261 3.53395C3.75235 3.24618 4.08698 3.01745 4.45669 2.86132C4.82641 2.70519 5.22369 2.62483 5.62502 2.625H18C18.2984 2.625 18.5845 2.74353 18.7955 2.95451C19.0065 3.16548 19.125 3.45163 19.125 3.75C19.125 4.04837 19.0065 4.33452 18.7955 4.5455C18.5845 4.75647 18.2984 4.875 18 4.875H5.62502C5.52444 4.87495 5.42488 4.89512 5.33226 4.93433C5.23964 4.97354 5.15585 5.03097 5.08588 5.10322C5.01591 5.17546 4.96118 5.26104 4.92496 5.35487C4.88873 5.4487 4.87175 5.54885 4.87502 5.64938V5.65688C4.89077 5.85546 4.98191 6.04048 5.12973 6.17401C5.27755 6.30754 5.47087 6.37946 5.67002 6.375H19.125C19.9207 6.375 20.6837 6.69107 21.2463 7.25368C21.8089 7.81629 22.125 8.57935 22.125 9.375ZM19.875 9.375C19.875 9.17609 19.796 8.98532 19.6553 8.84467C19.5147 8.70402 19.3239 8.625 19.125 8.625H5.67002C5.4018 8.62511 5.13465 8.59109 4.87502 8.52375V16.875C4.87502 17.0739 4.95404 17.2647 5.09469 17.4053C5.23534 17.546 5.42611 17.625 5.62502 17.625H19.125C19.3239 17.625 19.5147 17.546 19.6553 17.4053C19.796 17.2647 19.875 17.0739 19.875 16.875V9.375Z"
        fill="#8E8E91"
      />
    </svg>
  );
}
