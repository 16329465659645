import React from 'react';
import {filterIc, filterWhite} from '../assets/images/images';

export default function FilterButton({
  setOpenFilter,
  openFilter,
  filterCount = 0,
  className = '',
}) {
  return (
    <button
      className={`${className} flex items-center gap-2 p-[13px] pt-[14px] h-12 pb-3 font-medium outline outline-1 rounded focus-visible:outline-brandBlue ${
        filterCount === 0
          ? 'bg-white-900 text-grey80 outline-grey60'
          : 'bg-brandBlue text-white outline-none'
      }`}
      onClick={() => setOpenFilter(!openFilter)}
    >
      <img
        src={filterCount === 0 ? filterIc : filterWhite}
        alt=""
        width={24}
        height={24}
      />
      <span>Filter</span>
      <span
        className={`${
          filterCount === 0 ? 'hidden' : 'flex'
        } items-center justify-center text-xs text-brandBlue w-[18px] h-[18px] bg-white rounded-full`}
      >
        {filterCount}
      </span>
    </button>
  );
}
