const handleFileInput = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const data = {
      file: reader.result,
      filename: file.name,
    };
    callback(data);
  };
};

export default handleFileInput;
