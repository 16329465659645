import React, {useState} from 'react';
import {handleImageUpload} from '../../../helpers/handleImageUpload';
import {Accordion} from '../../../components/Accordion';

function ThemeConfig({settings, setSettings, setIsUploading}) {
  const [logo, setLogo] = useState({
    file: '',
    filename: '',
  });
  const [favicon, setFavIcon] = useState({
    file: '',
    filename: '',
  });

  const handleLogoUpload = file => {
    handleImageUpload({file, setIsUploading}, data => {
      setSettings({...settings, imageUrl: data?.imageUrl});
      setLogo({
        file: data.file,
        filename: data?.filename,
      });
    });
  };
  const handleFavIconUpload = file => {
    handleImageUpload({file, setIsUploading}, data => {
      setSettings({...settings, favicon: data?.imageUrl});
      setFavIcon({
        file: data.file,
        filename: data?.filename,
      });
    });
  };
  return (
    <Accordion title="Theme Configurations" background="bg-white-900">
      <div>
        <div className="mt-3">
          <label
            htmlFor="company_primary_color"
            className="flex flex-col items-start max-w-full text-sm label text-grey60"
          >
            Company&apos;s primary color
            <div className="flex w-full relative">
              <input
                value={settings?.companyPrimaryColor ?? ''}
                onChange={e =>
                  setSettings({
                    ...settings,
                    companyPrimaryColor: e.target.value,
                  })
                }
                id="company_primary_color"
                className="mr-2 flex-1 py-4 pl-4 mt-1 input bg-white max-w-full"
              />
              <input
                onChange={e =>
                  setSettings({
                    ...settings,
                    companyPrimaryColor: e.target.value,
                  })
                }
                type="color"
                value={settings?.companyPrimaryColor ?? ''}
                className="w-[70px] p-0 absolute right-0  border-none outline-none mt-1 input bg-white "
              />
            </div>
          </label>
        </div>
        <div className="flex mt-6 gap-5 justify-between items-center">
          <label className=" w-full text-sm label text-grey60">
            Upload Logo
            <input
              accept="image/*"
              type="file"
              onChange={e => {
                const selectedFile = e.target.files[0];
                setLogo({
                  ...logo,
                  file: selectedFile,
                  filename: selectedFile?.name,
                });
                handleLogoUpload(selectedFile);
              }}
            />
            <div className="mt-6 p-4 cursor-pointer bg-white-900 text-center border border-brandBlue border-dashed rounded w-[300px]">
              <p
                className={`${
                  logo?.filename ? 'text-black' : 'text-grey60'
                } text-sm font-medium w-auto`}
              >
                {logo?.filename?.substring(0, 35) ||
                  "Click here to upload company's logo"}
              </p>
            </div>
          </label>

          <img
            className="mt-2 w-24"
            src={logo?.file || settings?.imageUrl}
            alt=""
          />
        </div>

        <div className="flex mt-6 gap-5 justify-between items-center">
          <label className=" w-full text-sm label text-grey60">
            Upload Favicon
            <input
              accept="image/*"
              type="file"
              onChange={e => {
                const selectedFile = e.target.files[0];
                setFavIcon({
                  ...favicon,
                  file: selectedFile,
                  filename: selectedFile?.name,
                });
                handleFavIconUpload(selectedFile);
              }}
            />
            <div className="mt-6 p-4 cursor-pointer bg-white-900 text-center border border-brandBlue border-dashed rounded w-[300px]">
              <p
                className={`${
                  favicon?.filename ? 'text-black' : 'text-grey60'
                } text-sm font-medium w-auto`}
              >
                {favicon?.filename?.substring(0, 35) ||
                  "Click here to upload company's favicon"}
              </p>
            </div>
          </label>

          <img
            className="mt-2 w-24"
            src={favicon?.file || settings?.favicon}
            alt=""
          />
        </div>
      </div>
    </Accordion>
  );
}
export default ThemeConfig;
