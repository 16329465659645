import React, {useState} from 'react';
import {dropdownGrey} from '../assets/images/images';

export function Accordion({
  isOpen = true,
  border = false,
  title,
  onClick,
  children,
  customTitle,
  background = 'bg-white',
  ...props
}) {
  const [isActive, setIsActive] = useState(isOpen);

  return (
    <div
      className={`${background} flex flex-col rounded ${
        isActive ? 'pb-0' : 'pb-4'
      } ${border ? 'border border-[#F6F6F8]' : ''} `}
      onClick={onClick || null}
      {...props}
    >
      <div
        // tabIndex={0}
        className={`p-4 flex items-center justify-between cursor-pointer ${
          isActive ? '' : 'mb-2 pb-0'
        }`}
        onClick={() => setIsActive(!isActive)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsActive(!isActive);
          }
        }}
      >
        {customTitle || (
          <p
            className={`text-sm ${
              isActive ? 'text-body' : 'text-grey font-medium'
            }`}
          >
            {title}
          </p>
        )}
        <img
          src={dropdownGrey}
          alt=""
          className={`${
            isActive ? 'rotate-0 ease-out' : 'rotate-180 ease-in'
          } duration-200 transition`}
          width={16}
          height={16}
        />
      </div>
      <div className={`px-4 ${background} ${isActive ? 'hidden' : ''} `}>
        {children}
      </div>
    </div>
  );
}
