import React, {useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {toast} from 'react-toastify';
import {connect, useDispatch} from 'react-redux';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import PrimaryButton from '../../components/PrimaryButton';
import {updateCurrencyRate} from '../../helpers/queryFns/admin';
import {getCompanySettings} from '../../helpers/queryFns/fetchCompanyDetails';

function Settings() {
  const [currency, setCurrency] = useState({
    dollar_exchange_rate: 0,
  });
  const dispatch = useDispatch();
  const {data: settings} = useQuery(['companySettings-query'], () =>
    getCompanySettings(dispatch),
  );
  const {mutate, isLoading} = useMutation(
    data => updateCurrencyRate(data, dispatch),
    {
      onSuccess: () => {
        toast.success('Service has been updated successfully');
      },
    },
  );

  useEffect(() => {
    setCurrency({
      dollar_exchange_rate: settings?.settings?.dollar_exchange_rate,
    });
  }, [settings?.settings?.dollar_exchange_rate]);

  const handleChange = e => {
    const {value, name} = e.target;
    setCurrency({...currency, [name]: +value});
  };

  return (
    <DashboardLayout className="mt-4" headerText="Super Admin Settings">
      <LayoutContainer>
        <div className="mt-8 flex flex-col w-full h-full justify-center items-center ">
          <div className="max-w-sm mx-auto sm:my-8">
            <h5 className="mb-6 text-grey80 text-lg text-center font-bold">
              Settings
            </h5>
            <form className="max-w-sm" onSubmit={e => e.preventDefault()}>
              <label
                htmlFor="search"
                className="relative label2 flex flex-wrap items-center w-full mb-1 font-bold"
              >
                Dollar Rate ($)
              </label>
              <input
                name="dollar_exchange_rate"
                className="bg-grey20 border-0 outline-0 py-[10px] px-4 h-12 max-w-[385px] w-full rounded"
                type="number"
                placeholder={`Change USD rate. Currently: ${
                  settings?.settings?.dollar_exchange_rate || 0
                }`}
                onChange={handleChange}
              />

              <PrimaryButton
                className="inline-flex max-w-[385px] justify-center w-full text-white rounded text-tiny bg-brandBlue mt-6 mb-6"
                buttonText="Save changes"
                loadingState={isLoading}
                onClick={() => mutate(currency)}
                style={{width: 500}}
              />
            </form>
          </div>
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default connect(state => state, null)(Settings);
