import {LOGOUT_USER, LOADING, SET_USER} from '../types';

const initialState = {
  loading: null,
  user: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};

    case SET_USER:
      return {...state, user: action.payload};

    case LOGOUT_USER:
      return {...initialState};
    default:
      return state;
  }
}
