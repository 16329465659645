import React, {useCallback, useContext} from 'react';
import {CheckboxContext} from '../helpers/context/CheckboxContext';
import Checkbox from './Checkbox';

export default function Checkboxes({
  data,
  onCheck,
  values,
  filterName,
  className,
}) {
  const {isChecked, setIsChecked} = useContext(CheckboxContext);

  const handleCheckedBox = useCallback(
    e => {
      const {value, checked} = e.target;

      const checkedItems = {
        ...isChecked,
        [value]: checked,
      };
      const selectedItems = Object.keys(checkedItems)
        .filter(k => checkedItems[k] && values.includes(k))
        .join(',');
      setIsChecked(checkedItems);
      onCheck({[filterName]: selectedItems});
    },
    [filterName, setIsChecked, isChecked, onCheck, values],
  );

  return (
    <div
      className={`${className} bg-white relative flex flex-col gap-4 py-4 animate-dropdown`}
    >
      {data?.map(c => (
        <React.Fragment key={c.value}>
          <Checkbox
            key={c?.value}
            name={c?.name}
            label={c?.label}
            value={c?.value}
            isChecked={isChecked[c?.value]}
            onChange={handleCheckedBox}
          />
        </React.Fragment>
      ))}
    </div>
  );
}
