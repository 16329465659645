import React, {useState, useMemo} from 'react';
import Select from 'react-select';
import {useParams} from 'react-router-dom';
import {
  DropdownIndicator,
  customStyles,
  PrimaryButton,
  Modal,
} from '../../../components';
import AssignApps from './AssignApps';

export default function InviteMemberModal({
  open,
  setOpen,
  roles,
  inviteMember,
  invitingMember,
}) {
  const {id} = useParams();
  const roleOptions = useMemo(
    () =>
      roles
        ? roles
            .filter(role => role.role !== 'Owner')
            .map(role => ({value: role.id, label: role.role}))
        : null,
    [roles],
  );

  const [selectedRole, setSelectedRole] = useState([]);
  const [email, setEmail] = useState('');
  const [selectedApps, setSelectedApps] = useState({});

  const handleSubmit = e => {
    e.preventDefault();
    inviteMember({
      email,
      role: selectedRole.label,
      ...(selectedApps?.pullApps?.length !== 0 && {
        apps: selectedApps?.pushApps,
      }),
      company: id,
    });
  };

  return (
    <Modal
      show={open}
      modalTitle="Invite a member"
      onClose={setOpen}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <form onSubmit={handleSubmit} className="mt-6 text-left">
        <label
          htmlFor="dui"
          className="flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          Email address
          <input
            type="email"
            id="dui"
            className="max-w-full mt-2 input"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="example@gmail.com"
            required
          />
        </label>

        <label
          htmlFor="role"
          className="mt-6 mb-2 flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          Role
        </label>
        <Select
          defaultValue={selectedRole}
          onChange={setSelectedRole}
          options={roleOptions}
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="auto"
        />

        <AssignApps
          setAppsToAssign={data => {
            setSelectedApps(data);
          }}
        />

        <PrimaryButton
          fontSize="text-sm"
          buttonText="Send invite"
          className="inline-flex justify-center w-full mt-6 text-white rounded bg-brandBlue"
          loadingState={invitingMember}
          disabled={invitingMember}
        />
      </form>
    </Modal>
  );
}
