import {toast} from 'react-toastify';
import api from './api';

function fetchSenderIds(params) {
  return api.get('/admin/sender_ids', {params}).then(res => res.data);
}

function mutateSenderId({type, sender_id}) {
  return api
    .post(`/admin/${type}/sender_id`, {sender_id})
    .then(res => res.data);
}

async function exportSenderIds() {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const response = await api
    .get('/admin/sender_ids/export')
    .then(res => res.data);
  toast.success(response?.message);
  return response;
}

export {fetchSenderIds, mutateSenderId, exportSenderIds};
