import React, {useState} from 'react';
import {useQuery} from 'react-query';
import moment from 'moment';
import Select from 'react-select';
import {useLocation} from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import TableLayout from '../../components/TableLayout';
import DateRangeFilter from '../../components/DateFilter';
import {
  fetchAuditTrails,
  exportAuditTrail,
} from '../../helpers/queryFns/auditTrail';
import Loader from '../../components/Loader';
import {EmptyState} from '../../components/EmptyState';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../components';
import {check_export} from '../../assets/images/images';
import {fetchCompanies} from '../../helpers/queryFns/company';
import {debounceFunction} from '../../helpers/debounceFunction';

export default function AuditTrail() {
  const location = useLocation();

  const defaultDate = {
    end: moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    start: moment('2021-01-01').format('YYYY-MM-DD HH:mm:ss'),
  };
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState('1');
  const [{start, end}, setDateFilters] = useState(defaultDate);
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const query = new URLSearchParams(location?.search);
  const page = Number(query.get('page'));

  const {data, isLoading, isFetching} = useQuery(
    [
      'audit-trail',
      {
        end,
        page: currentPage,
        start,
        company_id: selectedCompany ? selectedCompany.value : '',
      },
    ],
    () =>
      fetchAuditTrails({
        end,
        page: currentPage,
        start,
        company_id: selectedCompany ? selectedCompany.value : '',
      }),
  );

  const {
    isLoading: loadingAuditTrailExport,
    isFetching: fetchingAuditTrailExport,
    refetch: refetchingAuditTrailExport,
  } = useQuery('export-audit-trail', () => exportAuditTrail(), {
    enabled: false,
    onSuccess: () => {
      setOpen(false);
    },
  });

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const {data: companyData, refetch: fetchCompanyData} = useQuery(
    ['company-data', {name: searchText, page: page === 0 ? 1 : page}],
    () => fetchCompanies({name: searchText, page: page === 0 ? 1 : page}),
    {enabled: false},
  );

  const debouncedSearch = debounceFunction(inputValue => {
    setSearchText(inputValue);
    setCurrentPage(1);
    fetchCompanyData();
  }, 1000);

  const handleCompanySearch = newValue => {
    debouncedSearch(newValue);
  };

  const options =
    companyData?.companies?.map(company => ({
      label: company.name,
      value: company.id,
    })) || [];

  return (
    <DashboardLayout headerText="Audit Trail">
      <LayoutContainer>
        <div className="flex flex-wrap justify-between gap-4 audit-trial">
          <Select
            value={selectedCompany}
            onChange={option => {
              setSelectedCompany(option);
              handleCompanySearch(option.label);
            }}
            options={options}
            onInputChange={inputValue => handleCompanySearch(inputValue)}
            placeholder="Search for company"
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            className="mt-1"
            styles={customStyles}
          />

          <div className="flex flex-wrap items-center gap-4">
            <h4 className="font-medium text-tiny text-grey60">Filter by:</h4>
            <DateRangeFilter onChange={date => setDateFilters(date)} />

            {!isLoading && data?.records?.length ? (
              <PrimaryButton
                buttonText="Export table"
                xPadding="px-6"
                className="font-medium"
                onClick={() => setOpen(!open)}
              />
            ) : (
              <PrimaryButton
                buttonText="Refresh"
                xPadding="px-6"
                className="font-medium "
                onClick={() => {
                  setSearchText('');
                  setSelectedCompany(null);
                }}
              />
            )}
            <Modal show={open} onClose={setOpen}>
              <div className="flex flex-col items-center px-12 pt-20 pb-10">
                <img className="w-[134px]" src={check_export} alt="" />
                <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                  Your export file is being prepared. When it is ready, it will
                  be sent to the email attached to this account.
                </h2>
                <PrimaryButton
                  buttonText="Continue"
                  xPadding="px-6"
                  className="w-full font-medium"
                  disabled={loadingAuditTrailExport || fetchingAuditTrailExport}
                  loadingState={
                    loadingAuditTrailExport || fetchingAuditTrailExport
                  }
                  onClick={refetchingAuditTrailExport}
                />
                <button
                  type="button"
                  className="inline-flex justify-center w-full mt-6 text-navGrey"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </Modal>
          </div>
        </div>
        {isLoading ? (
          <Loader height={45} />
        ) : (
          <>
            {!data?.totalRecords ? (
              <EmptyState
                title="No audit listed"
                body="No audit trail is listed yet"
              />
            ) : (
              <TableLayout>
                <thead className="text-xs font-semibold uppercase">
                  <tr className="border-b border-tableGrey">
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      users
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Company
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Action
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      time logged
                    </th>
                  </tr>
                </thead>

                <tbody
                  className="text-sm divide-y divide-gray-100"
                  style={{opacity: isFetching && 0.3}}
                >
                  {data?.records?.map((record, i) => (
                    <tr className="border-b border-tableGrey" key={i}>
                      <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                        {record?.actor_name}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {record?.company}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap capitalize">
                        {record?.action.replace(/\./g, ' ')}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {moment(record?.createdAt).format(
                          'Do MMM, YYYY, h:mm a',
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableLayout>
            )}
          </>
        )}

        <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
          <Pagination
            total={(data && data?.totalPages) || 0}
            current={+currentPage}
            onPageChange={activePage => {
              pageClick(activePage);
            }}
          />
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}
