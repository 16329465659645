import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import DateRangeFilter from '../../components/DateFilter';
import {getBatchCompanyLookup} from '../../helpers/queryFns/fetchCompanyDetails';
import Loader from '../../components/Loader';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';

export default function CompanyBatchEasyLookup() {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const batchLookupQuery = useQuery(['batch-lookup-query', id], () =>
    getBatchCompanyLookup(id, {page: currentPage}),
  );

  function truncate(string, n) {
    return string?.length > n ? `${string.substring(0, n - 1)}...` : string;
  }

  return (
    <DashboardLayout className="mt-4" headerText="Company/Batch Easy lookup">
      <LayoutContainer>
        <CompanyLayout>
          {batchLookupQuery.data?.records.length < 1 ? (
            <p className="mt-6">No records found</p>
          ) : (
            <div className="mt-5 mb-3">
              {batchLookupQuery.isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="flex flex-wrap items-center gap-4 justify-end">
                    <h4 className="font-medium text-tiny text-grey60">
                      Filter by:
                    </h4>
                    <DateRangeFilter />
                    <PrimaryButton
                      buttonText="Export table"
                      xPadding="px-6"
                      className="font-medium"
                    />
                  </div>
                  <TableLayout>
                    <thead className="text-xs font-semibold uppercase">
                      <tr className="border-b border-tableGrey">
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          name
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          id type
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          status
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          type
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          count
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          progress
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {batchLookupQuery.data?.records.map((record, i) => (
                        <tr className="border-b border-tableGrey" key={i}>
                          <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                            {truncate(
                              `${record.last_name} ${record.first_name}`,
                              24,
                            ) ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {record.model_type.toUpperCase() ?? '-'}
                          </td>
                          <td
                            className={`p-3 text-sm text-left ${
                              record.status === 'complete'
                                ? 'text-success'
                                : record.status === 'failed'
                                ? 'text-danger'
                                : null
                            } whitespace-nowrap`}
                          >
                            {record.status.replace(/^./, str =>
                              str.toUpperCase(),
                            ) ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {record.type ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {record.count ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {record.progress ?? '-'}
                          </td>
                          <td className="p-3 text-left text-tiny text-grey80 whitespace-nowrap">
                            {moment(record.date_created).format(
                              'MMM DD, YYYY',
                            ) ?? '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableLayout>
                </>
              )}
            </div>
          )}
          <div className="mt-4 mb-8">
            <Pagination
              total={batchLookupQuery?.data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
            {batchLookupQuery.isFetching ? (
              <p className="border-none mt-4">Updating...</p>
            ) : null}
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
