import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';

export default function Dropdown({children, value, topPosition = 'top-0'}) {
  return (
    <Menu as="div" className="inline-block relative text-left">
      <div>
        <Menu.Button>
          {value || (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 8C13.1046 8 14 7.10457 14 6C14 4.89543 13.1046 4 12 4C10.8954 4 10 4.89543 10 6C10 7.10457 10.8954 8 12 8Z"
                fill="#1B2A4E"
              />
              <path
                d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                fill="#1B2A4E"
              />
              <path
                d="M12 20C13.1046 20 14 19.1046 14 18C14 16.8954 13.1046 16 12 16C10.8954 16 10 16.8954 10 18C10 19.1046 10.8954 20 12 20Z"
                fill="#1B2A4E"
              />
            </svg>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${topPosition} right-0 z-40 p-4 w-max bg-white rounded drop-shadow origin-top-right`}
        >
          <div className="flex flex-col gap-4 items-start">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
