import {toast} from 'react-toastify';
import api from './api';

function fetchCompanies(params) {
  return api.get('/admin/companies', {params}).then(res => res.data);
}
function fetchBaasSettlements(params) {
  return api
    .get('/baas/wallet/settlement/fetch', {...params})
    .then(res => res.data);
}
function fetchBaasSettlement(params) {
  return api
    .get('/baas/wallet/settlement/retrieve', {...params})
    .then(res => res.data);
}
function updateBaasSettlement(data) {
  return api
    .put('/baas/wallet/settlement/update', {...data})
    .then(res => res.data);
}
function fetchCompanySummary() {
  return api.get('/admin/companies/summary').then(res => res.data);
}
const exportCompanies = async () => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const response = await api
    .get('/admin/companies/export')
    .then(res => res.data);
  toast.success(response?.message);
  return response;
};

function mutateCompany({type, company_id}) {
  return api.post(`/admin/${type}`, {company_id}).then(res => res.data);
}
function updateCompany({company_id, data}) {
  return api
    .put(`/admin/company/${company_id}`, {...data})
    .then(res => res.data);
}
function createCompany(data) {
  return api.post('/admin/company/invite', {...data}).then(res => res.data);
}

export {
  fetchCompanies,
  mutateCompany,
  fetchCompanySummary,
  exportCompanies,
  updateCompany,
  createCompany,
  fetchBaasSettlements,
  fetchBaasSettlement,
  updateBaasSettlement,
};
