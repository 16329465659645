import React, {useEffect} from 'react';
import Select from 'react-select';
import {ROLES} from '../helpers/constants';
import PrimaryButton from './PrimaryButton';
import {customStyles, DropdownIndicator, MultiValueRemove} from './ReactSelect';

export default function AdminForm({
  adminData,
  handleChange,
  onSubmit,
  onClick,
  formTitle,
  required,
  loadingState,
  disabled,
  adminRole,
  setAdminRole,
}) {
  useEffect(() => {
    const initialAdminRole = adminData?.role?.map(role => ({
      value: role,
      label: role,
    }));
    setAdminRole(initialAdminRole || []);
  }, [adminData?.role, setAdminRole]);

  const handleRoleChange = selectedOptions => {
    setAdminRole(selectedOptions);
  };
  return (
    <div className="max-w-sm mx-auto sm:my-8">
      <h4 className="mb-6 text-2xl font-extrabold text-grey80">{formTitle}</h4>
      <form className="max-w-sm" onSubmit={onSubmit}>
        <label
          htmlFor="first_name"
          data-widerlabel
          className="flex flex-col items-start mb-6 text-sm label text-grey60"
        >
          First name
          <input
            id="first_name"
            data-widerinput
            defaultValue={adminData && adminData.first_name}
            required={required}
            className="w-full py-4 pl-4 mt-1 input bg-grey20"
            onChange={handleChange}
          />
        </label>
        <label
          htmlFor="last_name"
          data-widerlabel
          className="flex flex-col items-start mb-6 text-sm label text-grey60"
        >
          Last name
          <input
            required={required}
            defaultValue={adminData && adminData.last_name}
            id="last_name"
            data-widerinput
            className="w-full py-4 pl-4 mt-1 input bg-grey20"
            onChange={handleChange}
          />
        </label>
        <label
          htmlFor="email"
          data-widerlabel
          className="flex flex-col items-start mb-6 text-sm label text-grey60"
        >
          Email address
          <input
            required={required}
            defaultValue={adminData && adminData.email}
            id="email"
            type="email"
            data-widerinput
            className="w-full py-4 pl-4 mt-1 input bg-grey20"
            onChange={handleChange}
          />
        </label>
        <label
          htmlFor="mobile"
          data-widerlabel
          className="flex flex-col items-start mb-6 text-sm label text-grey60"
        >
          Mobile
          <input
            type="tel"
            data-widerinput
            defaultValue={adminData && adminData.mobile}
            id="mobile"
            required={required}
            className="w-full py-4 pl-4 mt-1 input bg-grey20"
            onChange={handleChange}
          />
        </label>
        <label
          htmlFor="role"
          data-widerlabel
          className="flex flex-col items-start text-sm label text-grey60"
        >
          Role
        </label>
        <Select
          defaultValue={formTitle === 'Add admin' ? [] : adminRole}
          onChange={handleRoleChange}
          options={ROLES}
          isMulti
          multiValueLabel
          placeholder="Select roles"
          className="basic-multi-select text-left"
          classNamePrefix="react-select"
          components={{DropdownIndicator, MultiValueRemove}}
          styles={customStyles}
          menuPlacement="top"
        />
        <PrimaryButton
          className="mt-6 inline-flex justify-center w-full text-white rounded text-tiny bg-brandBlue"
          type="submit"
          buttonText="Submit"
          loadingState={loadingState}
          disabled={disabled}
        />
        <button
          type="button"
          className="inline-flex justify-center w-full mt-6 text-navGrey"
          onClick={onClick}
        >
          Cancel
        </button>
      </form>
    </div>
  );
}
