import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {
  alertCircleGrey,
  companies,
  usersIcon,
} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import DashboardChart from '../../components/DashboardChart';
import Loader from '../../components/Loader';
import {fetchDasboardSummary} from '../../helpers/queryFns/dashboard';
import SingleDropdown from '../../components/SingleDropdown';

const dropdownData = [{env: 'Production'}, {env: 'Sandbox'}];

function Dashboard() {
  const [selected, setSelected] = useState(dropdownData[0]);

  const {data: summaryData, isLoading} = useQuery(
    ['dashboard-summary', selected],
    () => fetchDasboardSummary(selected),
    {retry: false},
  );

  return (
    <DashboardLayout headerText="Dashboard">
      <LayoutContainer>
        <div className="flex flex-wrap gap-8">
          <div className="flex grow gap-8 items-center py-4 pr-16 pl-7 w-fit bg-lighterBlue rounded-lg lg:grow-0">
            <div className="p-[13px] bg-brandBlue rounded-2xl">
              <img src={usersIcon} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="text-tiny font-medium text-grey40">Users</p>
              <h2 className="text-xl font-bold text-grey100">
                {summaryData?.user_count || 0}
              </h2>
            </div>
          </div>
          <div className="flex grow gap-8 items-center py-4 pr-16 pl-7 w-fit bg-lighterBlue rounded-lg lg:grow-0">
            <div className="p-[13px] bg-brandBlue rounded-2xl">
              <img src={companies} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="text-tiny font-medium text-grey40">Transactions</p>
              <h2 className="text-xl font-bold text-grey100">
                {' '}
                {summaryData?.trans_count || 0}
              </h2>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6 pl-0 mt-16 max-w-[1042px] xl:pl-[76px]">
          <div className="flex flex-wrap gap-4 justify-between">
            <div className="flex gap-2 items-center">
              <h3 className="text-base font-medium text-grey80">
                {selected.env}
              </h3>
              <img src={alertCircleGrey} alt="" />
            </div>
            <div className="flex flex-wrap gap-4 items-center">
              <h4 className="text-tiny font-medium text-grey60">Filter by:</h4>
              <SingleDropdown
                dropdownData={dropdownData}
                selected={selected}
                setSelected={setSelected}
                spanText={selected.env}
              />
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <DashboardChart
              env={selected.env}
              data={
                summaryData?.api_count_grouped_sandbox ||
                summaryData?.api_count_grouped_production
              }
            />
          )}
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default Dashboard;
