import api from './api';

function fetchVerifications(params) {
  return api.get('/admin/human-review', {params}).then(res => res.data);
}
function fetchVerificationSummary(refId) {
  return api.get(`/admin/human-review/${refId}/summary`).then(res => res.data);
}
function fetchDocuments(refId) {
  return api
    .get(`/admin/human-review/${refId}/documents`)
    .then(res => res.data);
}
function fetchKTCCustomer(customerId) {
  return api
    .get(`/admin/human-review/customer?customer_id=${customerId}`)
    .then(res => res.data);
}
function fetchVerificationTimeline(refId) {
  return api.get(`/admin/human-review/${refId}/timeline`).then(res => res.data);
}

function assignReviewer({refId, data}) {
  return api
    .post(`/admin/human-review/${refId}/reviewer`, {...data})
    .then(res => res.data);
}

function updateDocument({refId, docId, data}) {
  return api
    .put(`/admin/human-review/${refId}/documents/${docId}`, {...data})
    .then(res => res.data);
}
function reviewDocument({refId, data}) {
  return api
    .post(`/admin/human-review/${refId}/review`, {...data})
    .then(res => res.data);
}

export {
  fetchVerifications,
  assignReviewer,
  fetchVerificationSummary,
  updateDocument,
  reviewDocument,
  fetchVerificationTimeline,
  fetchKTCCustomer,
  fetchDocuments,
};
