import React from 'react';

export default function Radio({label, value, name, handleChecked}) {
  return (
    <div className="pl-4" onChange={handleChecked}>
      <label
        htmlFor={value}
        className="relative flex flex-row items-center gap-2 font-normal transition ease-in-out duration-500"
      >
        <input
          type="radio"
          value={value}
          id={value}
          name={name}
          className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
        />
        <span className="text-sm text-grey">{label}</span>
      </label>
    </div>
  );
}
