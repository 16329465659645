import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {css} from '@emotion/react';

function Loader({height}) {
  const override = css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #3977de;
    margin-top: 20px;
    margin-bottom: 20px;
    height: ${height ? `${height}` : 75}vh;
  `;
  return <BeatLoader css={override} size={12} color="#3977de" />;
}

export default Loader;
