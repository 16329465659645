import {combineReducers} from 'redux';

import auth from './auth';
import data from './data';
import company from './company';
import blog from './blog';
import admin from './admin';
import verifications from './verifications';
import aml from './aml';

export default combineReducers({
  admin,
  auth,
  blog,
  company,
  data,
  verifications,
  aml,
});
