import React, {useState} from 'react';
import {dropdownBlue} from '../../assets/images/images';

export default function BillPaymentServices() {
  const [accordion, setAccordion] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between gap-4 mt-8">
        <div
          onClick={() => setAccordion(!accordion)}
          className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
        >
          <h4 className="font-medium text-grey80">Bill payment services</h4>
          <div className="flex items-center gap-4">
            <img
              src={dropdownBlue}
              alt=""
              className={`${
                accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
              } duration-200 transition`}
            />
          </div>
        </div>
      </div>

      {accordion && (
        <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="buy_airtime_ng"
                className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
              >
                Buy airtime (Nigeria)
                <input
                  id="buy_airtime_ng"
                  type="number"
                  className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                  disabled
                />
              </label>
            </div>

            <div>
              <label
                htmlFor="dstv_payment"
                className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
              >
                DSTV payment
                <input
                  id="dstv_payment"
                  type="number"
                  className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                  disabled
                />
              </label>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label
                htmlFor="buy_data"
                className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
              >
                Buy data bundles
                <input
                  id="buy_data"
                  type="number"
                  className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                  disabled
                />
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
