import React from 'react';

export default function Card({src, label, value}) {
  return (
    <div className="flex grow gap-8 items-center py-4 pr-16 pl-7 w-fit bg-lighterBlue rounded-lg lg:grow-0">
      <img src={src} alt="" />
      <div className="flex flex-col">
        <p className="text-tiny font-medium text-grey40 whitespace-nowrap">
          {label}
        </p>
        <h2 className="text-xl font-bold text-grey100">{value}</h2>
      </div>
    </div>
  );
}
