/* eslint-disable no-console */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import baseRoutes from './base';
import * as Actions from '../store/actions';
import * as Helpers from '../helpers';
import NotFound from './NotFound';
import PAGES_URL from './pageRoutes';

// const PageComponent = component => connect(state => state, Actions)(component);
class PageRoutes extends Component {
  state = {
    privateRoutes: null,
    publicRoutes: null,
  };

  constructor() {
    super();
    this.setRoutes(true);
  }

  componentDidMount() {
    const {load} = this.props;
    if (Helpers.token.get('admin:token')) {
      load();
    }
  }

  componentDidCatch(...args) {
    console.log('An error occured', args);
  }

  setRoutes = firstMount => {
    const state = {
      privateRoutes: baseRoutes.private.map(route =>
        Helpers.token.get('admin:token') ? (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            element={route.component}
          />
        ) : (
          <Route
            key={route.path}
            path="/*"
            element={<Navigate to={PAGES_URL.LOGIN} />}
          />
        ),
      ),
      publicRoutes: baseRoutes.public.map(route => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          element={route.component}
        />
      )),
    };
    if (firstMount) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = state;
    } else {
      this.setState(state);
    }
  };

  render() {
    const {privateRoutes, publicRoutes} = this.state;
    return (
      <Router>
        <Routes>
          {publicRoutes}
          {privateRoutes}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  }
}

export default connect(state => state, Actions)(PageRoutes);
