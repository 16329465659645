import moment from 'moment';
import {getDateRange} from './getDateRange';

export const customDateFilters = ({value, appFilters, onCheck}) => {
  switch (value?.toLowerCase()) {
    case 'today': {
      const date = `${moment().format('YYYY-MM-DD')}`;
      return onCheck({
        ...appFilters,
        start: `${date} 00:00:00`,
        end: `${date} 23:59:59`,
      });
    }

    case 'yesterday': {
      const today = new Date();
      const date = moment(today.setDate(today.getDate() - 1)).format(
        'YYYY-MM-DD',
      );

      return onCheck({
        ...appFilters,
        start: `${date} 00:00:00`,
        end: `${date} 23:59:59`,
      });
    }

    case 'seven_days': {
      const {start, end} = getDateRange(6);
      return onCheck({...appFilters, start, end});
    }

    case 'fifteen_days': {
      const {start, end} = getDateRange(14);
      return onCheck({...appFilters, start, end});
    }

    case 'thirty_days': {
      const {start, end} = getDateRange(29);
      return onCheck({...appFilters, start, end});
    }

    default:
      break;
  }
};
