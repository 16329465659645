import React, {useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {
  CustomDateRadio,
  FilterWrapper,
  Radios,
  Checkboxes,
  FilterAccordion,
} from '../../components';
import {dates} from '../../helpers/constants';
import {customDateFilters} from '../../helpers/customDateFilters';

const status = [
  {name: 'published', label: 'Published', value: 'published'},
  {name: 'unpublished', label: 'Unpublished', value: 'unpublished'},
  {name: 'draft', label: 'Draft', value: 'draft'},
];

function BlogFilter({blog: {blog_categories}, ...otherProps}) {
  const {
    openFilter,
    setOpenFilter,
    resetFilters = () => {},
    handleFilter,
    onCheck,
    appFilters,
  } = otherProps;

  const CATEGORIES_DATA = useMemo(
    () =>
      blog_categories &&
      blog_categories?.categories?.map(category => ({
        label: category.name,
        value: category._id,
        name: category.name,
      })),
    [blog_categories],
  );

  const STATUS_DATA = useMemo(
    () =>
      status?.map(stat => ({
        label: stat.name,
        value: stat.value,
        name: stat.name,
      })),
    [],
  );

  // const AUTHOR_DATA = useMemo(
  //   () =>
  //     author?.map(aut => ({
  //       label: aut.name,
  //       value: aut.value,
  //       name: aut.name,
  //     })),
  //   [],
  // );

  const [activeIndex, setActiveIndex] = useState(null);
  const [date, setDate] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customDate, setCustomDate] = useState(false);
  const [dateOpen, setDateClick] = useState(false);
  const [count, setCount] = useState({});

  const maxDate = new Date();

  const logsSearchInputs = useMemo(
    () => [
      {
        data: CATEGORIES_DATA,
        filterName: 'category',
        onCheck: data => {
          onCheck(data);
        },
      },
      {
        data: STATUS_DATA,
        filterName: 'status',
        onCheck: data => {
          onCheck(data);
        },
      },
      // {
      //   data: AUTHOR_DATA,
      //   filterName: 'author',
      //   onCheck: data => {
      //     onCheck(data);
      //   },
      // },
    ],
    [CATEGORIES_DATA, STATUS_DATA, onCheck],
  );

  const handleClearFilters = useCallback(() => {
    setActiveIndex(null);
    setDate('');
    setStartDate(new Date());
    setEndDate(new Date());
    setCustomDate(false);
    setDateClick(false);
    resetFilters();
    setCount({});
  }, [resetFilters]);

  const onDateCheck = useCallback(
    e => {
      const {value} = e.target;
      customDateFilters({value, appFilters, onCheck});

      setDate(value);
      setCount({
        ...count,
        date: 1,
      });

      setCustomDate(false);
    },
    [appFilters, count, onCheck],
  );

  const onCustomDateCheck = e => {
    const {checked} = e.target;
    if (checked) {
      setDate(1);
      setCustomDate(true);
    }
  };

  return (
    <FilterWrapper
      resetFilters={handleClearFilters}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilter={handleFilter}
      clearFilters
      appFilters={appFilters}
    >
      <FilterAccordion
        selectedCount={date === '' ? 0 : 1}
        filterName="date"
        onClick={() => setDateClick(!dateOpen)}
        activeIndex={dateOpen}
      >
        <Radios data={dates} onCheck={onDateCheck}>
          <label
            htmlFor="custom_date"
            className="pl-4 relative flex flex-row items-center gap-2 font-normal transition ease-in-out duration-500"
          >
            <input
              type="radio"
              value="custom_date"
              id="custom_date"
              name="date"
              className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
              onClick={onCustomDateCheck}
            />
            <span className="text-sm text-grey">Specific date range</span>
          </label>
          {customDate && (
            <CustomDateRadio
              selectedStart={startDate}
              selectedEnd={endDate}
              onChangeStart={a => {
                const start = moment(a).format('YYYY-MM-DD HH:mm:ss');
                onCheck({...appFilters, start});
                setStartDate(a);
              }}
              onChangeEnd={a => {
                const end = moment(a).format('YYYY-MM-DD HH:mm:ss');
                onCheck({...appFilters, end});
                setEndDate(a);
              }}
              startDate={startDate}
              endDate={endDate}
              maxDate={maxDate}
              minDate={startDate}
            />
          )}
        </Radios>
      </FilterAccordion>

      {logsSearchInputs.map(({data, filterName}, index) => {
        const isActive = activeIndex === index;

        return (
          <FilterAccordion
            activeIndex={isActive}
            key={filterName}
            filterName={filterName}
            selectedCount={count[filterName]}
            onClick={() => setActiveIndex(index)}
          >
            <Checkboxes
              data={data}
              values={data?.map(d => d.value) || []}
              onCheck={newData => {
                const filterValues = Object.values(newData)[0]?.split(',');
                onCheck(newData);
                setCount({
                  ...count,
                  [filterName]: filterValues,
                });
              }}
              filterName={filterName}
            />
          </FilterAccordion>
        );
      })}
    </FilterWrapper>
  );
}

export default connect(state => state, null)(BlogFilter);
