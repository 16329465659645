import api from './queryFns/api';

export const handleImageUpload = async ({file, setIsUploading}, callBack) => {
  try {
    setIsUploading(true);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Image = reader.result.split(',')[1];
      const formattedBase64Image = `data:${file.type};base64,${base64Image}`;

      api
        .post('/blog/upload', {image: formattedBase64Image})
        .then(response => {
          const imageUrl = response.data.image;
          callBack({
            file: formattedBase64Image,
            filename: file.name,
            imageUrl,
          });

          setIsUploading(false);
        })
        .catch(error => {
          setIsUploading(false);
          throw new Error('Error uploading image:', error);
        });
    };
  } catch (error) {
    setIsUploading(false);
    throw new Error('Error reading file:', error);
  }
};
