/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Layout from './Layout';
import {
  backNofill,
  backWhitefill,
  corruptedFile,
  smiley,
} from '../../../assets/images/images';
import {DashboardLayout, RowData} from '../../../components';
import * as Actions from '../../../store/actions';
import Loader from '../../../components/Loader';
import PAGES_URL from '../../pageRoutes';

function AmlCheck({
  amlCheck = 1,
  getAMLHistory,
  getAMLProfile,
  aml: {amlProfile, amlHistory, loading},
  verifications: {session_summary},
}) {
  const {slug} = useParams();
  const oldId = useRef('');

  const navigate = useNavigate();
  const [activeRelationships, setActiveRelationships] = useState({});
  const currentId = slug?.includes('=') ? slug?.split('=')[1] : '';
  const [profileID, setProfileID] = useState('');
  const appId = localStorage.getItem('appId');
  const VERIFICATION = session_summary?.data?.Verification;

  function handleActiveRelationships(index) {
    setActiveRelationships(index);
  }

  function setPreviousId(previousId) {
    oldId.current = previousId?.toProfileId;
  }

  const newSlug = slug?.includes('=') ? slug?.split('=')[0] : slug;

  useEffect(() => {
    if (VERIFICATION?.aml_reference) {
      getAMLHistory(
        VERIFICATION?.aml_reference,
        '',
        true,
        appId,
        newSlug,
        navigate,
        currentId,
      );
    }
  }, [
    appId,
    VERIFICATION?.aml_reference,
    getAMLHistory,
    getAMLProfile,
    navigate,
    currentId,
  ]);
  useEffect(() => {
    if (profileID && currentId !== profileID) {
      getAMLProfile({id: profileID, appId});
    }
  }, [appId, getAMLProfile, profileID]);

  const PROFILES = amlProfile?.entity?.result;
  const PROFILE = useMemo(
    () => [
      {leftText: 'Country', rightText: PROFILES?.countryName || '-'},
      {leftText: 'Category', rightText: PROFILES?.entryCategory || '-'},
      {leftText: 'Subcategory', rightText: PROFILES?.subCatDesc || '-'},
      {leftText: 'Date of birth', rightText: PROFILES?.dob || '-'},
      {leftText: 'Place of birth', rightText: PROFILES?.pob || '-'},
    ],
    [PROFILES],
  );
  const newData = useMemo(
    () =>
      !loading &&
      PROFILES?.relationships?.find(
        profile => profile?.toProfileId === oldId.current,
      ),
    [PROFILES, loading, profileID],
  );

  const person =
    amlHistory?.entity?.profile_ids &&
    amlHistory?.entity?.profile_ids?.find(
      user => user.profile_id === profileID || currentId,
    );

  const Links = useMemo(
    () => PROFILES?.sourceWebLinks?.split(';'),
    [PROFILES?.sourceWebLinks],
  );
  return (
    <>
      {!amlCheck || !VERIFICATION?.aml_reference ? (
        <Layout>
          <div>
            <p className="font-medium text-grey">AML Check</p>
            <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white-900">
              <img src={corruptedFile} alt="" width={48} height={48} />
              <p className="max-w-[179px] mt-2 text-sm text-center text-grey60">
                We did not find any information on this person
              </p>
            </div>
          </div>
        </Layout>
      ) : (
        <DashboardLayout
          topPadding="pt-0"
          bg="bg-white-900"
          xlLeftMargin="xl:ml-12"
        >
          <div className="flex-wrap items-start min-h-full xl:flex xl:flex-nowrap basis-full">
            <div className="basis-[17.58%] flex flex-col gap-6 pt-16 sm:pt-6 xl:pt-[35px] xl:sticky xl:top-[35px]">
              <h2 className="hidden text-base font-medium sm:block text-grey sm:text-lg whitespace-nowrap">
                AML Screening
              </h2>

              <div className="flex flex-col gap-6 mt-11">
                {!activeRelationships.toName ? (
                  amlHistory?.entity?.profile_ids &&
                  amlHistory?.entity?.profile_ids?.map((user, index) => (
                    <Link
                      to={`/verifications/aml-check/${slug?.split('=')[0]}=${
                        user?.profile_id
                      }`}
                      key={index}
                      className={`${
                        user?.profile_id === currentId ? 'text-brandBlue' : ''
                      } flex flex-col gap-2 text-grey60 font-medium`}
                    >
                      <p className="text-sm">{user?.name}</p>
                      <p className="text-xs">{user?.match_type}</p>
                    </Link>
                  ))
                ) : (
                  <button
                    onClick={() => setActiveRelationships({})}
                    className="flex items-start gap-2"
                  >
                    <img src={backWhitefill} alt="" width={16} height={16} />
                    <p className="ml-2 text-sm font-medium text-left">
                      {person?.name ? `  Back to ${person?.name}` : 'Go back'}
                    </p>
                  </button>
                )}
              </div>
            </div>

            <div className=" xl:ml-8 xl:basis-[45.67%] mt-6 xl:mt-0 px-4 grow xl:grow-0 xl:p-8 pt-4 xl:pt-[36px] bg-white-900 xl:bg-white">
              <div className="flex items-center justify-between w-full mb-4">
                <p className="font-medium text-grey ">AML Check</p>
                <button
                  onClick={() =>
                    navigate(
                      PAGES_URL.VERIFICATIONSBIODATA.replace(
                        ':slug',
                        VERIFICATION?.reference_id,
                      ),
                    )
                  }
                  className="flex items-center gap-2"
                >
                  <img src={backNofill} alt="" width={16} height={16} />
                  <p className="ml-2 text-sm">Back</p>
                </button>
              </div>

              {loading ? (
                <Loader />
              ) : !loading && !amlProfile ? (
                <>
                  <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white-900">
                    <img src={corruptedFile} alt="" width={48} height={48} />
                    <p className="max-w-[179px] mt-2 text-sm text-center text-grey60">
                      We did not find any information on this person
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center justify-between">
                    <p className="text-grey">
                      {activeRelationships?.toName ?? person?.name}
                    </p>
                    {oldId.current !== profileID && (
                      <button
                        onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          newData && setActiveRelationships(newData);
                          setProfileID(oldId.current);
                        }}
                        className="flex items-center gap-2"
                      >
                        <img src={backNofill} alt="" width={16} height={16} />
                        <p className="ml-2 text-sm">Back</p>
                      </button>
                    )}
                  </div>
                  <div className="mt-5 mb-6">
                    <h3 className="mb-6 font-medium text-grey">Profile</h3>
                    {PROFILE.map((profile, index) => (
                      <RowData key={index} {...profile} />
                    ))}
                  </div>
                  <div className="flex flex-col gap-6 p-4 rounded-lg bg-white-900">
                    <div>
                      <h3 className="mb-2 font-medium text-grey">Summary</h3>
                      <p className="text-sm text-grey60">
                        {PROFILES?.positions || '-'}
                      </p>
                    </div>
                    <div>
                      <h3 className="mb-2 font-medium text-grey">Remarks</h3>
                      <p className="text-sm text-grey60">
                        {PROFILES?.remarks || '-'}
                      </p>
                    </div>
                    <div>
                      <h3 className="mb-2 font-medium text-grey">Source</h3>
                      <p className="text-sm text-grey60">
                        {PROFILES?.sourceName || '-'}
                      </p>
                    </div>
                    <div>
                      <h3 className="mb-2 font-medium text-grey">
                        Source weblink
                      </h3>
                      <ul className="flex flex-col gap-2 ml-4 text-sm break-all list-disc text-brandBlue md:max-w-full">
                        {(Links &&
                          Links?.map(link => (
                            <li key={link}>
                              <a
                                href={link || '-'}
                                target="_blank"
                                rel="noreferrer"
                                className="hover:underline"
                              >
                                {link || '-'}
                              </a>
                            </li>
                          ))) ||
                          '-'}
                      </ul>
                    </div>
                  </div>
                </>
                // </div>
              )}
            </div>

            {amlHistory?.entity?.profile_ids?.length ? (
              <div className="xl:basis-[36%] xl:px-6 pt-16 sm:pt-6 xl:pt-[35px] xl:sticky xl:top-[35px] rounded">
                <div className="p-4 bg-white">
                  <div className="flex items-center gap-1">
                    <h3 className="text-base font-medium text-grey">
                      Relationships
                    </h3>
                    <span className="flex items-center justify-center w-5 h-5 text-xs font-medium text-white rounded-full bg-brandBlue">
                      {PROFILES?.relationships?.length || 0}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-grey60">
                    {PROFILES?.relationships?.length > 0
                      ? 'Click on any record'
                      : 'No relationship found'}
                  </div>

                  <div className="grid grid-cols-2 gap-4 mt-4">
                    {PROFILES?.relationships?.map((relationship, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setProfileID(relationship?.toProfileId);
                          setActiveRelationships(prev => setPreviousId(prev));

                          handleActiveRelationships(relationship);
                        }}
                        className={`flex flex-col gap-2 items-center bg-white-900 rounded card-link px-2 py-6 ${
                          activeRelationships === relationship
                            ? 'border border-brandBlue'
                            : ''
                        }`}
                      >
                        <img src={smiley} alt="" width={24} height={24} />
                        <p className="text-sm font-medium text-grey">
                          {relationship.toName}
                        </p>
                        <p className="text-xs font-medium uppercase text-grey60">
                          {relationship.is}
                        </p>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </DashboardLayout>
      )}
    </>
  );
}

export default connect(state => state, Actions)(AmlCheck);
