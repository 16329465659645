import React from 'react';
import {connect} from 'react-redux';
import {googleIcon, loginBg, logo} from '../assets/images/images';
import * as Actions from '../store/actions';

function Login({loginUser, auth: {loading}}) {
  const handleLogin = () => {
    loginUser();
  };

  return (
    <div
      className="flex bg-lighterBlue h-screen w-full"
      style={{opacity: loading && 0.6}}
    >
      <div className="flex flex-col items-center md:items-start justify-center w-full md:w-1/2 px-5 md:ml-12 lg:ml-[100px]">
        <div className="flex items-center gap-3">
          <img src={logo} alt="logo" width={114} height={37} />
          <span className="text-brandBlue text-lg font-bold">ADMIN</span>
        </div>
        <button
          onClick={handleLogin}
          className="mt-12 rounded google-signin-btn  flex gap-16 bg-white py-3 max-w-sm w-full pl-11"
        >
          <img src={googleIcon} alt="" />
          <p className="text-tiny  font-medium">Sign-In with Google</p>
        </button>
      </div>
      <img
        src={loginBg}
        alt=""
        className="w-1/2 object-cover hidden md:block"
      />
    </div>
  );
}

export default connect(state => state, Actions)(Login);
