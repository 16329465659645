import React, {useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {
  Modal,
  DropdownIndicator,
  customStyles,
  PrimaryButton,
} from '../../../components';
import * as Actions from '../../../store/actions';
import {reviewDocument} from '../../../helpers/queryFns/verifications';

const REVIEW_USER = [
  {value: 2, label: 'Successful'},
  {value: 3, label: 'Failed'},
];

const FAILED_VERIFICATION_REASONS = [
  {value: 102, label: 'ID does not Match Selfie'},
  {value: '003', label: 'ID is not Clear Enough'},
  {value: '005', label: 'User Data does not Match'},
  {value: '009', label: 'ID has expired'},
  {value: '0010', label: 'ID uploaded is different from ID selected'},
  {value: '0011', label: 'Selfie image detected with glasses on'},
  {value: '0012', label: "User didn't provide both sides of ID"},
  {value: '0013', label: 'Captured selfie not realtime'},
  {value: '0014', label: 'Selfie is not clear enough'},
];

function ReviewUserModal({open, setOpen}) {
  const [status, setStatus] = useState(null);
  const [comment, setComment] = useState(null);
  const {slug} = useParams();
  const queryClient = useQueryClient();
  const {mutate: reviewVerification, isLoading} = useMutation(reviewDocument, {
    onSuccess: data => {
      toast.success(data.msg);
      queryClient.invalidateQueries('verifications-summary');
      setOpen(false);
    },
    onError: error => {
      toast.error(error.response.data.error);
      setOpen(false);
    },
  });

  const handleReview = async () => {
    const data = {
      approval_status: status?.value || 0,
      comment: comment?.value?.toString(),
    };

    if (data) {
      await reviewVerification({refId: slug, data});
    }
  };

  return (
    <Modal
      modalTitle="Review"
      show={open}
      onClose={setOpen}
      setOpen={setOpen}
      extraModalClasses="md:max-w-[408px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="text-start mt-5">
        <label htmlFor="review_user" className="relative">
          Status
        </label>
        <Select
          defaultValue={status}
          value={status}
          onChange={setStatus}
          options={REVIEW_USER}
          placeholder="Select status"
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          className="mt-1"
          styles={customStyles}
          isSearchable={false}
        />
      </div>
      {status && status?.value === 3 && (
        <div className="text-start mt-5">
          <label htmlFor="failed_verification_reason" className="relative">
            Reason for failed verification
          </label>
          <Select
            defaultValue={comment}
            value={comment}
            onChange={setComment}
            options={FAILED_VERIFICATION_REASONS}
            placeholder="Select reason for failed verification"
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            className="mt-1"
            styles={customStyles}
            isSearchable={false}
            menuPlacement="auto"
          />
        </div>
      )}

      <PrimaryButton
        onClick={handleReview}
        buttonText="Save"
        className="w-full mt-4"
        disabled={!status || (status?.value === 3 && !comment)}
        loadingState={isLoading}
      />
    </Modal>
  );
}

export default connect(state => state, Actions)(ReviewUserModal);
