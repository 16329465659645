import React from 'react';

export function ApiLogsIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.3332 10H14.9998L12.4998 17.5L7.49984 2.5L4.99984 10H1.6665"
        stroke="#8E8E91"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
