import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
// import {search} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import DateRangeFilter from '../../components/DateFilter';
import GoToTop from '../../components/GoToTop';
import {getCompanyAuditTrail} from '../../helpers/queryFns/fetchCompanyDetails';
import Loader from '../../components/Loader';
import {EmptyState} from '../../components/EmptyState';
// import {SearchInput} from '../../components/SearchInput';

export default function CompanyAuditTrail() {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');
  // const [searchText, setSearchText] = useState('');
  const [{start, end}, setDateFilters] = useState({});

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const {data, isFetching, isLoading} = useQuery(
    ['audit-trail-query', {end, page: currentPage, start}],
    () => getCompanyAuditTrail(id, {end, page: currentPage, start}),
  );

  return (
    <DashboardLayout className="mt-4" headerText="Company/Audit trail">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5 mb-3">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {!data?.totalRecords ? (
                  <EmptyState
                    height={45}
                    title="No user listed"
                    body="No user is listed yet"
                  />
                ) : (
                  <>
                    <div className="flex flex-wrap justify-between gap-4">
                      {/* <SearchInput
                        placeholder="Search list"
                        onChange={text => setSearchText(text)}
                      /> */}
                      <div className="flex flex-wrap items-center gap-4">
                        <h4 className="font-medium text-tiny text-grey60">
                          Filter by:
                        </h4>
                        <DateRangeFilter
                          onChange={date => setDateFilters(date)}
                        />
                      </div>
                    </div>
                    <TableLayout>
                      <thead className="text-xs font-semibold uppercase">
                        <tr className="border-b border-tableGrey">
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            users
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            Action
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            time logged
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-sm divide-y divide-gray-100"
                        style={{opacity: isFetching && 0.3}}
                      >
                        {data?.records?.map((record, i) => (
                          <tr className="border-b border-tableGrey" key={i}>
                            <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                              {record.actor_name ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap capitalize">
                              {record?.action.replace(/\./g, ' ')}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {moment(record?.createdAt).format(
                                'Do MMM, YYYY, h:mm a',
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableLayout>
                  </>
                )}
              </>
            )}
          </div>

          <div className="mt-4 mb-8">
            <Pagination
              total={data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
