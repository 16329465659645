import React, {useState} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {useLocation, useNavigate} from 'react-router-dom';
import {check_export, usersIcon} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import DateRangeFilter from '../../components/DateFilter';
import {EmptyState} from '../../components/EmptyState';
import LayoutContainer from '../../components/LayoutContainer';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import PrimaryButton from '../../components/PrimaryButton';
import {SearchInput} from '../../components/SearchInput';
import TableLayout from '../../components/TableLayout';
import {exportUsers, fetchUser, fetchUsers} from '../../helpers/queryFns/users';

function ReconciloUsers() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  let page = Number(query.get('page'));
  const filter = query.get('filter');
  const start = query.get('start');
  const end = query.get('end');
  const [searchText, setSearchText] = useState(filter);
  const [dateFilters, setDateFilters] = useState({start, end});
  const [open, setOpen] = useState(false);

  const handleChangeCurrentPage = e => {
    navigate(`?page=${e}`);
  };

  const {data, isLoading, isFetching} = useQuery(
    [
      'users',
      {
        end: dateFilters.end,
        filter: searchText,
        page: page === 0 ? 1 : page,
        start: dateFilters.start,
        parent_company: '66b5d4a19c95110040c8666d',
      },
    ],
    () =>
      fetchUsers({
        end: dateFilters.end,
        filter: searchText,
        page: page === 0 ? 1 : page,
        start: dateFilters.start,
        parent_company: '66b5d4a19c95110040c8666d',
      }),
  );

  const {
    isLoading: loadingUsersExport,
    isFetching: fetchingUsersExport,
    refetch: refetchingUsersExport,
  } = useQuery('export-users', () => exportUsers(), {
    enabled: false,
    onSuccess: () => {
      setOpen(false);
    },
  });

  const refreshPage = () => {
    page = 0;
    setSearchText('');
    setDateFilters({});
  };

  return (
    <DashboardLayout headerText="Users">
      <LayoutContainer>
        <div className="flex flex-wrap gap-8">
          <div className="flex items-center gap-8 py-4 pr-16 rounded-lg grow pl-7 w-fit bg-lighterBlue sm:grow-0">
            <div className="p-[13px] bg-brandBlue rounded-2xl">
              <img src={usersIcon} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">Users</p>
              <h2 className="text-xl font-bold text-grey100">
                {data?.totalRecords || '0'}
              </h2>
            </div>
          </div>
        </div>

        <div>
          <h4 className="mt-12 mb-4 font-medium text-grey60">Users list</h4>
          <div className="flex flex-wrap justify-between gap-4">
            <SearchInput
              placeholder="Search users"
              onChange={text => {
                setSearchText(text);
                navigate(`?filter=${text}`);
              }}
              defaultValue={searchText}
            />

            <div className="flex flex-wrap items-center gap-4">
              <h4 className="font-medium text-tiny text-grey60">Filter by:</h4>
              <DateRangeFilter
                onChange={date => {
                  setDateFilters(date);
                  navigate(`?start=${date.start}&end=${date.end}`);
                }}
              />

              {!isLoading && data?.users?.length ? (
                <PrimaryButton
                  buttonText="Export table"
                  xPadding="px-6"
                  className="font-medium"
                  onClick={() => setOpen(!open)}
                />
              ) : (
                <PrimaryButton
                  buttonText="Refresh"
                  xPadding="px-6"
                  className="font-medium"
                  onClick={refreshPage}
                />
              )}
              <Modal show={open} onClose={setOpen}>
                <div className="flex flex-col items-center px-12 pt-20 pb-10">
                  <img className="w-[134px]" src={check_export} alt="" />
                  <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                    Your export file is being prepared. When it is ready, it
                    will be sent to the email attached to this account.
                  </h2>
                  <PrimaryButton
                    buttonText="Continue"
                    xPadding="px-6"
                    className="w-full font-medium"
                    loadingState={loadingUsersExport || fetchingUsersExport}
                    disabled={loadingUsersExport || fetchingUsersExport}
                    onClick={refetchingUsersExport}
                  />
                  <button
                    type="button"
                    className="inline-flex justify-center w-full mt-6 text-navGrey"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <div className="mt-3">
            {!data?.totalRecords ? (
              <EmptyState title="No user listed" body="No user is listed yet" />
            ) : (
              <TableLayout>
                <thead className="text-xs font-semibold text-gray-400 uppercase bg-gray-50">
                  <tr className="border-b border-tableGrey">
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      First name
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Last name
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      no of companies
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Email
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Phone
                    </th>
                  </tr>
                </thead>
                <tbody
                  className="text-sm divide-y divide-gray-100"
                  style={{opacity: isFetching && 0.3}}
                >
                  {data?.users?.map((user, i) => (
                    <tr
                      className="border-b border-tableGrey"
                      key={user?.email + i}
                      onClick={() =>
                        navigate('/users/profile', {
                          state: user?._id,
                        })
                      }
                      onMouseEnter={() => {
                        queryClient.prefetchQuery(['user', user?._id], () =>
                          fetchUser(user?._id),
                        );
                      }}
                    >
                      <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                        {user?.first_name || '-'}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {user?.last_name || '-'}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {user?.companies.length ?? 0}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {user?.email || '-'}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {user?.mobile || '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableLayout>
            )}

            <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
              <Pagination
                total={(data && data?.totalPages) || 0}
                current={page}
                onPageChange={handleChangeCurrentPage}
              />
            </div>
          </div>
        )}
      </LayoutContainer>
    </DashboardLayout>
  );
}
export default ReconciloUsers;
