export const ERROR = 'ERROR';
export const LOADING = 'LOADING';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER = 'SET_USER';
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const ALL_USERS = 'ALL_USERS';
export const USER = 'USER';
export const API_LOGS = 'API_LOGS';
export const SENDER_IDS = 'SENDER_IDS';

// COMPANIES
export const ALL_COMPANIES = 'ALL_COMPANIES';
export const COMPANY_INFO = 'COMPANY_INFO';
export const COMPANY_INFO_SETTINGS = 'COMPANY_INFO_SETTINGS';

// BLOGS
export const BLOG = 'BLOG';
export const BLOGS = 'BLOGS';
export const IMAGE_URL = 'IMAGE_URL';
export const BLOG_SUMMARY = 'BLOG_SUMMARY';
export const BLOG_CATEGORIES = 'BLOG_CATEGORIES';
// ADMINS
export const ADMINS = 'ADMINS';
export const ADMIN = 'ADMIN';

// VERIFICATIONS
export const ADDRESS = 'ADDRESS';
export const TIMELINE = 'TIMELINE';
export const DEVICE_IP = 'DEVICE_IP';
export const KYC_CUSTOMER = 'KYC_CUSTOMER';
export const KYC_BUSINESS = 'KYC_BUSINESS';
export const VERIFICATIONS = 'VERIFICATIONS';
export const SESSION_SUMMARY = 'SESSION_SUMMARY';
export const VERIFICATIONS_LOADING = 'VERIFICATIONS_LOADING';
export const EXPORT_VERIFICATIONS_LOADING = 'EXPORT_VERIFICATIONS_LOADING';

// AML
export const GET_AML_HISTORIES = 'GET_AML_HISTORIES';
export const GET_AML_PROFILE = 'GET_AML_PROFILE';
export const GET_AML_HISTORY = 'GET_AML_HISTORY';
export const AML_LOOKUP_LOADING = 'AML_LOOKUP_LOADING';
export const AML_LOADING = 'AML_LOADING';
export const AML_CONFIG_LOADING = 'AML_CONFIG_LOADING';
export const GET_AML_CONFIG = 'GET_AML_CONFIG';
