import React from 'react';

export default function Checkbox({
  label,
  name,
  value,
  onChange,
  isChecked,
  noLeftPadding = false,
  className,
  labelStyle,
  align = 'items-center',
}) {
  return (
    <div
      className={`${!noLeftPadding ? 'pl-4' : ''} ${className} cursor-pointer`}
    >
      <label
        htmlFor={value}
        className={`relative flex flex-row ${align} gap-2 font-normal transition duration-500 ease-in-out`}
      >
        <input
          type="checkbox"
          name={name}
          id={value}
          value={value}
          onChange={onChange}
          checked={isChecked ?? false}
          className="w-[15px] h-[15px] rounded-sm focus-visible:outline-1 focus-visible:outline-brandBlue"
        />
        <span
          className={`${
            !labelStyle ? 'text-sm text-grey' : labelStyle
          } cursor-pointer`}
        >
          {label}
        </span>
      </label>
    </div>
  );
}
