import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {
  DashboardLayout,
  LayoutContainer,
  PrimaryButton,
} from '../../components';
import SingleDropdown from '../../components/SingleDropdown';
import * as Actions from '../../store/actions';
import {fetchAdminUser, updateAdminUser} from '../../helpers/queryFns/admin';
import {DEPARTMENTS, JOB_TITLE} from '../../helpers/constants';

function Profile({auth}) {
  const queryClient = useQueryClient();
  const [jobTitle, setJobTitle] = useState({});
  const [department, setDepartment] = useState({});
  const {id} = auth?.user;

  const {data: adminUser, isLoading: loadingAdminUser} = useQuery(
    ['adminuser-query'],
    () => fetchAdminUser(id),
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (adminUser) {
      setJobTitle({
        job_title: adminUser?.user?.title,
        value: adminUser?.user?.title.toLowerCase(),
      });
      setDepartment({
        dep: adminUser?.user?.department,
        value: adminUser?.user?.department.toLowerCase(),
      });
    }
  }, [adminUser, loadingAdminUser]);

  const {mutate, isLoading: updatingAdminUserProfile} = useMutation(
    params => updateAdminUser(id, params),
    {
      onSuccess: () => {
        toast.success('Admin user updated successfully');
        queryClient.invalidateQueries('adminuser-query');
      },
    },
  );

  const handleSubmit = e => {
    e.preventDefault();
    mutate({
      title: jobTitle.job_title,
      department: department.dep,
    });
  };

  return (
    <DashboardLayout
      className="bg-white-900 pt-[35px] min-h-screen"
      noMarginTop
      headerText="Dashboard"
    >
      <LayoutContainer>
        <h1 className="text-lg text-grey font-medium">Profile</h1>

        <p className="mt-8 uppercase text-grey60 text-xs font-medium">
          personal information
        </p>
        <form
          onSubmit={handleSubmit}
          className="mt-6 bg-white py-12 px-6 max-w-[528px] rounded-2xl"
        >
          <label
            htmlFor="profile_full_name"
            className="label flex flex-col items-start mb-2 text-sm text-grey80 max-w-full"
          >
            Full Name
            <input
              id="profile_full_name"
              type="text"
              className="input mt-1 max-w-full"
              value={
                !loadingAdminUser
                  ? `${adminUser?.user?.first_name} ${adminUser?.user?.last_name}`
                  : '-'
              }
              disabled
            />
          </label>

          <label className="mt-6 flex items-start text-left text-sm label text-grey">
            Job Title
          </label>
          <SingleDropdown
            dropdownData={JOB_TITLE}
            selected={jobTitle}
            setSelected={e => setJobTitle(e)}
            spanText={jobTitle.job_title ?? '-'}
            width="w-full"
          />
          <label className="mt-6 flex items-start text-left text-sm label text-grey">
            Department
          </label>
          <SingleDropdown
            dropdownData={DEPARTMENTS}
            selected={department}
            setSelected={e => setDepartment(e)}
            spanText={department.dep ?? '-'}
            width="w-full"
          />

          <PrimaryButton
            buttonText="Save profile"
            className="w-full mt-6"
            loadingState={updatingAdminUserProfile}
            disabled={Boolean(updatingAdminUserProfile)}
          />
        </form>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(Profile);
