import React from 'react';
import {Modal} from '../index';
import {closeModal, logo, success_svg} from '../../assets/images/images';

export function SuccessDialog({
  openSuccessDialog,
  setOpenSuccessDialog,
  clientPartner,
  company,
  action,
}) {
  return (
    <Modal
      show={openSuccessDialog}
      onClose={() => setOpenSuccessDialog(false)}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <button
        onClick={() => setOpenSuccessDialog(false)}
        className="absolute right-4 top-4"
      >
        <img src={closeModal} alt="close" width={16} height={16} />
      </button>
      <div className="py-8 space-y-4 flex flex-col items-center max-w-[322px] mx-auto">
        <p className="font-semibold text-grey max-w-[306px]">
          You have{' '}
          {action === 'assigned' ? 'successfully assigned ' : 'removed '}
          {clientPartner} {action === 'assigned' ? 'to ' : 'from '} {company}
        </p>
        <p className="text-body">
          Your invite has been sent, when they complete their onboarding, you
          will be notified
        </p>
        <img src={success_svg} alt="" width={102} height={102} />
        <div className="flex items-center gap-1">
          <p className="text-sm text-grey60">Powered by</p>
          <img src={logo} alt="" width={30.55} height={14} />
        </div>
      </div>
    </Modal>
  );
}
