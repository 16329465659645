import React, {useState} from 'react';
import {useQuery} from 'react-query';
import moment from 'moment';
import {
  check_export,
  creditCard,
  totalRevenue,
} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import Pagination from '../../components/Pagination';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import LayoutContainer from '../../components/LayoutContainer';
import {
  exportBillings,
  fetchBillings,
  fetchBillingsSummary,
} from '../../helpers/queryFns/billings';
import {formatCurrency} from '../../helpers/currencyFormat';
import Loader from '../../components/Loader';
import {SearchInput} from '../../components/SearchInput';
import {EmptyState} from '../../components/EmptyState';
import SingleDropdown from '../../components/SingleDropdown';
import Modal from '../../components/Modal';

const statusData = [
  {status: 'All Status'},
  {status: 'Successful'},
  {status: 'Initiated'},
];

const typeData = [{type: 'All Type'}, {type: 'Paystack'}, {type: 'Topup'}];

export default function Billings() {
  const [currentPage, setCurrentPage] = useState('1');
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    page: 1,
  });
  const [status, setStatus] = useState(statusData[0]);
  const [type, setType] = useState(typeData[0]);
  const [open, setOpen] = useState(false);

  const {data, isLoading, isFetching} = useQuery(
    [
      'billings',
      {
        status: status.status === 'All Status' ? '' : status.status,
        type: type.type === 'All Type' ? '' : type.type,
        ...filters,
        filter: searchText,
      },
    ],
    () =>
      fetchBillings({
        status: status.status === 'All Status' ? '' : status.status,
        type: type.type === 'All Type' ? '' : type.type,
        ...filters,
        filter: searchText,
      }),
  );

  const {data: billingSummary} = useQuery(
    ['billings-summary'],
    fetchBillingsSummary,
  );
  const pageClick = e => {
    setCurrentPage(e);
    setFilters({...filters, page: e});
  };

  const {
    isLoading: loadingBillingsExport,
    isFetching: fetchingBillingsExport,
    refetch: refetchingBillingsExport,
  } = useQuery('export-billings', () => exportBillings(), {
    enabled: false,
    onSuccess: () => {
      setOpen(false);
    },
  });

  return (
    <DashboardLayout headerText="Billings">
      <LayoutContainer>
        <div className="flex flex-wrap gap-8">
          <div className="flex items-center gap-8 py-4 rounded-lg grow px-7 w-fit bg-lighterBlue lg:grow-0">
            <div className="p-[13px] bg-brandBlue rounded-2xl">
              <img src={totalRevenue} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">Total revenue</p>
              <h2 className="text-xl font-bold text-grey100">
                {formatCurrency(billingSummary?.total?.revenue / 100) || 0}
              </h2>
            </div>
          </div>
          <div className="flex items-center gap-8 py-4 rounded-lg grow pr-28 pl-7 w-fit bg-lighterBlue lg:grow-0">
            <div className="p-[13px] bg-yellow rounded-2xl">
              <img src={creditCard} alt="" />
            </div>
            <div className="flex flex-col">
              <p className="font-medium text-tiny text-grey40">
                Total billings
              </p>
              <h2 className="text-xl font-bold text-grey100">
                {billingSummary?.total?.apiLogs?.toLocaleString('en-US') || 0}
              </h2>
            </div>
          </div>
        </div>

        <div>
          <h4 className="mt-12 mb-4 font-medium text-grey60">Billings</h4>
          <div className="flex flex-wrap justify-between gap-4">
            <SearchInput
              placeholder="Search billings"
              onChange={text => setSearchText(text)}
            />
            <div className="flex flex-wrap items-center gap-4">
              <h4 className="font-medium text-tiny text-grey60">Filter by:</h4>

              <SingleDropdown
                dropdownData={statusData}
                selected={status}
                setSelected={setStatus}
                spanText={status.status}
              />
              <SingleDropdown
                dropdownData={typeData}
                selected={type}
                setSelected={setType}
                spanText={type.type}
              />

              <PrimaryButton
                buttonText="Export table"
                xPadding="px-6"
                className="font-medium"
                onClick={() => setOpen(!open)}
              />
              <Modal show={open} onClose={setOpen}>
                <div className="flex flex-col items-center px-12 pt-20 pb-10">
                  <img className="w-[134px]" src={check_export} alt="" />
                  <h2 className="mt-4 mb-6 text-lg font-bold text-center text-grey60">
                    Your export file is being prepared. When it is ready, it
                    will be sent to the email attached to this account.
                  </h2>
                  <PrimaryButton
                    buttonText="Continue"
                    xPadding="px-6"
                    className="w-full font-medium"
                    disabled={loadingBillingsExport || fetchingBillingsExport}
                    loadingState={
                      loadingBillingsExport || fetchingBillingsExport
                    }
                    onClick={refetchingBillingsExport}
                  />
                  <button
                    type="button"
                    className="inline-flex justify-center w-full mt-6 text-navGrey"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loader height={45} />
        ) : (
          <div className="mt-3">
            {!data?.totalRecords ? (
              <EmptyState
                height={45}
                title="No billing listed"
                body="No bill record is listed yet"
              />
            ) : (
              <TableLayout>
                <thead className="text-xs font-semibold uppercase">
                  <tr className="border-b border-tableGrey">
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Reference no
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      amount
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      prev. balance
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      balance
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      company
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      type
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      created
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      status
                    </th>
                  </tr>
                </thead>
                <tbody
                  className="text-sm divide-y divide-gray-100"
                  style={{opacity: isFetching && 0.3}}
                >
                  {data?.billing?.map((bill, i) => (
                    <tr className="border-b border-tableGrey" key={i}>
                      <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                        {bill?.reference_number || '-'}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {formatCurrency(bill?.amount / 100) || 0}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {formatCurrency(bill?.prev_balance / 100) || 0}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {formatCurrency(bill?.balance / 100) || 0}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {bill?.company?.name || '-'}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {bill?.type || '-'}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {moment(bill?.createdAt).format('MMM DD, YYYY') || '-'}
                      </td>
                      <td
                        className={`p-3 text-left text-tiny text-${
                          bill?.status.toLowerCase() === 'successful'
                            ? 'success'
                            : bill?.status.toLowerCase() === 'initiated'
                            ? 'brandBlue'
                            : 'danger'
                        } whitespace-nowrap`}
                      >
                        {bill?.status || '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableLayout>
            )}

            <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
              <Pagination
                total={(data && data?.totalPages) || 0}
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </LayoutContainer>
    </DashboardLayout>
  );
}
