import React, {useState} from 'react';
import Checkbox from '../../../components/Checkbox';
import {eye, hiddenEye} from '../../../assets/images/images';

import {PartnerCompanies} from './PartnerCompanie';
import {Accordion} from '../../../components/Accordion';

const SMTP_INPUTS = [
  {
    name: 'Host',
    key: 'host',
    type: 'text',
  },
  {
    name: 'Port number',
    key: 'port',
    type: 'text',
  },
  {
    name: 'User name',
    key: 'username',
    type: 'text',
  },
];

export function SubDomainConfig({settings, setSettings}) {
  const [show, setShow] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <Accordion title="Sub Domain Configurations" background="bg-white-900">
        <section>
          <div className="mt-3">
            <label
              htmlFor="company_primary_color"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Company&apos;s sub-domain
              <input
                value={settings?.subDomain ?? ''}
                onChange={e =>
                  setSettings({...settings, subDomain: e.target.value})
                }
                id="company_primary_color"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
              />
            </label>
          </div>
          <div className="mt-6">
            <label
              htmlFor="company_tagline"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Company&apos;s tagline or title
              <input
                value={settings?.tagline ?? ''}
                onChange={e =>
                  setSettings({...settings, tagline: e.target.value})
                }
                id="company_tagline"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
              />
            </label>
          </div>
          <label
            htmlFor="is_partner_company"
            className="flex mt-3 text-sm label text-grey60 "
          >
            <div className="flex w-full cursor-pointer">
              <Checkbox
                label="Partner company?"
                noLeftPadding
                className="py-3 cursor-pointer"
                labelStyle="text-sm text-body leading-[18px] "
                align="items-start"
                isChecked={settings?.partnerCompany}
                onChange={e => {
                  setSettings({
                    ...settings,
                    partnerCompany: e.target.checked,
                    parentCompany: e.target.checked
                      ? settings?.parentCompany
                      : {},
                  });
                }}
              />
            </div>
          </label>
          <PartnerCompanies
            settings={settings}
            open={settings.partnerCompany}
            setSettings={setSettings}
            inputKey="parentCompany"
          />
        </section>
      </Accordion>

      <Accordion title="SMTP Configurations" background="bg-white-900">
        <section>
          <div className="mt-3 grid grid-cols-1  md:grid-cols-2 gap-x-4 lg:gap-x-6 w-wull">
            {SMTP_INPUTS.map(input => (
              <label
                key={input.key}
                htmlFor={input.key}
                className="flex mb-3 flex-col items-start max-w-full text-sm label text-grey60"
              >
                {input.name}
                <input
                  value={settings?.smtp?.[input.key] ?? ''}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      smtp: {
                        ...settings?.smtp,
                        [input.key]:
                          // eslint-disable-next-line no-restricted-globals
                          input.key === 'port' && !isNaN(+e.target.value)
                            ? +e.target.value
                            : // eslint-disable-next-line no-restricted-globals
                            input.key === 'port' && isNaN(+e.target.value)
                            ? ''
                            : e.target.value,
                      },
                    })
                  }
                  id={input.key}
                  className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                  type={input.type}
                  placeholder={
                    input.key === 'port'
                      ? 'Only numbers please...'
                      : `Enter ${input.key}...`
                  }
                />
              </label>
            ))}
            <label
              htmlFor="host"
              className="flex mb-3 flex-col items-start max-w-full text-sm label text-grey60"
            >
              Sender email
              <input
                value={settings?.sender_email ?? ''}
                onChange={e =>
                  setSettings({
                    ...settings,
                    sender_email: e.target.value,
                  })
                }
                id="sender_email"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
                placeholder="Enter sender email..."
              />
            </label>{' '}
            <label
              htmlFor="host"
              className="flex flex-col items-start max-w-full text-sm label text-grey60"
            >
              Sender name
              <input
                value={settings?.sender_name ?? ''}
                onChange={e =>
                  setSettings({
                    ...settings,
                    sender_name: e.target.value,
                  })
                }
                id="sender_name"
                className="w-full py-4 pl-4 mt-1 input bg-white max-w-full"
                type="text"
                placeholder="Enter sender name..."
              />
            </label>
            <label
              htmlFor="password"
              className=" max-w-full text-sm label text-grey60"
            >
              Password
              <div className="relative">
                <input
                  value={settings?.smtp?.password ?? ''}
                  onChange={e =>
                    setSettings({
                      ...settings,
                      smtp: {
                        ...settings.smtp,
                        password: e.target.value,
                      },
                    })
                  }
                  id="password"
                  className="w-full z-10 py-4 pl-4 mt-1 input bg-white max-w-full"
                  type={show ? 'text' : 'password'}
                  placeholder="Enter password..."
                />
                {settings?.smtp?.password?.length > 1 && (
                  <button
                    title="View/Hide Password"
                    className="absolute h-1/4  right-6 bottom-5  z-30"
                    onClick={() => setShow(!show)}
                  >
                    <img src={!show ? eye : hiddenEye} alt="hide Icon" />
                  </button>
                )}
              </div>
            </label>
          </div>
        </section>
      </Accordion>

      <Accordion title="Easy Detect URL" background="bg-white-900">
        <section>
          <div className="mt-3 grid grid-cols-1 gap-x-4 lg:gap-x-6 gap-y-3 w-full">
            <label htmlFor="ingest_url">
              Injest Url
              <input
                type="text"
                className="w-full z-10 py-4 pl-4 mt-1 input bg-white max-w-full"
                value={settings?.ingest_url}
                onChange={e =>
                  setSettings({...settings, ingest_url: e.target.value})
                }
                placeholder="Enter Injest url..."
              />
            </label>
          </div>
        </section>
      </Accordion>

      <Accordion title="API URL" background="bg-white-900">
        <section>
          <div className="mt-3 grid grid-cols-1 gap-x-4 lg:gap-x-6 gap-y-3 w-full">
            <label htmlFor="sandbox_url">
              Sandbox Url
              <input
                type="text"
                className="w-full z-10 py-4 pl-4 mt-1 input bg-white max-w-full"
                value={settings?.sandbox_url}
                onChange={e =>
                  setSettings({...settings, sandbox_url: e.target.value})
                }
                placeholder="Enter sandbox url..."
              />
            </label>
            <label htmlFor="live_url">
              Live Url
              <input
                type="text"
                className="w-full z-10 py-4 pl-4 mt-1 input bg-white max-w-full"
                value={settings?.live_url}
                onChange={e =>
                  setSettings({...settings, live_url: e.target.value})
                }
                placeholder="Enter live url..."
              />
            </label>
          </div>
        </section>
      </Accordion>

      <Accordion title="Widget ID" background="bg-white-900">
        <section>
          <div className="mt-3 grid grid-cols-1 gap-x-4 lg:gap-x-6 gap-y-3 w-full">
            <label htmlFor="widget_url">
              Widget ID Url
              <input
                type="text"
                className="w-full z-10 py-4 pl-4 mt-1 input bg-white max-w-full"
                value={settings?.widget_url}
                onChange={e =>
                  setSettings({...settings, widget_url: e.target.value})
                }
                placeholder="Enter Widget ID url..."
              />
            </label>
          </div>
        </section>
      </Accordion>
    </div>
  );
}
