import React from 'react';

export default function TableLayout({children}) {
  return (
    <section className="antialiased">
      <div className="flex flex-col h-full">
        <div className="mx-auto -ml-4 w-full">
          <div className="p-3">
            <div>
              <table className="w-full table-auto">{children}</table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
