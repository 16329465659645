import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import Layout from './Layout';
import {RowData, Loader} from '../../../components';
import * as Actions from '../../../store/actions';
import {EmptyState} from '../../../components/EmptyState';
import {
  // base_address_marker,
  corruptedFile,
  // false_address_marker,
  // true_address_marker,
} from '../../../assets/images/images';

function Address({verifications: {address, loading}}) {
  const {slug} = useParams();

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    const verificationId = localStorage.getItem('verificationId');

    // eslint-disable-next-line no-unused-vars
    const getIpDeviceCheck = _slug => {};

    getIpDeviceCheck(slug || verificationId);
    // eslint-disable-next-line no-unused-vars
    const getAddress = _slug => {};
    getAddress(slug || sessionId);
  }, [slug]);

  const ADDRESS_DATA = useMemo(
    () => [
      {leftText: 'Inputted Address', rightText: address?.data?.base?.name},
      {
        leftText: 'Tracked Address',
        rightText: address?.data?.total,
      },
    ],
    [address],
  );
  //
  // const baseAddress = address?.data?.base;
  // const subAddresses = address?.data?.sub_addresses;

  // eslint-disable-next-line no-unused-vars
  // const formatAddresses = () => {
  //   const addresses = subAddresses && [...subAddresses, baseAddress];
  //   return addresses?.map(add => ({
  //     lat: +add?.latitude,
  //     lng: +add?.longitude,
  //     name: add?.name ? add?.name : 'No address name specified',
  //     markerImage: add.name
  //       ? base_address_marker
  //       : add.match
  //       ? true_address_marker
  //       : false_address_marker,
  //   }));
  // };

  // eslint-disable-next-line no-unused-vars
  // const center = {
  //   lat: Number(address?.data?.base?.longitude || 0),
  //   lng: Number(address?.data?.base?.latitude || 0),
  // };

  return (
    <Layout>
      <div>
        <p className="font-medium text-grey">Address</p>

        {!loading &&
        address &&
        Object.keys(address?.data?.base).length === 0 ? (
          <>
            <EmptyState
              body="We did not find any address information on this person"
              src={corruptedFile}
              noBtn
              height={45}
            />
          </>
        ) : loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex flex-col mt-4">
              {ADDRESS_DATA.map(({leftText, rightText}, i) => (
                <RowData key={i} leftText={leftText} rightText={rightText} />
              ))}
            </div>
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p className="font-medium text-grey">Map</p>
                <div className="uppercase pt-[5px] pb-[3px] px-2 text-xs text-status-400 font-medium bg-disabled rounded-full w-fit flex items-start gap-[2px]">
                  {address?.data?.percent}% match
                </div>
              </div>
              {/* <div className="relative"> */}
              {/*  <GoogleMap */}
              {/*    center={center} */}
              {/*    zoom={8} */}
              {/*    multipleMarkers={formatAddresses()} */}
              {/*  /> */}
              {/* </div> */}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default connect(state => state, Actions)(Address);
