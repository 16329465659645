import React, {useMemo, useState} from 'react';
import Select from 'react-select';
import {useQuery} from 'react-query';
import {DropdownIndicator, customStyles, PrimaryButton} from '.';
import {fetchAdminUsers} from '../helpers/queryFns/admin';
import Checkbox from './Checkbox';
import {PartnerCompanies} from '../routes/Dashboard/CompanySettingsComponents/PartnerCompanie';

const currencies = [
  {label: 'NGN', value: 'NGN'},
  {label: 'USD', value: 'USD'},
  {label: 'KZ (Kwanza)', value: 'AOA'},
];

export default function CreateCompanyForm({
  company,
  handleChange,
  onSubmit,
  required,
  loadingState,
  disabled,
  input,
  setInput,
}) {
  const {
    data: adminUsers,
    isLoading,
    isFetching,
  } = useQuery(['admin-users', {page: 1, limit: 1000}], () =>
    fetchAdminUsers({page: 1, limit: 1000}),
  );
  const [clientPartner, setClientPartner] = useState(null);
  const [currency, setCurrency] = useState(null);

  const CLIENT_PARTNERS =
    useMemo(
      () =>
        (!isLoading || !isFetching) &&
        adminUsers &&
        adminUsers?.users?.map(user => ({
          label: `${user?.first_name} ${user?.last_name}` || '-',
          value: user?._id,
        })),
      [adminUsers, isLoading, isFetching],
    ) || [];

  return (
    <div className="mx-auto sm:mt-8">
      <form className="max-w-full" onSubmit={onSubmit}>
        <label
          htmlFor="first_name"
          className="flex flex-col items-start mb-6 text-sm label text-grey60 max-w-full w-full"
        >
          Company name
          <input
            id="company"
            defaultValue={company && company.company}
            required={required}
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            onChange={handleChange}
          />
        </label>
        <label
          htmlFor="last_name"
          className="flex flex-col items-start mb-6 text-sm label text-grey60 max-w-full w-full"
        >
          Company email address
          <input
            required={required}
            defaultValue={company && company.email}
            id="email"
            className="w-full py-4 pl-4 mt-1 input bg-grey20 max-w-full"
            onChange={handleChange}
          />
        </label>
        <label
          htmlFor="client_partner"
          className="mb-2 flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          <p>Select currency</p>
        </label>
        <Select
          defaultValue={currencies[0]}
          value={currency}
          onChange={data => {
            setCurrency(data);
            setInput({...input, currency: data?.value});
          }}
          options={currencies}
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="top"
          className="text-left"
          placeholder="Select currency"
        />
        <label
          htmlFor="client_partner"
          className="mb-2 mt-6 flex flex-col items-start max-w-full text-sm label text-grey60"
        >
          <p>
            Select a Client Partner{' '}
            <span className="text-grey40">(optional)</span>
          </p>
        </label>
        <Select
          defaultValue={CLIENT_PARTNERS[0]}
          value={clientPartner}
          onChange={data => {
            setClientPartner(data);
            setInput({...input, client_partner: data?.value});
          }}
          options={CLIENT_PARTNERS}
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="top"
          className="text-left"
          placeholder="{Name of client partner}"
        />
        <label
          htmlFor="is_partner_company"
          className="flex   mb-6 text-sm label text-grey60 "
        >
          <div className="flex w-full">
            <Checkbox
              label="Partner company?"
              noLeftPadding
              className="py-[20px]  cursor-pointer"
              labelStyle="text-sm text-body leading-[18px] "
              align="items-start"
              isChecked={input?.is_partner_company}
              onChange={e => {
                setInput({...input, is_partner_company: e.target.checked});
              }}
            />
          </div>
        </label>
        <PartnerCompanies
          open={input.is_partner_company}
          settings={input}
          setSettings={setInput}
          customStyles={{bottom: 55, height: 450}}
          inputKey="parent_company"
        />
        <PrimaryButton
          fontSize="text-sm"
          className="mt-6 inline-flex justify-center w-full text-white rounded bg-brandBlue"
          type="submit"
          buttonText="Create company"
          loadingState={loadingState}
          disabled={disabled}
        />
      </form>
    </div>
  );
}
