import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-query';
import {
  NavLink,
  useParams,
  useNavigate,
  // useLocation
} from 'react-router-dom';
import {connect} from 'react-redux';
import {back} from '../../assets/images/images';
import GoToTop from '../../components/GoToTop';
import {formatNumberToCurrency} from '../../helpers/formatNumberToCurrency';
import {BAAS_TABS} from '../../helpers/constants/nav';
import {fetchBaasSettlement} from '../../helpers/queryFns/company';
import SkeletonLoader from '../../components/SkeletonLoader';

function BaasTab({id, href, text}) {
  return (
    <NavLink
      end
      to={`/baas/settlement/${id}/${href}`}
      className={({isActive}) =>
        `text-grey60 font-medium text-tiny px-3 py-4${
          isActive ? ' activeCompanyTab' : ''
        }`
      }
    >
      {text}
    </NavLink>
  );
}

function CompanyLayout({children}) {
  const {id} = useParams();
  const [tabs, setTabs] = useState(BAAS_TABS);
  const navigate = useNavigate();
  // const {state} = useLocation();

  useEffect(() => {
    setTabs(BAAS_TABS);
  }, []);

  const params = {
    id,
    // environment: state?.environment || 'live',
    environment: 'live',
  };
  const {data: settlementInfoData, isLoading: settlementInfoDataLoading} =
    useQuery(
      ['settlementInfo-query', {params}],
      () => fetchBaasSettlement({params}),
      {
        enabled: true,
      },
    );

  const settlementData = useMemo(
    () =>
      !settlementInfoDataLoading &&
      settlementInfoData &&
      settlementInfoData?.entity,
    [settlementInfoData, settlementInfoDataLoading],
  );

  const companyInfo = [
    {
      name: 'Company name',
      value: settlementData?.company_name || '-',
    },
    {
      name: 'Account number',
      value: settlementData?.account_number || '-',
    },
    {
      name: 'Bank name',
      value: settlementData?.bank_name || '-',
    },
    {
      name: 'Wallet balance',
      value: formatNumberToCurrency(settlementData?.amount ?? 0),
    },
  ];

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="flex items-center !font-inter gap-2 font-medium text-tiny text-brandBlue"
      >
        <img src={back} alt="" />
        Back to Wallet Settlements
      </button>

      <>
        <div className="flex flex-wrap gap-6 mt-7 sm:flex-nowrap w-fit">
          <div className="relative w-[78px] max-w-[78px] sm:max-w-[140px] sm:w-full flex items-center justify-center max-h-[78px] h-[78px] sm:max-h-[140px] sm:h-[140px] bg-grey80 text-[40px] sm:text-[72px] text-white rounded-full leading-none">
            {settlementInfoDataLoading ? (
              <div className="absolute -top-[3px] rounded-full">
                <SkeletonLoader
                  style={{height: 145, width: 150, borderRadius: '100%'}}
                />
              </div>
            ) : (
              settlementData?.company_name?.substr(0, 1)?.toUpperCase() || '-'
            )}
          </div>
          <div className="flex flex-wrap gap-4">
            {companyInfo?.map(info => (
              <div
                key={info?.name}
                className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit"
              >
                <h3 className="px-3 py-1 rounded-[5px] bg-lighterBlue2 text-brandBlue text-sm w-fit">
                  {info?.name}
                </h3>

                {settlementInfoDataLoading ? (
                  <SkeletonLoader style={{height: 25, width: 150}} />
                ) : (
                  <div className="flex items-center gap-1">
                    <h4 className="text-lg font-bold text-grey80">
                      {info?.value}
                    </h4>
                  </div>
                )}
              </div>
            ))}

            <div className="self-center px-4 font-medium h-fit invisible w-[500px]" />
          </div>
        </div>

        <div className="mt-8 border-b border-borderGrey">
          <div className="flex items-center space-x-4 overflow-x-auto company-nav whitespace-nowrap">
            {tabs.map((tab, index) => (
              <BaasTab id={id} key={index} {...tab} />
            ))}
          </div>
        </div>
      </>

      {children}
      <GoToTop />
    </>
  );
}

export default connect(state => state, null)(CompanyLayout);
