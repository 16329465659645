import React, {useState, useMemo, useEffect} from 'react';
import Select from 'react-select';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {
  DropdownIndicator,
  customStyles,
  Modal,
  PrimaryButton,
} from '../../../components';
import DeactivateMemberModal from './DeactivateMemberModal';
import AssignApps from './AssignApps';
import {
  assignAppsToUsers,
  updateTeamMemberRole,
} from '../../../helpers/queryFns/fetchCompanyDetails';

export default function EditMemberModal({
  open,
  setOpen,
  roles,
  removeTeamMember,
  selectedMember,
  deactivateModal,
  setDeactivateModal,
  removingTeamMember,
}) {
  const {id} = useParams();
  const roleOptions = useMemo(
    () =>
      roles
        ? roles
            .filter(role => role.role !== 'Owner')
            .map(role => ({value: role.id, label: role.role}))
        : null,
    [roles],
  );
  const queryClient = useQueryClient();
  const [selectedRole, setSelectedRole] = useState(
    roleOptions ? roleOptions[0] : null,
  );

  const [selectedApps, setSelectedApps] = useState({});

  const {mutate: editTeamMemberRole, isLoading: editingTeamMemberRole} =
    useMutation(member => updateTeamMemberRole(member), {
      onSuccess: res => {
        toast.success(res.data.message);
        queryClient.invalidateQueries('team-members-query');
        setOpen(false);
      },
      onError: error => {
        const errorMessage = error.response?.data?.error || 'An error occurred';
        toast.error(errorMessage);
      },
    });

  const {mutate: assignApps} = useMutation(user => assignAppsToUsers(user), {
    onSuccess: () => {
      queryClient.invalidateQueries('team-members-query');
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    const assignUserApp = {
      user: selectedMember.user?._id,
      company: id,
      pushApps: selectedApps.pushApps,
      pullApps: selectedApps.pullApps,
    };
    // eslint-disable-next-line no-unused-expressions
    (selectedApps?.pushApps?.length || selectedApps?.pushApps?.length) &&
      assignApps(assignUserApp);

    editTeamMemberRole(
      {
        email: selectedMember.email,
        role: selectedRole.label,
        company: id,
      },
      () => setOpen(false),
    );
  };

  useEffect(() => {
    setSelectedRole({
      value: selectedMember?.role,
      label: selectedMember?.role,
    });
  }, [selectedMember?.role]);

  return (
    <Modal
      modalWidth="581"
      show={open}
      modalTitle="Team member"
      onClose={setOpen}
      extraModalClasses="max-w-[581px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
      noBottomPadding
    >
      <DeactivateMemberModal
        selectedMember={selectedMember}
        removeTeamMember={removeTeamMember}
        deactivateModal={deactivateModal}
        setDeactivateModal={setDeactivateModal}
        removingTeamMember={removingTeamMember}
      />
      <section className="flex flex-col gap-4 mb-8">
        <div className="flex items-center justify-center mt-8 bg-accent-300 w-20 h-20 mx-auto rounded-full">
          <p className="text-brandBlue text-lg font-medium">
            {selectedMember?.user?.first_name
              ? selectedMember?.user?.first_name[0]
              : '-'}
            .
            {selectedMember?.user?.last_name
              ? selectedMember?.user?.last_name[0]
              : '-'}
          </p>
        </div>
        <p className="text-grey text-sm font-medium">
          {selectedMember?.user?.first_name} {selectedMember?.user?.last_name}
        </p>
      </section>

      <form onSubmit={handleSubmit}>
        <div className="bg-white-900 rounded p-6">
          <label
            htmlFor="oi"
            className="flex flex-col items-start max-w-full text-sm label text-grey60"
          >
            Email address
            <input
              type="email"
              id="oi"
              className="max-w-full mt-2 input outline outline-1 outline-grey-100"
              defaultValue={selectedMember?.email}
              placeholder="example@gmail.com"
              required
              disabled
            />
          </label>
          <label
            htmlFor="role"
            className="mt-6 mb-2 flex flex-col items-start max-w-full text-sm label text-grey60"
          >
            Role
          </label>
          <Select
            defaultValue={selectedMember}
            value={selectedRole}
            onChange={setSelectedRole}
            options={roleOptions}
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            styles={customStyles}
            menuPlacement="auto"
            className="text-left"
          />

          <AssignApps
            setAppsToAssign={data => {
              setSelectedApps(data);
            }}
            memberApps={selectedMember?.apps}
          />
        </div>
        <div
          className="-mx-6 mt-10 flex items-center gap-4 p-6 overflow-x-hidden bg-white80"
          style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
        >
          <PrimaryButton
            fontSize="text-sm"
            buttonText="Save changes"
            className="w-full"
            loadingState={editingTeamMemberRole}
            disabled={editingTeamMemberRole}
            type="submit"
          />
          <button
            type="button"
            className="bg-accent-400 rounded h-12 text-sm text-danger500 font-medium p-4 whitespace-nowrap hover:opacity-90"
            onClick={() => setDeactivateModal(true)}
          >
            Deactivate user
          </button>
        </div>
      </form>
    </Modal>
  );
}
