/* eslint-disable no-console */
import * as Helpers from '../../helpers';
import {LOGIN_USER, LOADING, SET_USER} from '../types';

export const loginUser = () =>
  Helpers.api(
    '/admin/login/url',
    'GET',
    null,
    {
      error: err => console.error(err),
      success: data => {
        window.location.href = data?.url;
      },
    },
    {error: 'ERROR', loading: LOADING, responder: 'LOGIN_USER_START'},
  );
export const completeLoginUser = code =>
  Helpers.api(
    '/admin/login/complete',
    'POST',
    {code},
    {
      report: true,
      success: (data, dispatch) => {
        Helpers.token.set(data?.token);

        const userRole = data?.user?.role;
        const isAdmin = userRole.includes('Admin');

        // redirect to pages based on user's role
        if (isAdmin) {
          window.location.href = '/dashboard';
        } else if (
          userRole.includes('Default') ||
          userRole.includes('Marketing')
        ) {
          window.location.href = '/profile';
        } else if (userRole.includes('Compliance')) {
          window.location.href = '/company';
        } else if (userRole.includes('Retention')) {
          window.location.href = '/dashboard';
        } else if (userRole.includes('Reviewer')) {
          window.location.href = '/verifications';
        } else {
          window.location.href = '/dashboard';
        }
        dispatch({
          payload: data?.user,
          type: SET_USER,
        });
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_USER},
  );
export const load = () =>
  Helpers.api(
    '/',
    'GET',
    null,
    {
      report: true,
    },
    {error: 'ERROR', loading: LOADING, responder: LOGIN_USER},
  );
export const logout = () => dispatch => {
  dispatch({type: 'LOGOUT'});
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
};
