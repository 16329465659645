import React, {useState} from 'react';
import {Menu} from '@headlessui/react';
import {useDispatch} from 'react-redux';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {edit, trash, userCheck} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import Dropdown from '../../components/Dropdown';
import Pagination from '../../components/Pagination';
import PrimaryButton from '../../components/PrimaryButton';
import TableLayout from '../../components/TableLayout';
import Modal from '../../components/Modal';
import LayoutContainer from '../../components/LayoutContainer';
import AdminForm from '../../components/AdminForm';
import {SearchInput} from '../../components/SearchInput';
import {
  createAdmin,
  fetchAdminUsers,
  removeAdmin,
  updateAdmin,
} from '../../helpers/queryFns/admin';
import Loader from '../../components/Loader';
import {EmptyState} from '../../components/EmptyState';
import {ADMINS} from '../../store/types';

export default function Admins() {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteAdmin, setDelete] = useState(false);
  const [adminData, setAdminData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [input, setInput] = useState({});
  const [adminRole, setAdminRole] = useState([]);
  const [currentPage, setCurrentPage] = useState('1');
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const pageClick = selected => {
    setCurrentPage(selected);
  };

  // Query for admin users
  const {
    data: adminUsers,
    isLoading,
    isFetching,
  } = useQuery(
    [
      'admin-users',
      {
        filter: searchText,
        page: currentPage,
      },
    ],
    () =>
      fetchAdminUsers({
        filter: searchText,
        page: currentPage,
      }),
    {
      onSuccess: data => {
        dispatch({type: ADMINS, payload: {...data}});
      },
    },
  );

  // Mutation for admin users
  const {mutate: updateAdminUser, isLoading: updatingAdminUser} = useMutation(
    updateAdmin,
    {
      onSuccess: () => {
        toast.success('Admin user updated successfully');
        queryClient.invalidateQueries('admin-users');
        setOpenEdit(false);
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );
  const {mutate: createAdminUser, isLoading: creatingAdminUser} = useMutation(
    createAdmin,
    {
      onSuccess: () => {
        toast.success('Admin user added successfully');
        queryClient.invalidateQueries('admin-users');
        setOpen(false);
        window.location.reload();
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );
  const {mutate: removeAdminUser} = useMutation(removeAdmin, {
    onSuccess: res => {
      toast.success(res.message);
      queryClient.invalidateQueries('admin-users');
    },
  });

  const handleChange = e => {
    const {id, value} = e.target;
    setInput({...input, [id]: value});
  };

  const onAddSubmit = e => {
    e.preventDefault();
    if (adminRole?.length < 1) {
      toast.error('Please select a role');
      return;
    }
    createAdminUser({
      ...input,
      role: adminRole?.map(ar => ar?.value),
      department: 'Product',
      title: 'Product Manager',
    });
  };
  const onEditSubmit = e => {
    e.preventDefault();
    if (adminRole?.length < 1) {
      toast.error('Please select a role');
      return;
    }
    const data = {
      ...adminData,
      ...input,
      role: adminRole?.map(ar => ar?.value),
    };
    delete data._id;
    delete data.suspended;
    updateAdminUser({adminData: data, id: adminData?._id});
  };

  return (
    <DashboardLayout headerText="Admins">
      <LayoutContainer>
        <div className="flex flex-wrap gap-8">
          <div className="flex items-center gap-4 py-4 rounded-lg px-7 w-fit bg-lighterBlue md:gap-8">
            <img src={userCheck} alt="" />
            <div className="flex flex-wrap items-center gap-8">
              <div className="flex flex-col">
                <p className="font-medium uppercase text-tiny text-grey40">
                  Total admins
                </p>
                <h2 className="text-xl font-bold text-grey100">
                  {adminUsers?.totalRecords}
                </h2>
              </div>
              <PrimaryButton
                buttonText="Add admin"
                xPadding="px-8"
                onClick={() => setOpen(!open)}
              />
              {openEdit ? (
                <Modal show={openEdit} onClose={setOpenEdit}>
                  <AdminForm
                    key={adminData?._id}
                    adminRole={adminData?.role?.map(role => ({
                      value: role,
                      label: role,
                    }))}
                    setAdminRole={setAdminRole}
                    adminData={adminData}
                    handleChange={handleChange}
                    formTitle="Edit admin"
                    selectedResponse={role => setInput({...input, role})}
                    disabled={Boolean(updatingAdminUser)}
                    loadingState={updatingAdminUser}
                    onSubmit={onEditSubmit}
                    onClick={() => setOpenEdit(false)}
                  />
                </Modal>
              ) : (
                <Modal show={open} onClose={setOpen}>
                  <AdminForm
                    key="add-admin"
                    adminRole={adminRole}
                    setAdminRole={setAdminRole}
                    formTitle="Add admin"
                    handleChange={handleChange}
                    selectedResponse={role => setInput({...input, role})}
                    disabled={Boolean(creatingAdminUser)}
                    loadingState={creatingAdminUser}
                    onSubmit={onAddSubmit}
                    onClick={() => setOpen(false)}
                    required
                  />
                </Modal>
              )}

              <Modal show={deleteAdmin} onClose={setDelete}>
                <div className="max-w-sm mx-auto sm:my-8">
                  <h2 className="mb-6">Are you sure?</h2>
                  <PrimaryButton
                    className="inline-flex justify-center w-full text-white rounded text-tiny bg-danger"
                    onClick={() => {
                      removeAdminUser(adminData._id);
                      setDelete(false);
                    }}
                    buttonText="Remove admin"
                  />
                  <button
                    type="button"
                    className="inline-flex justify-center w-full mt-6 text-navGrey"
                    onClick={() => setDelete(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between gap-4 mt-8">
          <SearchInput
            placeholder="Search users"
            onChange={text => setSearchText(text)}
          />
        </div>
        {isLoading ? (
          <Loader height={45} />
        ) : (
          <div className="mt-3">
            {!adminUsers?.totalRecords ? (
              <EmptyState
                title="No admin listed"
                body="No admin user is listed yet"
              />
            ) : (
              <TableLayout>
                <thead className="text-xs font-semibold uppercase">
                  <tr className="border-b border-tableGrey">
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      First name
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Last name
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Email
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      Admin role
                    </th>
                    <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                      status
                    </th>
                    <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap">
                      action
                    </th>
                  </tr>
                </thead>
                <tbody
                  className="text-sm divide-y divide-gray-100"
                  style={{opacity: isFetching && 0.3}}
                >
                  {adminUsers?.users?.map(admin => (
                    <tr className="border-b border-tableGrey" key={admin?._id}>
                      <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                        {admin?.first_name}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {admin?.last_name}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {admin?.email}
                      </td>
                      <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                        {Array.isArray(admin?.role)
                          ? admin?.role.join(', ')
                          : admin?.role}
                      </td>
                      <td
                        className={`p-3 text-left text-tiny text-${
                          admin?.suspended ? 'danger' : 'success'
                        } whitespace-nowrap`}
                      >
                        {admin?.suspended ? 'Suspended' : 'Active'}
                      </td>
                      <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                        <div className="flex justify-center">
                          <Dropdown>
                            <Menu.Item>
                              {() => (
                                <button
                                  className="z-40 flex items-center gap-4 font-medium text-gray-500 text-tiny"
                                  onClick={() => {
                                    setOpenEdit(!open);

                                    setAdminData(admin);
                                  }}
                                >
                                  <img src={edit} alt="" />
                                  Edit admin
                                </button>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {() => (
                                <button
                                  className="z-40 flex items-center gap-4 font-medium text-tiny text-danger"
                                  onClick={() => {
                                    setAdminData(admin);
                                    setDelete(!deleteAdmin);
                                  }}
                                >
                                  <img src={trash} alt="" />
                                  Remove admin
                                </button>
                              )}
                            </Menu.Item>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableLayout>
            )}

            <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
              <Pagination
                total={(adminUsers && adminUsers?.totalPages) || 0}
                current={+currentPage}
                onPageChange={activePage => {
                  pageClick(activePage);
                }}
              />
            </div>
          </div>
        )}
      </LayoutContainer>
    </DashboardLayout>
  );
}
