import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {useQuery} from 'react-query';
import {fetchCompanies} from '../../../helpers/queryFns/company';
import {customStyles, DropdownIndicator} from '../../../components';
import {sortObjectsAlphabetically} from '../../../helpers/sortAlphabetically';

export function PartnerCompanies({settings, setSettings, open, inputKey}) {
  const [companyData, setCompanyData] = useState([]);
  const {data, isLoading, isFetching} = useQuery(
    ['company-data', {limit: 100000, page: 1}],
    () => fetchCompanies({limit: 100000, page: 1}),
  );

  useEffect(() => {
    if (!isLoading || !isFetching) {
      const formattedData = data?.companies?.map(company => ({
        label: company?.name,
        value: company?.id,
      }));
      setCompanyData(sortObjectsAlphabetically(formattedData, 'label'));
    }
  }, [data?.companies, isFetching, isLoading]);

  const currentParentCompany = companyData.find(
    item => item.value === settings?.[inputKey],
  );

  return (
    <div>
      {open && (
        <div className="my-3">
          <label className="flex items-start text-left text-sm label text-grey60 mb-1">
            Select parent company
          </label>

          <Select
            defaultValue={(!isLoading || !isFetching) && companyData[0]}
            value={currentParentCompany || settings?.[inputKey]}
            onChange={partner =>
              setSettings({...settings, [inputKey]: partner})
            }
            options={companyData}
            components={{DropdownIndicator}}
            classNamePrefix="react-select"
            styles={customStyles}
            menuPlacement="top"
            className="text-left"
            placeholder="Select partner company"
          />
        </div>
      )}
    </div>
  );
}
