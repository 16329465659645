import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function Wallet({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const [walletCreate, setWalletCreate] = useState('');
  const [walletNgnCreate, setWalletNgnCreate] = useState('');
  const [walletNgnCollectionCap, setWalletNgnCollectionCap] = useState('');
  const [walletNgnTransfer, setWalletNgnTransfer] = useState('');
  const [walletNgnCollection, setWalletNgnCollection] = useState('');

  const {id} = useParams();

  const walletServicesQuery = useQuery(['wallet-services-query', id], () =>
    getCompanyServices(id),
  );

  const url = walletServicesQuery?.data?.pricing;

  const getWalletServicesQuery = React.useCallback(() => {
    setWalletCreate(url?.wallet_create / 100);
    setWalletNgnCreate(url?.wallet_ngn_create / 100);
    setWalletNgnCollectionCap(url?.wallet_ngn_collection_cap / 100);
    setWalletNgnTransfer(url?.wallet_ngn_transfer / 100);
    setWalletNgnCollection(url?.wallet_ngn_collection / 100);
  }, [
    url?.wallet_create,
    url?.wallet_ngn_collection,
    url?.wallet_ngn_collection_cap,
    url?.wallet_ngn_create,
    url?.wallet_ngn_transfer,
  ]);

  useEffect(() => {
    getWalletServicesQuery();
  }, [getWalletServicesQuery]);

  return (
    <>
      {walletServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">Wallet</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="wallet_create"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Wallet create
                    <input
                      id="wallet_create"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={walletCreate}
                      onChange={e =>
                        onUpdate({wallet_create: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="wallet_ngn_create"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Wallet NGN create
                    <input
                      id="wallet_ngn_create"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={walletNgnCreate}
                      onChange={e =>
                        onUpdate({wallet_ngn_create: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="wallet_collection_cap"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Wallet collection cap
                    <input
                      id="wallet_collection_cap"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={walletNgnCollectionCap}
                      onChange={e =>
                        onUpdate({
                          wallet_ngn_collection_cap: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="wallet_ngn_transfer"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Wallet NGN transfer
                    <input
                      id="wallet_ngn_transfer"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={walletNgnTransfer}
                      onChange={e =>
                        onUpdate({wallet_ngn_transfer: e.target.value * 100})
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="wallet_collection"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Wallet collection
                    <input
                      id="wallet_collection"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={walletNgnCollection}
                      onChange={e =>
                        onUpdate({wallet_ngn_collection: e.target.value * 100})
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
