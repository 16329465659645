import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {favicon} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import TableLayout from '../../components/TableLayout';
import CompanyLayout from './CompanyLayout';
import DateRangeFilter from '../../components/DateFilter';
import {getCompanyWidgets} from '../../helpers/queryFns/fetchCompanyDetails';
import Loader from '../../components/Loader';
import GoToTop from '../../components/GoToTop';
import {EmptyState} from '../../components/EmptyState';

export default function CompanyWidget() {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');
  const [{start, end}, setDateFilters] = useState({});

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const widgetQuery = useQuery(
    ['widget-query', {end, page: currentPage, start}],
    () => getCompanyWidgets(id, {end, page: currentPage, start}),
  );

  const widgetBaseUrl =
    process.env.REACT_APP_WIDGET_BASE_URL ||
    'https://services_staging.dojah.io';

  return (
    <DashboardLayout className="mt-4" headerText="Company/Widget">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5 mb-3">
            {widgetQuery.isLoading ? (
              <Loader />
            ) : (
              <>
                {!widgetQuery?.data?.totalRecords ? (
                  <EmptyState
                    height={45}
                    title="No widget listed"
                    body="No widget is listed yet"
                  />
                ) : (
                  <>
                    <div className="flex flex-wrap items-center justify-end gap-4">
                      <h4 className="font-medium text-tiny text-grey60">
                        Filter by:
                      </h4>
                      <DateRangeFilter
                        onChange={date => setDateFilters(date)}
                      />
                    </div>
                    <TableLayout>
                      <thead className="text-xs font-semibold uppercase">
                        <tr className="border-b border-tableGrey">
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            widget link
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            app name
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            records
                          </th>
                          <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                            date created
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {widgetQuery.data?.widgets.map((widget, i) => (
                          <tr className="border-b border-tableGrey" key={i}>
                            <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                              {`${widgetBaseUrl}.widget_id=${id}`}
                            </td>
                            <td className="flex items-center gap-2 p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              <img src={favicon} alt="" />
                              {widget.app.name ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {widget.app.records ?? '-'}
                            </td>
                            <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                              {moment(widget.createdAt).format(
                                'Do MMM, YYYY',
                              ) ?? '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TableLayout>
                  </>
                )}
              </>
            )}
          </div>

          <div className="mt-4 mb-8">
            <Pagination
              total={widgetQuery?.data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
            {widgetQuery.isFetching ? (
              <p className="mt-4 border-none">Updating...</p>
            ) : widgetQuery.isError ? (
              <p className="mt-4 border-none">
                An error occurred while updating
              </p>
            ) : null}
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
