/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from 'react';
import {search} from '../assets/images/images';
import {debounceFunction} from '../helpers/debounceFunction';

export function SearchInput({onChange, placeholder, defaultValue = ''}) {
  const [text, setSearchText] = useState('');

  const handleChange = e => {
    setSearchText(e.target.value);
  };

  const debounceChange = useCallback(
    debounceFunction(e => {
      handleChange(e);
      onChange(e.target.value);
    }, 1000),
    [text],
  );

  return (
    <label
      htmlFor="search"
      className="relative label flex flex-wrap items-center"
    >
      <input
        name="search"
        placeholder={placeholder || 'Search list'}
        className="input"
        onChange={debounceChange}
        defaultValue={defaultValue}
      />
      <img src={search} alt="" className="absolute top-4 right-4" />
    </label>
  );
}
