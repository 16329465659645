import React, {useState} from 'react';
import {Menu} from '@headlessui/react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import CompanyLayout from './CompanyLayout';
import Dropdown from '../../components/Dropdown';
import TableLayout from '../../components/TableLayout';
import {changeRole, trash} from '../../assets/images/images';
import Loader from '../../components/Loader';
import {getCompanyUsers} from '../../helpers/queryFns/fetchCompanyDetails';
import GoToTop from '../../components/GoToTop';
import Pagination from '../../components/Pagination';
import {EmptyState} from '../../components/EmptyState';

export default function CompanyUsers() {
  const {id} = useParams();
  const [currentPage, setCurrentPage] = useState('1');

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const usersQuery = useQuery(['users-query', id], () =>
    getCompanyUsers(id, {page: currentPage}),
  );

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <DashboardLayout className="mt-4" headerText="Company/Users">
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5">
            {usersQuery.isLoading ? (
              <Loader />
            ) : (
              <>
                {!usersQuery.data.totalRecords ? (
                  <EmptyState
                    height={45}
                    title="No user listed"
                    body="No user is listed yet"
                  />
                ) : (
                  <TableLayout>
                    <thead className="text-xs font-semibold uppercase">
                      <tr className="border-b border-tableGrey">
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          First name
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          Last name
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          Email
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          Phone
                        </th>
                        <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                          status
                        </th>
                        <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap">
                          action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {usersQuery.data.users.map((user, i) => (
                        <tr className="border-b border-tableGrey" key={i}>
                          <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                            {user.first_name ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {user.last_name ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {user.email ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                            {user.mobile ?? '-'}
                          </td>
                          <td className="p-3 text-left text-tiny text-success whitespace-nowrap">
                            {user.status ?? '-'}
                          </td>
                          <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                            <div className="flex justify-center">
                              <Dropdown>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      href="#"
                                      className={classNames(
                                        active ? 'text-grey60' : 'text-grey60',
                                        'flex items-center gap-4 text-tiny font-medium',
                                      )}
                                    >
                                      <img src={changeRole} alt="" />
                                      Change role
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({active}) => (
                                    <button
                                      href="#"
                                      className={classNames(
                                        active ? 'text-danger' : 'text-danger',
                                        'flex items-center gap-4 text-tiny font-medium',
                                      )}
                                    >
                                      <img src={trash} alt="" />
                                      Suspend user
                                    </button>
                                  )}
                                </Menu.Item>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </TableLayout>
                )}
              </>
            )}
          </div>
          <div className="mt-4 mb-8">
            <Pagination
              total={usersQuery?.data?.totalPages ?? 0}
              current={+currentPage}
              onPageChange={activePage => {
                pageClick(activePage);
              }}
            />
            {usersQuery.isFetching ? (
              <p className="border-none mt-4">Updating...</p>
            ) : null}
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
