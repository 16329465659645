import React, {useCallback, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import moment from 'moment';
import DashboardLayout from '../../../components/DashboardLayout';
import LayoutContainer from '../../../components/LayoutContainer';
import CompanyLayout from '../CompanyLayout';
import TableLayout from '../../../components/TableLayout';
import {edit, eye} from '../../../assets/images/images';
import Loader from '../../../components/Loader';
import {
  deactivateTeamMember,
  getCompanyTeamMembers,
  getCompanyTeamRoles,
  inviteMemberToCompany,
} from '../../../helpers/queryFns/fetchCompanyDetails';
import GoToTop from '../../../components/GoToTop';
import Pagination from '../../../components/Pagination';
import {EmptyState} from '../../../components/EmptyState';
import {SearchInput} from '../../../components/SearchInput';
import {FilterButton, PrimaryButton} from '../../../components';
import InviteMemberModal from './InviteMemberModal';
import EditMemberModal from './EditMemberModal';
import ViewMemberModal from './ViewMemberModal';
import TransferOwnershipModal from './TransferOwnershipModal';
import TeamFilter from './TeamFilter';

export default function CompanyTeams() {
  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const query = new URLSearchParams(location?.search);
  const page = Number(query.get('page'));
  const searchFilter = query.get('q');
  const [searchText, setSearchText] = useState(searchFilter);
  const [filters, setFilters] = useState({
    status: '',
  });
  const [openFilter, setOpenFilter] = useState(false);

  const handleChangeCurrentPage = e => {
    navigate(`?page=${e}`);
  };

  const teamMembersQuery = useQuery(
    [
      'team-members-query',
      id,
      {
        filter: searchText,
        page: page === 0 ? 1 : page,
      },
    ],
    () =>
      getCompanyTeamMembers(id, {
        filter: searchText,
        page: page === 0 ? 1 : page,
        status: filters?.status,
      }),
  );

  const handleFilter = () => {
    teamMembersQuery.refetch();
  };

  const handleResetFilter = () => {
    setFilters({
      status: '',
    });
    teamMembersQuery.refetch();
  };

  const teamRoles = useQuery(['team-roles', id], () => getCompanyTeamRoles(id));

  const roles = teamRoles?.data?.roles;

  const [inviteMemberModal, setInviteMemberModal] = useState(false);
  const [editMemberModal, setEditMemberModal] = useState(false);
  const [viewMemberModal, setViewMemberModal] = useState(false);
  const [transferOwnershipModal, setTransferOwnershipModal] = useState(false);
  const [deactivateMemberModal, setDeactivateMemberModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const diffInHours = useCallback(
    (date1, date2) => Math.abs(date1 - date2) / 36e5,
    [],
  );

  const {mutate: inviteMember, isLoading: invitingMember} = useMutation(
    member => inviteMemberToCompany(member),
    {
      onSuccess: res => {
        toast.success(res.data.message);
        queryClient.invalidateQueries('team-members-query');
        setInviteMemberModal(false);
      },
      onError: error => {
        const errorMessage = error.response?.data?.error || 'An error occurred';
        toast.error(errorMessage);
      },
    },
  );

  const takeAction = member => {
    if (
      member.status !== 'active' &&
      diffInHours(new Date(member.createdAt), new Date()) > 24
    ) {
      inviteMember(
        {
          email: member.email,
          role: member.permissions?.role,
          company: id,
        },
        () => setInviteMemberModal(false),
      );
    } else {
      setSelectedMember({
        id: member._id,
        email: member.email,
        role: member.permissions?.role,
        user: member.user,
        apps: member.apps,
      });
      setEditMemberModal(!editMemberModal);
    }
  };

  const {mutate: removeTeamMember, isLoading: removingTeamMember} = useMutation(
    info => deactivateTeamMember(info),
    {
      onSuccess: res => {
        toast.success(res.data.message);
        queryClient.invalidateQueries('team-members-query');
        setDeactivateMemberModal(false);
        setEditMemberModal(false);
        setViewMemberModal(false);
      },
      onError: error => {
        const errorMessage = error.response?.data?.error || 'An error occurred';
        toast.error(errorMessage);
      },
    },
  );

  return (
    <DashboardLayout className="mt-4" headerText="Company/Users">
      <InviteMemberModal
        open={inviteMemberModal}
        setOpen={setInviteMemberModal}
        roles={roles}
        inviteMember={inviteMember}
        invitingMember={invitingMember}
      />
      <EditMemberModal
        open={editMemberModal}
        setOpen={setEditMemberModal}
        roles={roles}
        selectedMember={selectedMember}
        deactivateModal={deactivateMemberModal}
        setDeactivateModal={setDeactivateMemberModal}
        removeTeamMember={removeTeamMember}
        removingTeamMember={removingTeamMember}
      />
      <ViewMemberModal
        open={viewMemberModal}
        setOpen={setViewMemberModal}
        selectedMember={selectedMember}
        takeAction={takeAction}
        deactivateModal={deactivateMemberModal}
        setDeactivateModal={setDeactivateMemberModal}
        removeTeamMember={removeTeamMember}
        removingTeamMember={removingTeamMember}
      />
      <TransferOwnershipModal
        open={transferOwnershipModal}
        setOpen={setTransferOwnershipModal}
      />
      <TeamFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        appFilters={filters}
        onCheck={data => setFilters({...filters, ...data})}
        handleFilter={handleFilter}
        resetFilters={handleResetFilter}
      />
      <LayoutContainer>
        <CompanyLayout>
          <div className="mt-5">
            <div>
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-5 w-full">
                  <SearchInput
                    onChange={text => {
                      setSearchText(text);
                      navigate(`?q=${text}`);
                    }}
                  />
                  <FilterButton
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                  />
                </div>
                <div className="flex items-center gap-4 w-full justify-end">
                  <PrimaryButton
                    buttonText="Invite a member"
                    xPadding="px-4"
                    fontSize="text-sm"
                    onClick={() => setInviteMemberModal(true)}
                  />
                  <button
                    className="px-4 pt-[18px] pb-[14px] bg-white-900 text-brandBlue rounded text-sm font-medium"
                    onClick={() => setTransferOwnershipModal(true)}
                  >
                    Transfer ownership
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {teamMembersQuery.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {!teamMembersQuery?.data?.totalRecords ? (
                      <EmptyState
                        height={45}
                        title="No user listed"
                        body="No user is listed yet"
                      />
                    ) : (
                      <TableLayout>
                        <thead className="text-xs font-semibold uppercase">
                          <tr className="border-b border-tableGrey">
                            <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                              Name of team member
                            </th>
                            <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                              Email address
                            </th>
                            <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                              Role
                            </th>
                            <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                              Date added
                            </th>
                            <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                              status
                            </th>
                            <th className="p-3 font-medium text-tiny text-grey60 whitespace-nowrap" />
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-100">
                          {teamMembersQuery?.data?.team?.map(member => (
                            <tr
                              key={member?._id}
                              className="border-b border-tableGrey"
                            >
                              <td className="p-3 text-sm text-grey80 whitespace-nowrap">
                                {member.user?.first_name
                                  ? `${member.user.first_name} ${member.user?.last_name}`
                                  : '-'}
                              </td>
                              <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                                {member?.email}
                              </td>
                              <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                                {member?.permissions.role}
                              </td>
                              <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                                {moment(member?.createdAt).format(
                                  'Do MMM, YYYY',
                                ) || '-'}
                              </td>
                              <td className="p-3 text-left text-xs whitespace-nowrap">
                                <div
                                  className={`flex items-center justify-center uppercase px-2 pt-[6px] pb-[2px] font-medium w-fit rounded-full ${
                                    member?.status !== 'active'
                                      ? diffInHours(
                                          new Date(member?.createdAt),
                                          new Date(),
                                        ) > 24
                                        ? 'text-accent-400 bg-danger200'
                                        : 'text-accent-300 bg-darkBlue'
                                      : 'bg-success400 text-accent-200'
                                  }`}
                                >
                                  {member?.status !== 'active'
                                    ? diffInHours(
                                        new Date(member?.createdAt),
                                        new Date(),
                                      ) > 24
                                      ? 'expired'
                                      : 'pending'
                                    : member?.status}
                                </div>
                              </td>
                              <td className="p-3 text-sm text-center text-grey80 whitespace-nowrap">
                                <div className="flex items-center gap-4 justify-center">
                                  <button
                                    title="View"
                                    aria-label="View"
                                    onClick={() => {
                                      setViewMemberModal(true);
                                      setSelectedMember(member);
                                    }}
                                  >
                                    <img
                                      src={eye}
                                      alt="View"
                                      width={16}
                                      height={16}
                                    />
                                  </button>
                                  <button
                                    title="Edit"
                                    aria-label="Edit"
                                    onClick={() => {
                                      takeAction(member);
                                    }}
                                  >
                                    <img
                                      src={edit}
                                      alt="Edit"
                                      width={16}
                                      height={16}
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </TableLayout>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 mb-8">
            <Pagination
              total={teamMembersQuery?.data?.totalPages ?? 0}
              current={page}
              onPageChange={handleChangeCurrentPage}
            />
            {teamMembersQuery.isFetching ? (
              <p className="border-none mt-4">Updating...</p>
            ) : null}
          </div>
        </CompanyLayout>
      </LayoutContainer>
      <GoToTop />
    </DashboardLayout>
  );
}
