import React, {useMemo, useState} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {enlargeIc, eye, placeholder} from '../../assets/images/images';
import {
  DashboardLayout,
  LayoutContainer,
  Modal,
  PrimaryButton,
} from '../../components';
import {mutateCompany, updateCompany} from '../../helpers/queryFns/company';
import CompanyLayout from './CompanyLayout';
import SingleDropdown from '../../components/SingleDropdown';

function ViewDocument({url}) {
  return (
    <a
      href={url || ''}
      target="_blank"
      className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white-900 w-fit"
      rel="noreferrer"
    >
      <span>View document</span>
      <img src={eye} alt="" className="-mt-[2px]" />
    </a>
  );
}

function RowData({leftText, rightText, yPadding = 'py-4'}) {
  return (
    <div
      className={`flex items-center justify-between px-4 ${yPadding} border-t border-grey-100 last:border-y md:px-0`}
    >
      <span className="text-xs font-medium uppercase text-grey">
        {leftText}
      </span>
      <span className="text-sm text-body">{rightText}</span>
    </div>
  );
}

function CompanyCompliance({company: {company_info}, auth: {user}}) {
  const COMPANY = company_info?.company;

  const dropdownData = useMemo(
    () => [
      {label: COMPANY?.approved ? 'Decline' : 'Ready for Verification'},
      {label: 'Compliance Check'},
    ],
    [COMPANY],
  );

  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const {mutate: verifyCompany, isLoading} = useMutation(mutateCompany, {
    onSuccess: data => {
      toast.success(
        data?.message || 'Yikes!! Company status updated successfully!',
      );
      setOpen(false);
      queryClient.invalidateQueries('companyInfo-query');
    },
  });
  const {mutate: updateCompanyInfo, isLoading: updateLoading} = useMutation(
    updateCompany,
    {
      onSuccess: data => {
        toast.success(data?.message || 'Company status updated successfully!');
        setOpen(false);
        queryClient.invalidateQueries('companyInfo-query');
      },
      onError: error => toast.error(error.response?.data?.error),
    },
  );

  const resizeImage = (id, enlarge) => {
    const img = document.getElementById(id);

    if (enlarge) {
      img.classList.remove('max-h-[192px]');
    } else {
      img.classList.add('max-h-[192px]');
    }
  };

  const DOCUMENT_DATA = useMemo(
    () => [
      {leftText: 'CAC', rightText: <ViewDocument url={COMPANY?.sla_id} />},
      {leftText: 'Industry', rightText: COMPANY?.industry || '-'},
      {leftText: 'Website', rightText: COMPANY?.website || '-'},
    ],
    [COMPANY],
  );

  const COMPANY_DATA = useMemo(
    () => [
      {leftText: 'Company Name', rightText: COMPANY?.name || '-'},
      {
        leftText: 'COUNTRY',
        rightText: COMPANY.country?.toUpperCase() || '-',
      },
      {leftText: 'State', rightText: COMPANY?.state || '-'},
      {leftText: 'Address', rightText: COMPANY?.address || '-'},
    ],
    [COMPANY],
  );

  const SLA_SIGNEE_DATA = useMemo(
    () => [
      {leftText: 'NAME OF SLA', rightText: COMPANY?.sla || '-'},
      {leftText: 'RC Number', rightText: COMPANY?.rc_number || '-'},
    ],
    [COMPANY],
  );

  return (
    <DashboardLayout className="mt-8" headerText="Company/Compliance">
      <LayoutContainer>
        <CompanyLayout>
          <div className="bg-white-900 pt-8 pb-20 flex items-stretch gap-16 basis-full">
            <div className="bg-white py-10 px-8 basis-[50.79%]">
              <div>
                <p className="text-grey font-medium mb-6">Company Data</p>

                {COMPANY_DATA.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
              <Modal show={open} onClose={setOpen} modalTitle="Change status">
                <div className=" mx-auto sm:my-8">
                  <label className="flex items-start mb-1 text-sm text-left label pb-3 text-grey60">
                    Status
                  </label>
                  <SingleDropdown
                    dropdownData={dropdownData}
                    selected={selected}
                    setSelected={setSelected}
                    spanText={selected?.label ?? '-'}
                    width="w-full"
                    styles={{height: 148, overflowY: 'scroll'}}
                  />
                  {/* <SingleDropdown
                    dropdownData={dropdownData}
                    selected={selected}
                    setSelected={setSelected}
                    spanText={selected?.label ?? '-'}
                    width="w-full"
                    styles={{height: 148, overflowY: 'scroll'}}
                  /> */}

                  <PrimaryButton
                    className="inline-flex justify-center w-full mt-12 text-white rounded text-tiny bg-brandBlue"
                    buttonText="Save"
                    // disabled={topUpError || toppingUpWallet}
                    loadingState={isLoading || updateLoading}
                    onClick={() => {
                      const updateData = {
                        company_name: COMPANY?.name,
                        company_address: COMPANY?.address,
                        sms_route: COMPANY?.sms_route,
                        currency: COMPANY?.currency,
                        rc_number: COMPANY?.rc_number,
                      };
                      if (
                        selected.label === 'Approve' ||
                        selected.label === 'Decline'
                      ) {
                        return verifyCompany({
                          company_id: COMPANY?._id,
                          type: COMPANY?.approved ? 'decline' : 'approve',
                        });
                      }

                      return updateCompanyInfo({
                        company_id: COMPANY?._id,
                        data: {
                          ...updateData,
                          verificationInProgress:
                            !COMPANY?.verificationInProgress,
                        },
                      });
                    }}
                  />
                </div>
              </Modal>

              <div className="mt-40">
                <p className="text-grey font-medium mb-6">ID of SLA Signee</p>

                <div className="relative mb-6">
                  <img
                    src={COMPANY?.sla_id || placeholder}
                    alt=""
                    className={`w-full mt-6 object-cover ${
                      COMPANY?.sla_id ? 'max-h-[192px]' : 'max-w-[292px]'
                    } `}
                    id="id-url"
                  />
                  {COMPANY?.sla_id && (
                    <div className="absolute flex items-center gap-2 right-2 bottom-2">
                      <button
                        onBlur={() => resizeImage('id-url')}
                        onClick={() => resizeImage('id-url', true)}
                        className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white-900 w-fit"
                      >
                        <span className="-mb-[2px]">Enlarge image</span>
                        <img src={enlargeIc} alt="" className="-mt-[2px]" />
                      </button>
                      <a
                        href={COMPANY?.sla_id || ''}
                        target="_blank"
                        className="flex items-start gap-2 px-2 py-2 text-xs font-medium rounded text-grey bg-white-900 w-fit"
                        rel="noreferrer"
                      >
                        <span>View image</span>
                        <img src={eye} alt="" className="-mt-[2px]" />
                      </a>
                    </div>
                  )}
                </div>
                {SLA_SIGNEE_DATA.map(({leftText, rightText}, i) => (
                  <RowData key={i} leftText={leftText} rightText={rightText} />
                ))}
              </div>
            </div>
            <div className="bg-white py-10 px-8 basis-[49.21%]">
              <div className="flex items-center justify-between">
                <div
                  className={`px-2 pt-[5px] pb-[3px] text-xs font-medium uppercase rounded-full text-white ${
                    COMPANY?.verified
                      ? 'bg-accent-500'
                      : COMPANY?.approved
                      ? 'bg-brandBlue'
                      : COMPANY?.verificationDeclined
                      ? 'bg-danger'
                      : COMPANY?.verificationInProgress
                      ? 'bg-darkRed'
                      : COMPANY?.hasRequestedVerification
                      ? 'bg-grey-200'
                      : 'bg-danger'
                  } w-fit`}
                >
                  {COMPANY?.verified
                    ? 'verified'
                    : COMPANY?.approved
                    ? 'Ready for Verification'
                    : COMPANY?.verificationDeclined
                    ? 'Verification failed'
                    : COMPANY?.verificationInProgress
                    ? 'Compliance Check'
                    : COMPANY?.hasRequestedVerification
                    ? 'Verification Requested'
                    : 'unverified'}
                </div>
                <PrimaryButton
                  buttonText="Change status"
                  xPadding="px-2"
                  yPadding="py-2"
                  fontSize="text-xs"
                  className="font-medium"
                  onClick={() =>
                    // verifyCompany({
                    //   company_id: COMPANY?._id,
                    //   type: COMPANY?.approved ? 'decline' : 'approve',
                    // })
                    setOpen(true)
                  }
                  style={{height: 30, width: 100}}
                  disabled={
                    user?.role?.includes('Retention') &&
                    !user?.role?.includes('Admin')
                  }
                />
              </div>

              <div>
                <p className="mt-4 text-grey font-medium mb-6">Document Data</p>

                {DOCUMENT_DATA.map(({leftText, rightText}, i) => (
                  <RowData
                    yPadding="py-[10px]"
                    key={i}
                    leftText={leftText}
                    rightText={rightText}
                  />
                ))}
              </div>
            </div>
          </div>
        </CompanyLayout>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default connect(state => state, null)(CompanyCompliance);
