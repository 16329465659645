import axiosLib from 'axios';
import {toast} from 'react-toastify';
import token from './token';

let axios = axiosLib;
const api =
  (url, method, data, {success, error, updated}, actionTypes) =>
  (dispatch, mockAxios) => {
    const {responder, loading, error: errorConstant, report} = actionTypes;
    if (mockAxios.get) axios = mockAxios;

    dispatch({
      payload: url,
      type: loading,
    });
    axios.defaults.headers.common.Authorization = `Bearer ${token.get()}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // console.log('api', token.get());
    return axios[method.toLowerCase()](
      `${
        process.env.REACT_APP_BASE_URL || 'https://services_staging.dojah.io'
      }${url}`,
      data,
    )
      .then(res => {
        dispatch({
          payload: '',
          type: loading,
        });
        dispatch({
          payload: res.data,
          type: responder,
        });

        if (updated) {
          window.location.reload();
        }

        if (typeof success === 'function' && report !== false) {
          success(res.data, dispatch);
        }

        return true;
      })
      .catch(e => {
        dispatch({
          payload: '',
          type: loading,
        });
        dispatch({
          errorObj: e,
          payload: e.response && e.response.data,
          type: errorConstant,
        });
        toast.error(e?.response?.data?.error);

        if (typeof error === 'function' && report !== false) {
          error(e.response && e.response.data, dispatch);
        }

        if (
          e.response &&
          e.response.status === 401 &&
          (e.response.data.error.message === 'jwt expired' ||
            e.response.data.error.message === 'jwt malfomed')
        ) {
          token.remove();
          window.location = '/auth';
        }

        return false;
      });
  };

export default api;
