import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import {css} from '@emotion/react';

export default function TertiaryButton({
  buttonText,
  xPadding = 'px-4',
  yPadding = 'pt-[18px] pb-[14px]',
  className = '',
  fontSize = 'text-sm',
  onClick,
  loading,
  color,
  background,
  ...props
}) {
  const override = css`
    border-color: #ffffff;
    position: absolute;
    background: transparent;
  `;

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${className} ${
        loading ? 'flex items-center justify-center relative' : ''
      } bg-danger text-white ${fontSize} font-medium max-h-12 hover:bg-opacity-90 duration-200 transition rounded ${xPadding} ${yPadding}`}
      style={{
        ...(color && {color}),
        ...(background && {background}),
        ...(props?.style && props.style),
      }}
      {...props}
    >
      {loading ? (
        <PuffLoader css={override} size={35} color={color || '#ffffff'} />
      ) : null}
      {buttonText}
    </button>
  );
}
