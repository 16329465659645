import {
  LOADING,
  ALL_COMPANIES,
  COMPANY_INFO,
  COMPANY_INFO_SETTINGS,
} from '../types';

const initialState = {
  all_companies: null,
  company_info: null,
  settings: null,
  loading: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {...state, loading: action.payload};
    case COMPANY_INFO_SETTINGS:
      return {...state, settings: action.payload};
    case ALL_COMPANIES:
      return {...state, all_companies: action.payload};
    case COMPANY_INFO:
      return {...state, company_info: action.payload};
    default:
      return state;
  }
}
