import React from 'react';

export default function RowData({
  leftText,
  rightText,
  bottomBorderOnly = false,
  xPadding = false,
  bolderText = false,
  smallerText = false,
  customUI,
  alignStart = false,
  editable,
  onContentChange,
}) {
  const handleGenderChange = () => {};

  const renderRightComponent = () => {
    if (leftText === 'gender') {
      if (editable) {
        return (
          <select
            className="p-2 rounded text-sm text-body outline outline-1 outline-[#DEDEE0] bg-white-900 h-[27.5px] w-20"
            defaultValue={rightText}
            onChange={e => handleGenderChange(e.target.value)}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        );
      }
    }
    if (rightText) {
      return (
        <span
          className={`text-sm text-body ${
            editable
              ? 'outline outline-1 outline-[#DEDEE0] bg-white-900 p-2 rounded'
              : ''
          } ${bolderText ? 'font-medium' : ''} ${
            smallerText ? 'text-small' : ''
          }`}
          contentEditable={editable ? 'true' : 'false'}
          suppressContentEditableWarning
          onInput={onContentChange}
          onBlur={onContentChange}
        >
          {rightText}
        </span>
      );
    }
    return customUI;
  };

  return (
    <div
      className={`flex justify-between whitespace-nowrap px-4 py-4 border-grey-100 last:border-y ${
        bottomBorderOnly ? 'border-b' : 'border-t'
      } ${xPadding ? 'md:px-6' : 'md:px-0'} ${
        alignStart ? 'items-start gap-10' : 'items-center'
      }`}
    >
      <span className="text-xs font-medium uppercase text-grey">
        {leftText}
      </span>
      {renderRightComponent()}
    </div>
  );
}
