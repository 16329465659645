import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {loadState} from '../helpers/persistState';

import reducers from './reducers';

const persistedState = loadState();
const middleware = [thunk, createLogger()];
const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(...middleware),
);

export default store;
