import React from 'react';
import {toast} from 'react-toastify';
import api from '../../helpers/queryFns/api';
import {handleFileInput} from '../../helpers';

export default function GuestCollaborators({
  guestCollaborators,
  setGuestCollaborators,
  setBlogData,
}) {
  const addGuestCollaborator = () => {
    setGuestCollaborators(prevState => [
      ...prevState,
      {
        name: '',
        image: '',
        imageFile: null,
        isGeneratingImgUrl: false,
      },
    ]);
  };

  const removeGuestCollaborator = index => {
    if (guestCollaborators.length > 1) {
      setGuestCollaborators(prevState =>
        prevState.filter((_, i) => i !== index),
      );
    }
  };

  const handleGuestCollaboratorChange = (name, value, index) => {
    setGuestCollaborators(prevState => {
      const newState = [...prevState];
      newState[index][name] = value;
      return newState;
    });

    setBlogData(prevState => ({
      ...prevState,
      guest_collaborators: [...guestCollaborators],
    }));
  };

  const uploadGuestImage = async (base64String, index) => {
    setGuestCollaborators(prevState => {
      const newState = [...prevState];
      const updatedGuestCollaborator = newState.map((collaborator, i) => {
        if (i === index) {
          return {
            ...collaborator,
            isGeneratingImgUrl: true,
          };
        }
        return collaborator;
      });
      return updatedGuestCollaborator;
    });

    try {
      const response = await api.post('/blog/upload', {image: base64String});
      const res = response.data.image;

      setGuestCollaborators(prevState => {
        const newState = [...prevState];
        const updatedGuestCollaborator = newState.map((collaborator, i) => {
          if (i === index) {
            return {
              ...collaborator,
              image: res,
              isGeneratingImgUrl: false,
            };
          }
          return collaborator;
        });
        return updatedGuestCollaborator;
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);

      setGuestCollaborators(prevState => {
        const newState = [...prevState];
        const updatedGuestCollaborator = newState.map((collaborator, i) => {
          if (i === index) {
            return {
              ...collaborator,
              isGeneratingImgUrl: false,
            };
          }
          return collaborator;
        });
        return updatedGuestCollaborator;
      });
    }
  };

  return (
    <>
      {guestCollaborators.map((collaborator, index) => (
        <div key={index}>
          <label
            htmlFor="name"
            className="mt-7 w-full flex flex-col items-start text-xs label text-grey60 font-medium max-w-full relative"
          >
            Name
            <input
              type="text"
              name="name"
              id="name"
              className="input w-full max-w-full text-sm"
              onChange={e =>
                handleGuestCollaboratorChange('name', e.target.value, index)
              }
              value={collaborator?.name ?? ''}
            />
          </label>

          <div className="mt-7 w-full">
            <p className="font-medium text-xs">Upload image</p>
            <div className="flex items-center gap-4 w-full">
              <label className="w-full">
                <input
                  accept="image/png, image/jpeg"
                  type="file"
                  onChange={e => {
                    handleFileInput(e.target.files[0], data => {
                      setGuestCollaborators(prevState => {
                        const newState = [...prevState];
                        newState[index].imageFile = data;
                        return newState;
                      });
                      uploadGuestImage(data?.file, index);
                    });
                  }}
                />

                <div className="p-4 cursor-pointer bg-white-900 text-center border border-brandBlue border-dashed rounded w-full">
                  <p className="text-grey60 text-xs font-medium">
                    {collaborator?.imageFile?.filename ??
                      'Click to upload image'}
                  </p>
                </div>
              </label>
              {collaborator?.isGeneratingImgUrl ? (
                <svg
                  className="animate-spin h-5 w-5 text-brandBlue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : null}
              {collaborator?.imageFile?.file || collaborator?.image ? (
                <div className="flex items-center">
                  <img
                    src={collaborator?.imageFile?.file || collaborator?.image}
                    alt=""
                    width={48}
                    height={48}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="mt-2 flex items-center w-full gap-4">
            {guestCollaborators?.length > 1 ? (
              <button
                type="button"
                className="text-sm text-grey60 font-medium"
                onClick={() => removeGuestCollaborator(index)}
              >
                {guestCollaborators.length > 1 ? 'remove' : null}
              </button>
            ) : null}
          </div>
        </div>
      ))}
      <button
        type="button"
        className="mx-auto flex items-center mt-4 text-sm text-brandBlue font-medium"
        onClick={addGuestCollaborator}
      >
        Add another guest collaborator
      </button>
    </>
  );
}
