import React from 'react';
import SingleDropdown from '../../../components/SingleDropdown';
import Toggle from '../../../components/Toggle';
import {Accordion} from '../../../components/Accordion';

const smsRoutes = [
  {sms_route: 'International', value: 'international'},
  {sms_route: 'Promotional', value: 'promotional'},
  {sms_route: 'Premium', value: 'premium'},
  {sms_route: 'Elta_dnd', value: 'elta_dnd'},
  {sms_route: 'Champ', value: 'champ'},
];

const currencies = [{currency: 'NGN'}, {currency: 'USD'}];

export function MainSettings({settings, setSettings}) {
  return (
    <Accordion title="Main Settings" background="bg-white-900">
      <div>
        <section className="mt-3 grid grid-cols-1  md:grid-cols-2 gap-x-4 lg:gap-x-6 w-wull">
          <div>
            <label className="flex items-start text-left text-sm label text-grey60 mb-1">
              SMS route
            </label>
            <SingleDropdown
              whiteBg
              dropdownData={smsRoutes}
              selected={settings?.smsRoute}
              setSelected={route => setSettings({...settings, smsRoute: route})}
              spanText={settings?.smsRoute?.sms_route}
              width="w-full"
            />
          </div>
          <div>
            <label className="flex items-start text-left text-sm label text-grey60 mb-1">
              Currency
            </label>

            <SingleDropdown
              whiteBg
              dropdownData={currencies}
              selected={settings?.curr}
              setSelected={data => setSettings({...settings, curr: data})}
              spanText={settings?.curr?.currency}
              width="w-full"
            />
          </div>
          <div className="mt-4">
            <label className="flex items-start text-left text-sm label text-grey60 mb-1">
              White Label
            </label>
            <div className="relative w-full flex gap-2 items-center justify-between cursor-pointer rounded border border-grey20 bg-white py-[14px] px-4 text-left h-12 focus:outline-none focus:border focus:border-brandBlue">
              <Toggle
                enabled={settings?.whiteLabel}
                setEnabled={e => setSettings({...settings, whiteLabel: e})}
              />
            </div>
          </div>
        </section>
      </div>
    </Accordion>
  );
}
