import React, {useCallback, useState} from 'react';
import {dropdownFillGrey} from '../assets/images/images';

export default function FilterAccordion({
  children,
  filterName = '',
  selectedCount = 0,
  activeIndex,
  onClick,
}) {
  const [isActive, setIsActive] = useState(false);
  const handleAccordionOpen = useCallback(() => {
    setIsActive(a => !a);
  }, []);

  const active = onClick ? activeIndex : isActive;
  const selectedFiltersCount =
    typeof selectedCount === 'number'
      ? selectedCount
      : selectedCount?.filter(Boolean).length;

  return (
    <div className="flex flex-col px-6 py-4 bg-white-900 -mx-6">
      <div
        onClick={() => {
          handleAccordionOpen();
          onClick();
        }}
      >
        <div
          className={`${
            selectedFiltersCount > 0
              ? 'bg-brandBlue text-white'
              : 'bg-white text-body'
          } flex py-[11px] px-4 items-center justify-between uppercase text-xs font-medium rounded cursor-pointer transition ease-in-out duration-300`}
        >
          <span>by {filterName?.replace('_', ' ')}</span>
          {selectedFiltersCount > 0 ? (
            <span className="flex items-center justify-center text-xs text-brandBlue w-[18px] h-[18px] bg-white rounded-full">
              {selectedFiltersCount}
            </span>
          ) : (
            <img
              src={dropdownFillGrey}
              alt=""
              className={`${
                active ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
              } duration-200 transition`}
              width={16}
              height={16}
            />
          )}
        </div>
      </div>
      {active && <div>{children}</div>}
    </div>
  );
}
